import { FaExternalLinkAlt, FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { FC, useMemo, useState } from 'react';
import { generalBlue } from 'src/app-config';
import { H2, Hr, Loading } from 'src/components/_commons';
import { houseToHouseServices, hthHelper } from 'src/services';
import { HTHRestructuringMap } from './HTHRestructuringMap';
import { HTHRestructuringMove } from './HTHRestructuringMove';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeBlock, typeHTHTerritory, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    close: () => void;
    hthTerritories: typeHTHTerritory[];
}

export const HTHRestructuring: FC<propsType> = ({ close, hthTerritories }) => {
    const [blockToMove, setBlockToMove] = useState<{ territory: typeHTHTerritory, block: typeBlock } | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [newAmountOfTerritories, setNewAmountOfTerritories] = useState(hthTerritories.length);
    const [newHthTerritories, setNewHthTerritories] = useState(hthTerritories);
    const [territoryForMap, setTerritoryForMap] = useState<typeHTHTerritory | null>(null);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const isValidAmountOfTerritories: boolean = useMemo(() => {  // obsolet
        if (newAmountOfTerritories >= hthTerritories.length) return true;
        let someTerritory = true;
        newHthTerritories.filter(t => t.number > newAmountOfTerritories).forEach(t => {
            if (t.map.polygons.length) someTerritory = false;
        });
        return someTerritory;
    }, [hthTerritories, newAmountOfTerritories, newHthTerritories]);

    const isValidLessThan10BlocksForTerritory: boolean = useMemo(() =>
        !newHthTerritories.some(t => [...new Set(t.map.polygons.map(p => p.block))].length > 9)
    , [newHthTerritories]);

    const isValid: boolean = useMemo(() => {
        if (!isValidAmountOfTerritories) return false;
        if (!isValidLessThan10BlocksForTerritory) return false;
        return true;
    }, [isValidAmountOfTerritories, isValidLessThan10BlocksForTerritory]);

    const save = () => {
        if (!isValid) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: 'Se van a reestructurar los territorios. Las manzanas eliminadas (si se están eliminando) no podrán ser recuperadas.',
            mode: 'confirm',
            animation: 3,
            execution: async () => {
                let block = 0;
                const orderedNewHthTerritories: typeHTHTerritory[] = [
                    ...newHthTerritories.sort((t1, t2) => t1.number - t2.number).map(t => ({
                        ...t,
                        map: {
                            ...t.map,
                            polygons: t.map.polygons.sort((p1, p2) => parseInt(p1.block) - parseInt(p2.block) || p1.face.localeCompare(p2.face)).map(p => {
                                if (p.face === 'A') block++;
                                return {
                                    ...p,
                                    block: block.toString() as typeBlock
                                };
                            })
                        }
                    }))
                ];
                setIsLoading(true);
                const success = await houseToHouseServices.RestructuringTerritories(orderedNewHthTerritories);
                setIsLoading(false);
                dispatch(setValuesAndOpenAlertModalReducer({
                    title: success ? 'Logrado': 'Algo falló',
                    message: success ? 'Se reestructuraron los territorios': 'No se pudieron reestructurar los territorios',
                    mode: 'alert',
                    animation: success ? 1 : 2,
                    execution: success ? close : () => {}
                }));
            }
        }));
    }

    const cancel = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Cancelar',
            message: 'Se van a perder los cambios hechos',
            animation: 3,
            mode: 'confirm',
            execution: close
        }));
    }

    const updateNumberOfTerritories = (n: number) => {
        if (n > newAmountOfTerritories) {
            const x = Array.from({ length: n }, (_, i) => i + 1).filter(x => x > newAmountOfTerritories);
            let copy = [...newHthTerritories];
            for (let i = 0; i < x.length; i++) {
                copy.push({
                    assignments: [],
                    congregation: user.congregation,
                    map: {
                        centerCoords: hthTerritories[hthTerritories.length - 1].map.centerCoords,
                        lastEditor: user.id,
                        markers: [],
                        polygons: [],
                        zoom: 17
                    },
                    number: x[i],
                    territoryNumber: x[i].toString() as typeTerritoryNumber
                });
            }
            setNewHthTerritories(copy);
        } else if (n < newAmountOfTerritories) {
            const territoriesX = newHthTerritories.filter(t => t.number > n);
            if (territoriesX.some(t => t.map.polygons.length)) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    title: 'Error',
                    message: 'Para reducir la cantidad de territorios primero hay que reubicar o eliminar todas las manzanas de los territorios que ya no se van a usar',
                    mode: 'alert',
                    animation: 2
                }));
                return;
            }
            setNewHthTerritories(x => x.filter(t => t.map.polygons.length || t.number <= n));
        }
        setNewAmountOfTerritories(n);
    }

    const deleteFace = (territory: typeHTHTerritory, block: typeBlock) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Eliminar Manzana',
            message: `Se va a eliminar la manzana ${block} del territorio ${territory.territoryNumber}. Se perderán todos sus edificios, no tocar, observaciones e historial de predicación. Para revertir este cambio (antes de guardar) habrá que refrescar la pantalla y volver a empezar la reestructuración.`,
            animation: 3,
            mode: 'confirm',
            execution: () => {
                setNewHthTerritories(x => x.map(y => y.number === territory.number ? ({
                    ...y,
                    map: {
                        ...y.map,
                        polygons: y.map.polygons.filter(p => p.block !== block)
                    }
                }) : y));
            }
        }));
    }

    const reorderBlock = (t: typeHTHTerritory, b: typeBlock, i: number, up: boolean) => {
        setNewHthTerritories(x => x.map(y => ({
            ...y,
            map: {
                ...y.map,
                polygons: y.map.polygons.map(p => ({
                    ...p, block:
                        y.territoryNumber === t.territoryNumber ?
                            up ?
                                p.block === b ?
                                    (parseInt(p.block) - 1).toString() as typeBlock
                                    :
                                    parseInt(p.block) === parseInt(b) - 1 ?
                                        (parseInt(p.block) + 1).toString() as typeBlock
                                        :
                                        p.block
                                :
                                p.block === b ?
                                    (parseInt(p.block) + 1).toString() as typeBlock
                                    :
                                    parseInt(p.block) === parseInt(b) + 1 ?
                                        (parseInt(p.block) - 1).toString() as typeBlock
                                        :
                                        p.block
                            :
                            p.block
                }))
            }
        })));
    }

    return (
        <div className={isDarkMode ? 'text-white' : ''}>
            {territoryForMap &&
                <HTHRestructuringMap
                    close={() => setTerritoryForMap(null)}
                    hthTerritory={territoryForMap}
                />
            }
            {!!blockToMove &&
                <HTHRestructuringMove
                    blockToMove={blockToMove}
                    close={() => setBlockToMove(null)}
                    newAmountOfTerritories={newAmountOfTerritories}
                    setNewHthTerritories={setNewHthTerritories}
                />
            }

            <H2 title={'REESTRUCTURACIÓN DE TERRITORIOS'} />

            <p>*Reestructurar los territorios en un horario en el que no se esté predicando para evitar pérdida de información.</p>

            <div className={`card-footer d-flex justify-content-start ${isDarkMode ? 'bg-dark' : ''}`}>
                <h3 className={'btn-general-blue p-2 me-3'}
                    style={{ width: '400px' }}
                >
                    Nueva cantidad de Territorios:
                </h3>
                <div className={'d-flex justify-content-center'}>
                    <select
                        className={'form-select text-center'}
                        style={{ width: '170px' }}
                        value={newAmountOfTerritories}
                        onChange={n => updateNumberOfTerritories(parseInt(n.target.value))}
                    >
                        {Array.from({ length: 200 }, (_, i) => i + 1).map(n =>
                            <option key={n} value={n}>{n}</option>
                        )}
                    </select>
                </div>
            </div>

            <div className={'mt-3 mb-5'}>
                {newHthTerritories.sort((t1, t2) => t1.number - t2.number).map(t =>
                    <div key={t.territoryNumber} className={`card ${isDarkMode ? 'text-white' : ''} mb-5`}>
                        <div className={`card-header ${isDarkMode ? 'bg-success' : ''} d-flex justify-content-between`}>
                            <div>
                                <h2 className={'mb-0'}>
                                    TERRITORIO {t.territoryNumber}
                                </h2>
                            </div>
                            <div>
                                <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                                    onClick={() => setTerritoryForMap(t)}
                                >
                                    <FaExternalLinkAlt className={'mb-1 me-1'} /> Ver en el Mapa
                                </button>
                            </div>
                        </div>
                        <div className={`card-body ${isDarkMode ? 'bg-dark' : ''}`}>
                            {[...new Set(t.map.polygons.sort((p1, p2) => parseInt(p1.block) - parseInt(p2.block)).map(p => p.block))].map((b, i) =>
                                <div key={b} className={'mb-3'}>
                                    {isMobile && b !== '1' && <Hr />}
                                    <div className={`${isMobile ? '' : 'd-flex justify-content-between'} ${isDarkMode ? 'bg-dark' : 'bg-light'} border-top border-bottom`}>
                                        <div className={'fw-bold me-auto p-2'}>
                                            MANZANA {hthHelper.MaskTheBlock((i + 1).toString() as typeBlock, config.usingLettersForBlocks)}
                                        </div>
                                        <button className={'btn btn-general-red btn-sm my-1 me-4'}
                                            onClick={() => deleteFace(t, b)}
                                        >
                                            Eliminar Manzana
                                        </button>
                                        <button className={'btn btn-success btn-sm my-1'}
                                            onClick={() => setBlockToMove({ territory: t, block: b })}
                                        >
                                            Reubicar Manzana
                                        </button>
                                        <div className={'text-nowrap mt-1 ms-3'}>
                                            <button className={'btn btn-link p-0 mb-2'}
                                                onClick={() => reorderBlock(t, b, i, true)}
                                                disabled={i === 0 || [...new Set(t.map.polygons.map(p => p.block))].length === 1}
                                            >
                                                <FaArrowUp size={25} color={generalBlue} />
                                            </button>
                                            <button className={'btn btn-link p-0 mb-2 ms-2'}
                                                onClick={() => reorderBlock(t, b, i, false)}
                                                disabled={i === [...new Set(t.map.polygons.map(p => p.block))].length - 1}
                                            >
                                                <FaArrowDown size={25} color={generalBlue} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className={'mt-1'}>
                                        {t.map.polygons.filter(p => p.block === b).sort((p1, p2) => parseInt(p1.block) - parseInt(p2.block) || p1.face.localeCompare(p2.face)).map(p =>
                                            <div key={p.id}>
                                                <div className={'ms-2'}>
                                                    Cara {hthHelper.MaskTheFace(p.face, config.usingLettersForBlocks)}: {p.street} ... {p.buildings.length} edificios
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className={`card-footer d-flex justify-content-start ${isDarkMode ? 'bg-dark' : ''}`}></div> */}
                    </div>
                )}
            </div>

            {!isValidAmountOfTerritories &&
                <div className={'alert alert-danger'} role={'checkbox'}>
                    La nueva cantidad de territorios es menor a la actual. Reubicar o eliminar todas las manzanas de los territorios: <strong>{newHthTerritories.filter(t => t.number > newAmountOfTerritories && !!t.map.polygons.length).map(t => t.number).join(', ')}</strong>
                </div>
            }
            {!isValidLessThan10BlocksForTerritory &&
                <div className={'alert alert-danger'} role={'checkbox'}>
                    El límite de manzanas por territorio es 9. Reubicar o eliminar manzanas de los territorios: <strong>{newHthTerritories.filter(t => [...new Set(t.map.polygons.map(p => p.block))].length > 9).map(t => t.number).join(', ')}</strong>
                </div>
            }

            <div className={'d-flex justify-content-center'}>
                {isLoading ?
                    <Loading mt={'50px'} />
                    :
                    <div>
                        <button
                            className={'btn btn-general-blue w-100 mt-4'}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={save}
                            disabled={!isValid || isLoading}
                        >
                            ACEPTAR CAMBIOS
                        </button>
                        <button
                            className={`btn btn-general-red w-100 mt-3`}
                            style={{ fontWeight: 'bolder', height: '50px' }}
                            onClick={cancel}
                            disabled={isLoading}
                        >
                            CANCELAR
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}
