import { BsPencil, BsTrash } from 'react-icons/bs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { domain } from 'src/app-config';
import { FC, useMemo, useState } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { houseToHouseServices } from 'src/services';
import { Hr, Loading } from '..';
import { HTHBuildingModalComplex, HTHBuildingModalSimple, HTHBuildingModalToEdit } from '.';
import { IoIosArrowDroprightCircle } from 'react-icons/io';
import { Modal } from 'react-bootstrap';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeHTHBuilding, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useNavigate } from 'react-router';

type propsType = {
    closeBuildingModalHandler: () => void;
    congregation: number;
    currentBuilding: typeHTHBuilding;
    currentFace: typePolygon;
    territoryNumber: typeTerritoryNumber;
}

export const HTHBuildingModal: FC<propsType> = ({
    closeBuildingModalHandler, congregation, currentBuilding, currentFace, territoryNumber
}) => {
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showEditBuilding, setShowEditBuilding] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const shareUrl = useMemo(() => {
        let currentUrl = `####  *TERRITORIO ${territoryNumber}*   ####\n*Fecha: ${generalHelpers.GetCurrentLocalDate()}*`;
        currentUrl += "\n\n*EDIFICIO COMPARTIDO POR 30 DÍAS:*\n";
        currentUrl += `${currentFace.street} ${currentBuilding.streetNumber}${currentBuilding.streetNumber2 ? `/${currentBuilding.streetNumber2}` : ''}${currentBuilding.streetNumber3 ? `/${currentBuilding.streetNumber3}` : ''}`;
        currentUrl += `\n\n`;
        currentUrl += `${domain}/edificio/${config.congregation}/${territoryNumber}/${currentFace.block}/${currentFace.face}/${currentBuilding.streetNumber}`;
        currentUrl += `\n\n`;
        return currentUrl;
    }, [config, currentBuilding, currentFace, territoryNumber]);

    const deleteHTHBuildingHandler = () => {
        closeBuildingModalHandler();
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: "¿Eliminar Edificio?",
            message: `Se va a eliminar el edificio ${currentFace.street} ${currentBuilding.streetNumber}. Esta acción es irreversible.`,
            execution: async () => {
                const success = await houseToHouseServices.DeleteBuilding(territoryNumber, currentFace.block, currentFace.face, currentBuilding.streetNumber);
                if (!success) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: "Algo falló",
                        message: "No se pudo eliminar el edificio",
                        animation: 2
                    }));
                    return;
                }
            }
        }));
    }

    const shareForAMonth = async () => {
        const success = await houseToHouseServices.ShareBuildingForAMonth(territoryNumber, currentFace.block, currentFace.face, currentBuilding.streetNumber);
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: "No se pudo habilitar el edificio para compartir",
                animation: 2
            }));
            return;
        }
        setCopiedToClipboard(true);
    }

    return (
        <Modal
            backdrop={'static'}
            backdropClassName={''}
            contentClassName={isDarkMode ? 'bg-secondary' : ''}
            keyboard={false}
            onHide={() => closeBuildingModalHandler ? closeBuildingModalHandler() : navigate('/')}
            show={true}
            size={'xl'}
        >
            <Modal.Header closeButton />

            <Modal.Body>
                <div className={'card my-4'}>

                    {isLoading ?
                        <Loading mt={'30px'} mb={'7px'} white={false} />
                        :
                        <>
                        {!showEditBuilding &&
                            <button className={'btn btn-general-blue btn-size12 w-100 mx-auto mt-4 mb-2'}
                                onClick={() => closeBuildingModalHandler ? closeBuildingModalHandler() : navigate('/')}
                                style={{ maxWidth: '300px' }}
                            >
                                Cerrar
                            </button>
                        }
                        </>
                    }

                    <Hr />

                    <h1 className={'bg-dark text-white text-center fw-bolder mt-3 mb-2 py-2'}
                        style={{ border: isDarkMode ? '' : '1px solid lightgray', fontSize: '1.6rem' }}
                    >
                        Edificio {currentFace.street} {currentBuilding.streetNumber}{currentBuilding.streetNumber2 ? `/${currentBuilding.streetNumber2}` : ''}{currentBuilding.streetNumber3 ? `/${currentBuilding.streetNumber3}` : ''}
                        {user.isBuildingsAdmin && !showEditBuilding &&
                            <>
                                <BsPencil
                                    className={'pointer ms-3 mb-1'}
                                    onClick={() => setShowEditBuilding(true)}
                                />
                                <BsTrash
                                    className={'pointer ms-3 mb-1'}
                                    onClick={() => deleteHTHBuildingHandler()}
                                />
                            </>
                        }
                    </h1>

                    <Hr classes={'bg-dark text-dark'} />

                    {showEditBuilding ?
                        <>
                            <HTHBuildingModalToEdit
                                closeEdit={() => setShowEditBuilding(false)}
                                currentBuilding={currentBuilding}
                                currentFace={currentFace}
                                territoryNumber={territoryNumber}
                            />
                        </>
                        :
                        <>
                        {isMobile && currentBuilding.isComplex && currentBuilding.numberPerLevel > 3 &&
                            <div className={'bg-light ps-3'}>
                                Hay más timbres a la derecha <IoIosArrowDroprightCircle />
                            </div>
                        }

                        {currentBuilding.isComplex ?
                            <HTHBuildingModalComplex
                                closeBuildingModalHandler={closeBuildingModalHandler}
                                congregation={congregation}
                                currentBuilding={currentBuilding}
                                currentFace={currentFace}
                                setIsLoading={setIsLoading}
                                territoryNumber={territoryNumber}
                            />
                            :
                            <HTHBuildingModalSimple
                                congregation={congregation}
                                currentBuilding={currentBuilding}
                                currentFace={currentFace}
                                setIsLoading={setIsLoading}
                                territoryNumber={territoryNumber}
                            />
                        }

                        {isLoading ?
                            <Loading mb={'15px'} white={false} />
                            :
                            <>
                            <button className={'btn btn-general-blue btn-size12 w-100 mx-auto mt-1 mb-3'}
                                style={{ maxWidth: '300px' }}
                                onClick={() => closeBuildingModalHandler ? closeBuildingModalHandler() : navigate('/')}
                            >
                                Cerrar
                            </button>
                            {user.isHthAdmin && currentBuilding.households.length > 9 &&
                                <CopyToClipboard text={shareUrl} onCopy={() => setCopiedToClipboard(true)}>
                                    <button className={`btn btn-${copiedToClipboard ? 'general-red' : 'success'} btn-size12 w-100 mx-auto mb-4`}
                                        style={{ maxWidth: '300px' }}
                                        onClick={shareForAMonth}
                                    >
                                        {copiedToClipboard ? 'Link copiado!' : 'Compartir por 30 días'}
                                    </button>
                                </CopyToClipboard>
                            }
                            </>
                        }
                        </>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}
