"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.socketIOEvents = exports.httpHeaders = void 0;
exports.httpHeaders = {
    accept: 'Accept',
    authorizationToken: 'x-authorization',
    contentDisposition: 'Content-Disposition',
    contentType: 'Content-Type',
    contentTypeApplicationJson: 'application/json',
    contentTypeAuthorizationOpenCors: 'Authorization',
    contentTypeMultipartFormData: 'multipart/form-data',
    recaptchaToken: 'x-recaptcha-token',
    requestDomain: 'x-request-domain',
    schedulerToken: 'x-scheduler-token',
    twilioToken: 'x-twilio-signature'
};
exports.socketIOEvents = {
    hthChange: 'hth: change',
    telephonicChange: 'telephonic: change',
    userChange: 'user: change'
};
