import { Col0a, Col0b, FewHouseholdsWarning, FreePhonesMessage, LocalStatistics, MapModalTeleph, PhonesToShowPagination, StateOfTerritoryBtn, StaticMap, TelephonicCard } from './subcomponents';
import { H2, Loading, WarningToaster } from 'src/components/_commons';
import { io } from 'socket.io-client';
import { SERVER } from 'src/app-config';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { socketIOEvents } from '@monorepo/helpers';
import { telephonicServices, telephonicHelper } from 'src/services';
import { typeBlock, typeHousehold, typeTelephonicTerritory, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from 'src/custom-hooks';
import { useNavigate, useParams } from 'react-router';
import { useState, useEffect, useCallback } from 'react';

const socket = io(SERVER, { withCredentials: true });

export const TelephonicPage = () => {
    const [addressToShowInGoogleMaps, setAddressToShowInGoogleMaps] = useState('');
    const [blocks, setBlocks] = useState<typeBlock[]>();
    const [brought, setBrought] = useState(10);
    const [currentBlock, setCurrentBlock] = useState<typeBlock>();
    const [householdsToShow, setHouseholdsToShow] = useState<typeHousehold[]>();
    const [isShowingAllAvailable, setIsShowingAllAvailable] = useState(false);
    const [isShowingAllStates, setIsShowingAllStates] = useState(false);
    const [isShowingStatistics, setIsShowingStatistics] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [showPagination, setShowPagination] = useState(true);
    const [showWarningToaster, setShowWarningToaster] = useState(false);
    const [telephonicTerritory, setTelephonicTerritory] = useState<typeTelephonicTerritory>();
    const [userEmailWarningToaster, setUserEmailWarningToaster] = useState<string>();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const territoryNumber: typeTerritoryNumber = useParams<any>().territoryNumber as typeTerritoryNumber;
    const user = useGetCurrentUser();

    const openAlertModalHandler = (title: string, message: string, animation?: number): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title,
            message,
            animation
        }));
    }

    const setBroughtAllHandler = (): void => {
        setIsShowingAllAvailable(true);
        setShowPagination(false);
    }

    const setCurrentBlockHandler = (value: typeBlock): void => {
        setIsShowingStatistics(false);
        setIsShowingAllAvailable(false);
        setIsShowingAllStates(false);
        setBrought(10);
        setShowPagination(true);
        setCurrentBlock(value);
    }
    
    const setIsShowingAllStatesHandler = (value: boolean): void => {
        setIsShowingStatistics(false);
        setIsShowingAllAvailable(false);
        setIsShowingAllStates(value);
        setBrought(10);
        setShowPagination(true);
    }

    const setIsShowingStatisticsHandler = () => {
        setIsShowingStatistics(true);
        setIsShowingAllAvailable(false);
        setIsShowingAllStates(false);
        setCurrentBlock(undefined);
    }

    const closeWarningToasterHandler = (): void => setShowWarningToaster(false);

    const hideGoogleMapHandler = (): void => setAddressToShowInGoogleMaps('');

    const updateHouseholdsToShow = useCallback(() => {
        if (!currentBlock) return setHouseholdsToShow([]);
        if (!telephonicTerritory) return;
        let householdsToShow0: typeHousehold[] =
        telephonicHelper.GetHouseholdsToShow(telephonicTerritory.households, currentBlock, isShowingAllStates, isShowingAllAvailable);
        const temp: typeHousehold[] = householdsToShow0;
        if (!isShowingAllAvailable) {
            householdsToShow0 = householdsToShow0.slice(0, brought);
        }
        if (isShowingAllAvailable || householdsToShow0.length === temp.length) {
            setShowPagination(false);
        }
        householdsToShow0 = telephonicHelper.GetHouseholdVariant(householdsToShow0);
        setHouseholdsToShow(householdsToShow0);
    }, [brought, currentBlock, isShowingAllAvailable, isShowingAllStates, telephonicTerritory]);

    useEffect(() => {
        if (!territoryNumber) return navigate('/telefonica');
        telephonicServices.GetTerritory(territoryNumber).then((telephonicTerritory0: typeTelephonicTerritory|null) => {
            setLoaded(true);
            if (!telephonicTerritory0) return navigate('/telefonica');
            const blocks: typeBlock[] = telephonicHelper.GetBlocks(telephonicTerritory0.households);
            setBlocks(blocks);
            setCurrentBlock(blocks[0]);
            setTelephonicTerritory(telephonicTerritory0);
        });
        return () => {
            setBlocks(undefined);
            setUserEmailWarningToaster(undefined);
            setTelephonicTerritory(undefined);
        }
    }, [navigate, territoryNumber]);

    useEffect(() => {
        updateHouseholdsToShow();
    }, [brought, currentBlock, isShowingAllAvailable, isShowingAllStates, telephonicTerritory, updateHouseholdsToShow]);
    
    useEffect(() => {
        socket.on(socketIOEvents.telephonicChange, (_congregation: number, _territoryNumber: typeTerritoryNumber, _updatedHousehold: typeHousehold | null, _userEmail: string) => {
            if (_congregation !== user.congregation) return;
            if (_territoryNumber !== territoryNumber) return;
            if (_userEmail !== user.email) {
                setShowWarningToaster(true);
                setUserEmailWarningToaster(_userEmail);
                if (!_updatedHousehold) {  // territory reseted or changed state by someone else
                    navigate('/telefonica');
                    return;
                }
            }
            if (!telephonicTerritory || !_updatedHousehold) return;
            setTelephonicTerritory(x => !x ? x : ({
                ...x,
                households: telephonicTerritory?.households?.map(x =>
                    x.householdId === _updatedHousehold.householdId ?
                        ({ ..._updatedHousehold, doNotMove: true })
                        :
                        x
                )
            }));
            updateHouseholdsToShow();
        })
        return () => {
            socket.off(socketIOEvents.telephonicChange);
        };
    }, [brought, currentBlock, isShowingAllAvailable, isShowingAllStates, navigate, telephonicTerritory, territoryNumber, updateHouseholdsToShow, user.congregation, user.email]);

    return (
        <>
            {addressToShowInGoogleMaps &&
                <MapModalTeleph
                    address={addressToShowInGoogleMaps}
                    hideGoogleMapHandler={hideGoogleMapHandler}
                />
            }

            {loaded &&
                <div style={{ marginTop: '30px', position: 'fixed', zIndex: 4 }}>
                    <FewHouseholdsWarning
                        households={telephonicTerritory?.households}
                    />

                    {(!socket || !socket.connected) &&
                        <WarningToaster
                            bodyText={"Refrescar la página y verificar que hay internet"}
                            headerText={<strong>Hay un problema de conexión</strong>}
                        />
                    }

                    {showWarningToaster && userEmailWarningToaster &&
                        <WarningToaster
                            bodyText={["Este territorio está siendo trabajado por el usuario ", <strong key={0}>{userEmailWarningToaster}</strong>]}
                            closeWarningToaster={closeWarningToasterHandler}
                            headerText={<strong>Posible confusión de asignación</strong>}
                        />
                    }
                </div>
            }

            <H2 title={'TELEFÓNICA'} mb={'0px'} />

            <H2 title={`TERRITORIO ${territoryNumber} ${telephonicTerritory?.stateOfTerritory.isFinished ? "- TERMINADO" : ''}`}
                mt={'10px'}
                mb={'50px'}
            />

            {!loaded && <Loading mt={'60px'} mb={'10px'} />}

            {loaded && !isShowingStatistics && telephonicTerritory &&
                <StaticMap
                    mapId={telephonicTerritory.mapId}
                    territoryNumber={territoryNumber}
                />
            }

            {loaded &&
                <>
                    <Col0a
                        blocks={blocks}
                        currentBlock={currentBlock}
                        setCurrentBlockHandler={setCurrentBlockHandler}
                    />

                    <Col0b
                        isShowingAll={isShowingAllStates}
                        isShowingStatistics={isShowingStatistics}
                        setIsShowingAllStatesHandler={setIsShowingAllStatesHandler}
                        setIsShowingStatisticsHandler={setIsShowingStatisticsHandler}
                    />
                </>
            }

            {isShowingStatistics ?
                <>
                    {!!telephonicTerritory ?
                        <LocalStatistics
                            telephonicTerritory={telephonicTerritory}
                        />
                        :
                        <>Error en datos de Estadísticas Locales</>
                    }
                </>
                :
                <>
                    {telephonicTerritory &&
                        <>
                            <StateOfTerritoryBtn
                                isFinished={telephonicTerritory.stateOfTerritory.isFinished}
                                openAlertModalHandler={openAlertModalHandler}
                                territoryNumber={territoryNumber}
                            />
                            <FreePhonesMessage
                                currentBlock={currentBlock}
                                households={telephonicTerritory?.households}
                                loaded={loaded}
                                territoryNumber={territoryNumber}
                            />
                        </>
                    }

                    {!!householdsToShow?.length && householdsToShow?.map((household: typeHousehold) =>
                        <TelephonicCard
                            household={household}
                            key={household.householdId}
                            openAlertModalHandler={openAlertModalHandler}
                            setAddressToShowInGoogleMaps={setAddressToShowInGoogleMaps}
                            territoryNumber={territoryNumber}
                        />
                    )}

                    {!!householdsToShow?.length && showPagination &&
                        <PhonesToShowPagination
                            isShowingAllStates={isShowingAllStates}
                            setBrought={setBrought}
                            setBroughtAllHandler={setBroughtAllHandler}
                        />
                    }
                </>
            }
        </>
    )
}
