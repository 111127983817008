import { H2, Hr, Loading } from 'src/components/_commons';
import { helpers, houseToHouseServices, userServices } from 'src/services';
import { io } from 'socket.io-client';
import { SERVER } from 'src/app-config';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { socketIOEvents } from '@monorepo/helpers';
import { typeHTHTerritory, typeTerritoryNumber, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetCurrentUser, useGetIsDarkMode } from 'src/custom-hooks';
import * as Subcomponents from './subcomponents';

const socket = io(SERVER, { withCredentials: true });

export const AdminsHTH = () => {
    const [hthTerritories, setHthTerritories] = useState<typeHTHTerritory[] | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [showOption, setShowOption] = useState<0 | 1 | 2 | 3 | 4 | 5>(0);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const user = useGetCurrentUser()

    const getClasses = (option: number) => `btn btn-${showOption === option ? 'general-blue' : 'general-red'} btn-size12 d-block mx-auto mt-2 mb-0`;

    useEffect(() => {
        userServices.GetAll().then(us => {
            setUsers(us);
        });
        houseToHouseServices.GetTerritoriesForStatistics().then(territories => {
            setIsLoading(false);
            if (!territories) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron obtener los territorios de Casa en Casa. Refrescar. Ver si hay internet.",
                    animation: 2
                }));
                return;
            }
            setHthTerritories(territories);
        });
    }, [dispatch, refreshCounter]);

    useEffect(() => {
        socket.on(socketIOEvents.userChange, (_congregation: number) => {
            if (_congregation !== user.congregation) return;
            setRefreshCounter(x => x + 1);
        });
        return () => {
            socket.off(socketIOEvents.userChange);
        };
    }, [user.congregation]);

    useEffect(() => {
        socket.on(socketIOEvents.hthChange, (_congregation: number) => {
            if (_congregation !== user.congregation) return;
            console.log("Refrescando por uso de un usuario");
            setTimeout(() => {
                setRefreshCounter(x => x + 1);
            }, Math.floor(Math.random() * 2000));
        });
        return () => {
            socket.off(socketIOEvents.hthChange);
        };
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (showOption) {
            helpers.GoToTop(500);
        } else {
            helpers.GoToTop();
        }
    }, [showOption]);

    if (isLoading) return <Loading mt={'80px'} />;

    return (
        <div className={'container'}>
            <H2 title={'ADMINISTRACIÓN CASA EN CASA'} />
            {!!hthTerritories?.length ?
                <div>
                    <button className={getClasses(1)}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 1 ? 0 : 1)}
                    >
                        {showOption === 1 ? 'Viendo Asignaciones' : 'Ver Asignaciones'}
                    </button>
                    <button className={getClasses(2)}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 2 ? 0 : 2)}
                    >
                        {showOption === 2 ? 'Viendo Edificios' : 'Ver Edificios'}
                    </button>
                    <button className={getClasses(3)}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 3 ? 0 : 3)}
                    >
                        {showOption === 3 ? 'Viendo Predicación de Edificios' : 'Ver Predicación de Edificios'}
                    </button>
                    <button className={getClasses(4)}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 4 ? 0 : 4)}
                    >
                        {showOption === 4 ? 'Viendo Configuración' : 'Ver Configuración'}
                    </button>
                    <button className={getClasses(5)}
                        style={{ width: '350px' }}
                        onClick={() => setShowOption(x => x === 5 ? 0 : 5)}
                    >
                        {showOption === 5 ? 'Viendo Reestructuración' : 'Ver Reestructuración'}
                    </button>

                    <Hr classes={'mt-5 mx-auto'} />

                    {showOption === 1 &&
                        <Subcomponents.HTHAssignments
                            hthTerritories={hthTerritories}
                            users={users}
                        />
                    }
                    {showOption === 2 &&
                        <Subcomponents.HTHBuildingsForAdmins
                            hthTerritories={hthTerritories}
                        />
                    }
                    {showOption === 3 &&
                        <Subcomponents.HTHMap
                            hthTerritories={hthTerritories}
                        />
                    }
                    {showOption === 4 &&
                        <Subcomponents.HTHConfig />
                    }
                    {showOption === 5 &&
                        <Subcomponents.HTHRestructuring
                            close={() => setShowOption(0)}
                            hthTerritories={hthTerritories}
                        />
                    }
                </div>
                :
                <h4 className={`text-center mt-5 ${isDarkMode ? 'text-white' : ''}`}>
                    No hay datos
                </h4>
            }
        </div>
    )
}
