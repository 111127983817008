import { H2, Loading, TerritoryNumberBlock } from 'src/components/_commons';
import { houseToHouseServices } from 'src/services';
import { useEffect, useState } from 'react';
import { useGetIsDarkMode } from 'src/custom-hooks';
import { useLocation } from 'react-router';

export const HTHFreeSelectorPage = () => {
    const queryString = new URLSearchParams(useLocation().search);
    //
    const congregation = parseInt(queryString.get('grupo') || '0');
    //
    const [isLoading, setIsLoading] = useState(true);
    const [territories, setTerritories] = useState<number[] | null>(null);
    const isDarkMode = useGetIsDarkMode();

    useEffect(() => {
        if (!congregation) return;
        houseToHouseServices.GetNumbersOfReleasedTerritories(congregation).then(tn => {
            setIsLoading(false);
            setTerritories(tn);
        });
    }, [congregation]);

    return (
        <>
            <H2 title={'CASA EN CASA'} mb={'10px'} />

            {/* <HTHVoiceToText congregation={congregation} /> */}

            {isLoading ?
                <Loading mt={'50px'} />
                :
                !!territories?.length ?
                    <TerritoryNumberBlock
                        classes={'btn-general-blue animate__animated animate__rubberBand'}
                        congregation={congregation}
                        showForecast={false}
                        territories={territories}
                        url={'/buscador'}
                    />
                    :
                    <h5 className={`${isDarkMode ? 'text-white' : ''} text-center`}>
                        No hay territorios compartidos el día de hoy
                    </h5>
            }
        </>
    )
}
