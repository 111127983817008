import { H2, Hr } from './../_commons';
import { useGetIsDarkMode } from 'src/custom-hooks';

const classes = 'mt-4 mb3';
const style = { maxWidth: '100%', height: 'auto', maxHeight: '500px', margin: '40px' };
const styleLg = { maxWidth: '100%', height: 'auto', maxHeight: '500px', marginBlock: '40px' };

export const InstallPage = () => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={'text-center'}>
            <H2 title='Cómo instalar esta App' />
            <div className={isDarkMode ? 'text-white' : ''}>
                <h5 className={classes}> Esta aplicación es web (se accede desde navegador) pero es una aplicación web "progresiva" (PWA), es decir que puede ser instalada mediante un navegador. El resultado de la instalación es un acceso directo y, mediante él, poder abrirla como si fuera una aplicación descargarda desde una tienda de aplicaciones, evitando tener que abrir un navegador y navegar hasta el sitio. Además, tiene la ventaja de ganar espacio en la pantalla porque de esta manera no se carga la barra de navegación de internet. </h5>

                <h5 className={classes}>Requisito: tener instalados Chrome o Safari.</h5>
                
                <h5 className={classes}>En todos los casos hay que primeramente abrir el navegador y dirigirse a este sitio web.</h5>

                <Hr classes={'mt-5'} />

                <h2 className='mt-4'>Android con Chrome</h2>
                <h5 className={classes}>Arriba a la derecha se encuentra el ícono de 3 puntos para abrir las opciones.</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/android_chrome.png`}
                        alt={'Android con Chrome'}
                        style={style}
                    />
                </div>
                <h5 className={classes}>Usar la opción "Agregar a la pantalla principal".</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/android_chrome_m.png`}
                        alt={'Android con Chrome'}
                        style={style}
                    />
                </div>

                <Hr />

                <h2 className='mt-4'>iOS con Safari</h2>
                <h5 className={classes}>Arriba a la derecha se encuentra el ícono de compartir.</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/safari.png`}
                        alt={'iOS con Safari'}
                        style={style}
                    />
                </div>
                <h5 className={classes}>En el menú que se despliega usar la opción "Agregar a Inicio".</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/safari_m.png`}
                        alt={'iOS con Safari'}
                        style={style}
                    />
                </div>

                <Hr />

                <h2 className='mt-4'>iOS con Chrome</h2>
                <h5 className={classes}>Abajo en el centro se encuentra el ícono de compartir.</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/ios-chrome.png`}
                        alt={'iOS Chrome'}
                        style={style}
                    />
                </div>
                <h5 className={classes}>Usar la opción "Añadir a pantalla de inicio".</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/ios-chrome_m.png`}
                        alt={'iOS Chrome'}
                        style={style}
                    />
                </div>

                <Hr />

                <h2 className='mt-4'>Windows con Chrome</h2>
                <h5 className={classes}>Arriba a la derecha se encuentra el ícono de instalación.</h5>
                <div>
                    <img src={`${process.env.PUBLIC_URL}/instalar/windows.png`}
                        alt={'Windows con Chrome'}
                        style={styleLg}
                    />
                </div>
            </div>
        </div>
    )
}
