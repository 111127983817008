import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import * as types from '@monorepo/models';

class TelephonicServices {
    private Base = pointer.telephonic;
    async AssignTerritory(user: types.typeUser, toAssign: number | null, toUnassign: number | null, all: boolean | null): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/assignment`, 'PUT', { all, user, toAssign, toUnassign });
        return !!data?.success;
    }
    async ChangeStateOfTerritory(territoryNumber: string, isFinished: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { territoryNumber, isFinished });
        return !!data?.success;
    }
    async GetGlobalStatistics(): Promise<types.typeTelephonicTerritory[] | null> {
        const data = await api.SendRequest(`${this.Base}/statistics/table`, 'GET');
        if (!data || !data.success || !data.territoriesTableData) return null;
        return data.territoriesTableData;
    }
    async GetTerritory(territoryNumber: string): Promise<types.typeTelephonicTerritory | null> {
        const data = await api.SendRequest(`${this.Base}/${territoryNumber}`, 'GET');
        if (!data || !data.success || !data.telephonicTerritory) return null;
        return data.telephonicTerritory;
    }
    async ModifyHousehold(territoryNumber: types.typeTerritoryNumber, householdId: number, callingState: string, notSubscribed: boolean, isAssigned: boolean): Promise<types.typeHousehold | null> {
        const data = await api.SendRequest(`${this.Base}/${territoryNumber}`, 'PUT', { householdId, callingState, notSubscribed, isAssigned });
        if (!data || !data.success || !data.household) return null;
        return data.household;
    }
    async ResetTerritory(territoryNumber: string, option: number): Promise<number | null> {
        const data = await api.SendRequest(this.Base, 'DELETE', { territoryNumber, option });
        if (!data || !data.success || data.modifiedCount === null || data.modifiedCount === undefined) return null;
        return data.modifiedCount;
    }
}

export const telephonicServices = new TelephonicServices();
