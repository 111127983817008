import { configServices } from 'src/services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

export const HTHConfig = () => {
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const rowStyles = { marginTop: '14px', width: isMobile ? '400px' : '800px' };

    const setDisableEditHthMapsHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.SetDisableEditMaps(!config.isDisabledEditHthMaps);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: 'Algo falló',
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la edición de Mapas`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setDisableCloseHthFacesHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.SetDisableCloseHthFaces(!config.isDisabledCloseHthFaces);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: 'Algo falló',
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la función de cerrar Caras`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setDisableHthFaceObservatiosHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.SetDisableHthFaceObservatios(!config.isDisabledHthFaceObservations);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: 'Algo falló',
                message: `No se pudo ${config.isDisabledEditHthMaps ? 'habilitar' : 'deshabilitar'} la función de Observaciones de Caras`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setUsingLettesForBlocksHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.SetUsingLettersForBlocks(!config.usingLettersForBlocks);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: 'Algo falló',
                message: `No se pudo ${config.usingLettersForBlocks ? 'deshabilitar' : 'habilitar'} el uso de letras para identificar las manzanas`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    const setIsSharingAllHandler = async () => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.SetHthIsSharingAll(!config.isSharingHthAll);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: 'Algo falló',
                message: `No se pudo ${config.isSharingHthAll ? 'deshabilitar' : 'habilitar'} la opción de compartir todos los territorios todos los días`,
                animation: 2
            }));
            return;
        }
        window.location.reload();
    }

    return (
        <div className={`container card ${isDarkMode ? 'bg-dark text-white' : ''} py-2 pb-3 mt-5`}>
            <div className={'d-flex row justify-content-center align-items-center'} style={rowStyles}>
                <div className={`${isMobile ? 'col-2' : 'col-3'} text-end`}>
                    <input type='checkbox'
                        className={'form-check-input checkbox-md pointer'}
                        checked={!config.isDisabledEditHthMaps}
                        onChange={setDisableEditHthMapsHandler}
                    />
                </div>
                <div className={'col'}>
                    <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                        Habilitar edición de Mapas
                    </h5>
                </div>
            </div>
            <div className={'d-flex row justify-content-center align-items-center'} style={rowStyles}>
                <div className={`${isMobile ? 'col-2' : 'col-3'} text-end`}>
                    <input type='checkbox'
                        className={'form-check-input checkbox-md pointer'}
                        checked={!config.isDisabledCloseHthFaces}
                        onChange={setDisableCloseHthFacesHandler}
                    />
                </div>
                <div className={'col'}>
                    <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                        Habilitar función de cerrar Caras
                    </h5>
                </div>
            </div>
            <div className={'d-flex row justify-content-center align-items-center'} style={rowStyles}>
                <div className={`${isMobile ? 'col-2' : 'col-3'} text-end`}>
                    <input type='checkbox'
                        className={'form-check-input checkbox-md pointer'}
                        checked={!config.isDisabledHthFaceObservations}
                        onChange={setDisableHthFaceObservatiosHandler}
                    />
                </div>
                <div className={'col'}>
                    <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                        Habilitar función de Observaciones de Caras
                    </h5>
                </div>
            </div>
            <div className={'d-flex row justify-content-center align-items-center'} style={rowStyles}>
                <div className={`${isMobile ? 'col-2' : 'col-3'} text-end`}>
                    <input type='checkbox'
                        className={'form-check-input checkbox-md pointer'}
                        checked={config.usingLettersForBlocks}
                        onChange={setUsingLettesForBlocksHandler}
                    />
                </div>
                <div className={'col'}>
                    <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                        Usar letras para identificar las manzanas en vez de números
                    </h5>
                </div>
            </div>
            <div className={'d-flex row justify-content-center align-items-center'} style={rowStyles}>
                <div className={`${isMobile ? 'col-2' : 'col-3'} text-end`}>
                    <input type='checkbox'
                        className={'form-check-input checkbox-md pointer'}
                        checked={config.isSharingHthAll}
                        onChange={setIsSharingAllHandler}
                    />
                </div>
                <div className={'col'}>
                    <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                        Liberar todos los territorios
                    </h5>
                </div>
            </div>
        </div>
    )
}
