import { api } from './_apiInterface';
import { Credentials } from 'google-auth-library';
import { pointer } from 'src/app-config';

class EmailServices {
    private Base = pointer.email;
    async GetGmailUrl(): Promise<string|null> {
        const data = await api.SendRequest(this.Base, 'GET');
        if (!data || !data.success || !data.url) return null;
        return data.url;
    }
    
    async GetGmailRequest(code: string): Promise<Credentials|null> {
        const data = await api.SendRequest(this.Base, 'POST', { code });
        if (!data || !data.success || !data.gmailKeys) return null;
        return data.gmailKeys;
    }
    
    async SaveNewGmailAPITokenToDB(accessToken: string, refreshToken: string): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PUT', { accessToken, refreshToken });
        return !!data?.success;
    }
}

export const emailServices = new EmailServices();
