import { generalBlue, lastDeployTimestamp } from 'src/app-config';
import { localStorageHelper } from '../../services';
import { logoutReducer, setValuesAndOpenAlertModalReducer } from 'src/store';
import { Navbar, Nav, Button, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsMobile } from 'src/custom-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useMemo, useState } from 'react';

const color = '#fbfbfb';

export const NavBar = () => {
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const location = useLocation();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const openLogoutConfirmModal = (): void => {
        setExpanded(false)
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: '¿Cerrar sesión?',
            message: '',
            execution: () => {
                dispatch(logoutReducer());
                navigate('/acceso');
            }
        }));
    }

    const navigateHandler = (url: string): void => {
        setExpanded(false);
        navigate(url);
    }

    const showAnimationOnNewsLink: boolean = useMemo(() => {
        const lastVisit = localStorageHelper.GetNewsVisited();
        const isOnWeek = Date.now() < lastDeployTimestamp + 7*24*60*60*1000;
        return isOnWeek && (!lastVisit || lastVisit < lastDeployTimestamp);
    // eslint-disable-next-line
    }, [window.location.pathname]);

    const isActive = (url: string) => location.pathname === url ? 'bold' : 'normal';

    return (
        <Navbar expanded={expanded} style={{ backgroundColor: generalBlue, zIndex: 200 }} expand={'lg'}>
            <Container fluid>
                <Navbar.Brand className={'pointer'}
                    style={{ color, fontWeight: isActive('/') }}
                    onClick={() => navigateHandler('/')} 
                >
                    &nbsp;&nbsp; INICIO
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(x => !x)} />
                <Navbar.Collapse>
                    <Nav className={'me-auto'}>
                        {user.isAuth ?
                            <>
                                <Nav.Link
                                    style={{ color, margin: isMobile ? '8px 0' : '0', fontWeight: isActive('/casa-en-casa') }}
                                    onClick={() => navigateHandler('/casa-en-casa')}
                                >
                                    &nbsp; &nbsp;Casa en Casa&nbsp; &nbsp;
                                </Nav.Link>
                                <Nav.Link
                                    style={{ color, margin: isMobile ? '8px 0' : '0', fontWeight: isActive('/telefonica') }}
                                    onClick={() => navigateHandler('/telefonica')}
                                >
                                    &nbsp; &nbsp;Telefónica&nbsp; &nbsp;
                                </Nav.Link>
                                <Nav.Link
                                    style={{ color, margin: isMobile ? '8px 0' : '0', fontWeight: isActive('/tablero') }}
                                    onClick={() => navigateHandler('/tablero')}
                                >
                                    &nbsp; &nbsp;Tablero&nbsp; &nbsp;
                                </Nav.Link>
                                {user.isCartsAdmin &&
                                    <Nav.Link
                                        style={{ color, margin: isMobile ? '8px 0' : '0', fontWeight: isActive('/carritos') }}
                                        onClick={() => navigateHandler('/admins/carritos')}
                                    >
                                        &nbsp; &nbsp;Carritos&nbsp; &nbsp;
                                    </Nav.Link>
                                }
                            </>
                            :
                            <Nav.Link
                                style={{ color, fontWeight: isActive('/acceso') }}
                                onClick={() => navigateHandler('/acceso')}
                            >
                                &nbsp; &nbsp;Entrar&nbsp; &nbsp;
                            </Nav.Link>
                        }
                        {(user.isHthAdmin || user.isTelephonicAdmin || user.isCartsAdmin || user.isBoardAdmin) &&
                            <Nav.Link
                                onClick={() => navigateHandler('/admins')}
                                style={{ color, margin: isMobile ? '8px 0' : '0', fontWeight: isActive('/admins') }}
                            >
                                &nbsp; &nbsp;Administradores&nbsp; &nbsp;
                            </Nav.Link>
                        }
                    </Nav>
                    {user.isAuth &&
                        <Nav >
                            <Nav.Link
                                style={{
                                    margin: isMobile ? '8px 0' : 0,
                                    paddingLeft:  isMobile ? '13px' : 0,
                                    fontWeight: isActive('/usuario')
                                }}
                                onClick={() => navigateHandler('/usuario')}
                            >
                                <span style={{ color }}> Mi Usuario </span> &nbsp;&nbsp;&nbsp;
                            </Nav.Link>
                            <Nav.Link className={showAnimationOnNewsLink ? isMobile ? 'animate__animated animate__flash animate__infinite infinite' : 'animate__animated animate__pulse animate__infinite infinite' : ''}
                                style={{
                                    margin: isMobile ? '8px 0' : 0,
                                    paddingLeft:  isMobile ? '13px' : 0,
                                    fontWeight: isActive('/novedades')
                                }}
                                onClick={() => navigateHandler('/novedades')}
                            >
                                <span style={{ color }}> Novedades </span> &nbsp;&nbsp;&nbsp;
                            </Nav.Link>
                            <Button className={`${isMobile ? 'my-3' : ''}`}
                                variant={'outline-info'}
                                style={{ color, borderColor: color }}
                                onClick={() => openLogoutConfirmModal()}
                            >
                                CERRAR SESIÓN
                            </Button>
                        </Nav>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
