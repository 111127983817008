import { BsArrowBarDown, BsArrowBarUp } from 'react-icons/bs';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { generalBlue } from 'src/app-config';
import { Hr } from '..';
import { hthHelper } from 'src/services';
import { typeBlock, typeHTHTerritory } from '@monorepo/models';
import { useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    filter: typeBlock[];
    territoryHTH: typeHTHTerritory;
    setFilter: Dispatch<SetStateAction<typeBlock[]>>;
    usingLettersForBlocks: boolean;
}

export const HTHBuildingsFilter: FC<propsType> = ({ filter, setFilter, territoryHTH, usingLettersForBlocks }) => {
    const [showFilter, setShowFilter] = useState(false);
    const isMobile = useGetIsMobile();

    return (
    <>
        <h1 className={'container pointer btn-general-blue text-white py-3'}
            style={{
                backgroundColor: generalBlue,
                fontSize: isMobile ? '1.8rem' : '2.4rem',
                fontWeight: 'bold',
                margin: isMobile ? '30px auto 20px auto' : '60px auto 40px auto',
                textAlign: 'center'
            }}
            onClick={() => setShowFilter(x => !x)}
        >
            {showFilter ? 'OCULTAR FILTROS' : 'VER FILTROS'} {showFilter ? <BsArrowBarUp size={isMobile ? '1.6rem' : '1.4rem'} /> : <BsArrowBarDown size={isMobile ? '1.6rem' : '1.4rem'} />}
        </h1>

        {showFilter &&
            <>
            <div className={'flex-wrap mt-4 mb-5'}>
                {[...new Set(territoryHTH.map.polygons.map(p => p.block))].map(b =>
                    <div key={b} className={'m-2'}>
                        <div className={'col card btn-grey c-pointer py-1 mx-auto'}
                            style={{ width: '250px' }}
                            onClick={() => filter.includes(b) ?
                                setFilter(x => x.filter(y => y !== b))
                                :
                                setFilter(x => [...x, b])
                            }
                        >
                            <div className={'d-flex justify-content-center'}>
                                <input type='checkbox'
                                    className={'form-check-input checkbox-xs checkbox-red me-2'}
                                    checked={filter.includes(b)}
                                    onChange={() => {}}
                                />
                                <label className={'h5 ms-2 c-pointer'} style={{ marginTop: '2px' }}>
                                    Manzana {hthHelper.MaskTheBlock(b, usingLettersForBlocks)}
                                </label>
                            </div>
                        </div>
                        <div className={'bg-light small text-center'}>
                            {territoryHTH.map.polygons.filter(p => p.block === b)?.map(p => p.street).join(', ')}
                        </div>
                    </div>
                )}
            </div>
            <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />
            </>
        }
    </>)
}
