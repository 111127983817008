import { Col, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FC, useEffect, useState } from 'react';
import { generalRed } from 'src/app-config';
import { typeBlock } from '@monorepo/models';
import { useGetIsMobile } from 'src/custom-hooks';

type radioObj = {
    name: string;
    value: string;
}

type propsType = {
    blocks?: typeBlock[];
    currentBlock?: typeBlock;
    setCurrentBlockHandler: (value: typeBlock) => void;
}

export const Col0a: FC<propsType> = ({ blocks, currentBlock, setCurrentBlockHandler }) => {
    const isMobile = useGetIsMobile();

    let radiosCurrentBlock: radioObj[] = [];
    blocks?.forEach((block: string) => {
        radiosCurrentBlock.push({ name: `Manzana ${block}`, value: block });
    });

    return (
        <Col className={'text-center mb-0 p-0'} style={{ maxWidth: '100%' }}>
            {blocks && !!blocks.length &&
                <div style={{ marginBottom: '10px' }}>
                    <ButtonGroup
                        vertical={isMobile && blocks.length > 3 ? true : false}
                        style={{ maxWidth: '100%' }}
                    >
                        {radiosCurrentBlock?.map((radio: radioObj) =>
                            <BlockToggleButton
                                currentBlock={currentBlock}
                                key={radio.value}
                                radio={radio}
                                setCurrentBlockHandler={setCurrentBlockHandler}
                            />
                        )}
                    </ButtonGroup>
                </div>
            }
        </Col>
    )
}

type propsType1 = {
    currentBlock?: typeBlock;
    radio: radioObj;
    setCurrentBlockHandler: (value: typeBlock) => void;
}

const BlockToggleButton: FC<propsType1> = ({ currentBlock, radio, setCurrentBlockHandler }) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setIsChecked(currentBlock === radio?.value);
    }, [currentBlock, radio]);
    
    return (
    <>
        <ButtonGroup>
            <ToggleButton type={'radio'}
                id='toggle'
                variant={isChecked ? 'danger' : 'dark'}
                name={"radio"}
                style={{
                    padding: '0',
                    backgroundColor: `${isChecked ? generalRed : ''}`,
                    borderTopLeftRadius: `${radio?.value === '1' ? '3px' : ''}`,
                    borderBottomLeftRadius: `${radio?.value === '1' ? '3px' : ''}`
                }}
                value={radio?.value}
                //checked={isChecked}
            >
                <div className={'text-white'}
                    style={{ height: '40px', lineHeight: '40px', width: '120px' }}
                    onClick={() => setCurrentBlockHandler(radio.value as typeBlock)}
                >
                    {radio?.name}
                </div>
            </ToggleButton>
        </ButtonGroup>
    </>)
}
