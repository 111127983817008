import { FC } from 'react';
import { generalBlue } from 'src/app-config';

type propsType = {
    label: string;
    setNext: () => void;
    setPrevious: () => void;
}

export const HorizontalSelector: FC<propsType> = ({ label, setNext, setPrevious }) => {
    return (
        <div className={'d-flex justify-content-center'}>
            <button className={'btn btn-secondary rounded-end-0 rounded-start-5 ps-3'}
                onClick={setPrevious}
            >
                Anterior
            </button>
            <div className={'form-control text-center rounded-0 text-white py-2'}
                style={{ backgroundColor: generalBlue, maxWidth: '250px' }}
            >
                <span>{label}</span>
            </div>
            <button className={'btn btn-secondary rounded-start-0 rounded-end-5 pe-3'}
                onClick={setNext}
            >
                Siguiente
            </button>
        </div>
    )
}
