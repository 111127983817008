import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import * as types from '@monorepo/models';

type addBuildingServiceResponse = {
    success: boolean;
    dataError: boolean;
    alreadyExists: boolean;
}

class HouseToHouseServices {
    private Base = pointer.houseToHouse;
    async AddDoNotCall(territory: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, polygonId: number, doNotCall: types.typeDoNotCall): Promise<boolean> {
        if (!territory || !block || !face || !polygonId || !doNotCall) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/do-not-call/${territory}/${block}/${face}`, 'POST', { doNotCall, polygonId });
        return !!data?.success;
    }    
    async AddObservation(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face:types.typeFace, polygonId: number, observation: types.typeObservation): Promise<boolean> {
        if (!territoryNumber || !block || !face || !polygonId || !observation) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/observation/${territoryNumber}/${block}/${face}`, 'POST', { observation, polygonId });
        return !!data?.success;
    }
    async AddPolygonFace(territoryNumber: types.typeTerritoryNumber, polygon: types.typePolygon): Promise<boolean> {
        if (!territoryNumber || !polygon) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/map/${territoryNumber}`, 'POST', { polygon });
        return !!data?.success;
    }
    async AssignTerritory(user: types.typeUser, toAssign: number | null, toUnassign: number | null, all: boolean | null): Promise<boolean> {
        all = !!all;
        const data = await api.SendRequest(`${this.Base}/assignment`, 'PUT', { all, user, toAssign, toUnassign });
        return !!data?.success;
    }
    async CreateTerritories(numberOfTerritories: number, lat: number, lng: number): Promise<boolean> {
        if (!numberOfTerritories || !lat || !lng) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/genesys`, 'POST', { numberOfTerritories, lat, lng });
        return !!data?.success;
    }
    async DeletePolygonFace(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, faceId: number): Promise<boolean> {
        if (!territoryNumber || !block || !face || !faceId) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/map/${territoryNumber}/${block}/${face}`, 'DELETE', { faceId });
        return !!data?.success;
    }
    async DeleteDoNotCall(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, doNotCallId: number): Promise<boolean> {
        if (!territoryNumber || !block || !face || !doNotCallId) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/do-not-call/${territoryNumber}/${block}/${face}`, 'DELETE', { doNotCallId });
        return !!data?.success;
    }
    async DeleteObservation(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face:types.typeFace, observationId: number): Promise<boolean> {
        if (!territoryNumber || !block || !face || !observationId) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/observation/${territoryNumber}/${block}/${face}`, 'DELETE', { observationId });
        return !!data?.success;
    }
    async EditObservation(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face:types.typeFace, observation: types.typeObservation): Promise<boolean> {
        if (!territoryNumber || !block || !face || !observation) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/observation/${territoryNumber}/${block}/${face}`, 'PATCH', { observation });
        return !!data?.success;
    }
    async EditMap(territoryNumber: types.typeTerritoryNumber, editedHTHMap: types.typeHTHMap, editedHTHPolygons: types.typePolygon[]): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/map/${territoryNumber}`, 'PATCH', { editedHTHMap, editedHTHPolygons });
        return !!data?.success;
    }
    async GetBuilding(congregation: number, territoryNumber: string, block: types.typeBlock, face: types.typeFace, streetNumber: number): Promise<types.typeResponseData|null> {
        // no token
        const data = await api.SendRequest(`${this.Base}/building/${congregation}/${territoryNumber}/${block}/${face}/${streetNumber}`, 'GET', null, false);
        return data;
    }
    async GetNumbersOfReleasedTerritories(congregation: number): Promise<number[] | null> {
        // no token
        const data = await api.SendRequest(`${this.Base}/released?congregation=${congregation}`, 'GET', null, false);
        return data?.numbersOfTerritories ?? null;
    }
    async GetFaceFromAddress(address: string): Promise<types.typeResponseData|null> {
        if (!address) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/face?address=${encodeURIComponent(address)}`, 'GET');
        return data;
    }
    async SetIsFinished(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock | null, face: types.typeFace | null, polygonId: number | null, isFinish: boolean, isAll: boolean = false): Promise<boolean> {
        if (!territoryNumber || isFinish === undefined) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/state/${territoryNumber}/${block}/${face}`, 'PATCH', { isFinish, polygonId, isAll });
        return !!data?.success;
    }
    async GetStreetsByTerritory(territoryNumber: types.typeTerritoryNumber): Promise<string[] | null> {
        if (!territoryNumber) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/street/${territoryNumber}`, 'GET');
        if (!data || !data.success || !data.streets) return null;
        return data.streets;
    }
    async GetTerritoriesForStatistics(): Promise<types.typeHTHTerritory[] | null> {
        const data = await api.SendRequest(`${this.Base}/map/territory-statistics`, 'GET');
        if (!data?.success || !data?.hthTerritories) return null;
        return data.hthTerritories;
    }
    async GetTerritoriesForMap(): Promise<types.typeHTHTerritory[] | null> {
        const data = await api.SendRequest(`${this.Base}/map/territory-map`, 'GET');
        if (!data?.success || !data?.hthTerritories) return null;
        return data.hthTerritories;
    }
    async GetTerritory(territoryNumber: string, congregation: number | null = null, block?: types.typeBlock|null): Promise<types.typeResponseData|null> {
        // no token
        if (!territoryNumber) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/${territoryNumber}${congregation ? `?congregation=${congregation}&block=${block ? block : ''}` : ''}`, 'GET', null, false);
        return data;
    }
    async RestructuringTerritories(newHthTerritories: types.typeHTHTerritory[]): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/restructuring`, 'PUT', { newHthTerritories });
        return !!data?.success;
    }
    //////// BUILDINGS
    async AddBuilding(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, newBuilding: types.typeHTHBuilding): Promise<addBuildingServiceResponse | null> {
        const data = await api.SendRequest(`${this.Base}/building/${territoryNumber}/${block}/${face}`, 'POST', { newBuilding });
        if (!data) return null;
        return {
            success: !!data.success,
            alreadyExists: !!data.alreadyExists,
            dataError: !!data.dataError
        };
    }
    async DeleteBuilding(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, streetNumber: number): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/building/${territoryNumber}/${block}/${face}`, 'DELETE', { streetNumber });
        return !!data?.success;
    }
    async EditBuildingAddress(currentBuildingAddress: types.typeAddress, newBuildingAddress: types.typeAddress): Promise<boolean> {
        if (!currentBuildingAddress || !newBuildingAddress) throw new Error("Faltan datos");
        const data = await api.SendRequest(`${this.Base}/building-address`, 'PATCH', { currentBuildingAddress, newBuildingAddress });
        return !!data?.success;
    }
    async ModifyHousehold(congregation: number, territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, streetNumber: number, householdId: number, isChecked: boolean, isManager: boolean, street: string): Promise<boolean> {
        // no token
        const data = await api.SendRequest(`${this.Base}/building/${congregation}/${territoryNumber}/${block}/${face}`, 'PATCH', { householdId, isChecked, isManager, street, streetNumber }, false);
        return !!data?.success;
    }
    // async SelectByVoice(audioBlob: Blob, congregation: number, territoryNumber?: types.typeTerritoryNumber, householdId?: number): Promise<boolean> {
    //     // no token
    //     const body = {
    //         audioBase64: await blobToBase64(audioBlob),
    //         congregation,
    //         householdId,
    //         mimeType: audioBlob.type,
    //         territoryNumber
    //     };
    //     const data = await api.SendRequest(`${this.Base}/building-voice`, 'PATCH', body, false);
    //     return !!data?.success;
    // }
    async SetIsSharedBuildings(territoryNumber: types.typeTerritoryNumber, block?: types.typeBlock, face?: types.typeFace, streetNumbers?: number[]): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/building/${territoryNumber}?block=${block ? block : ''}&face=${face ? face : ''}`, 'PUT', { streetNumbers });
        return !!data?.success;
    }
    async ShareBuildingForAMonth(territoryNumber: types.typeTerritoryNumber, block: types.typeBlock, face: types.typeFace, streetNumber: number): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/building/${territoryNumber}?block=${block}&face=${face}&streetNumber=${streetNumber}`, 'PATCH');
        return !!data?.success;
    }
}

export const houseToHouseServices = new HouseToHouseServices();
