import { typeConfigsDoc, typeUser } from '@monorepo/models';

class LocalStorageHelper {
    // token
    GetToken = (): string | null => localStorage.getItem('token');
    RemoveToken = (): void => localStorage.removeItem('token');
    SetToken = (token: string) => localStorage.setItem('token', token);
    // dark mode
    GetDarkMode = (): boolean => localStorage.getItem('darkMode') === 'true';
    SetDarkMode = (darkMode: boolean): void => localStorage.setItem('darkMode', (!!darkMode).toString());
    // failing email
    GetFailingEmail = (): string | null => localStorage.getItem('failingEmail');
    RemoveFailingEmail = (): void => localStorage.removeItem('failingEmail');
    SetFailingEmail = (failingEmail: string) => localStorage.setItem('failingEmail', failingEmail);
    // news animation
    GetNewsVisited = (): number | null => parseInt(localStorage.getItem('newsVisitLastTime') ?? '') || null;
    SetNewsVisited = (): void => localStorage.setItem('newsVisitLastTime', Date.now().toString());
    // config
    GetConfig = (): typeConfigsDoc | null => {
        const ls = localStorage.getItem('mw-config');
        if (!ls) return null;
        try {
            const config = JSON.parse(ls) as typeConfigsDoc;
            return config;
        } catch (error) {
            console.log("No se pudo parsear Config");
            return null;
        }
    }
    SetConfig = (config: typeConfigsDoc) => localStorage.setItem('mw-config', JSON.stringify(config));
    // user
    GetUser = (): typeUser | null => {
        const ls = localStorage.getItem('user');
        if (!ls) return null;
        try {
            const user = JSON.parse(ls) as typeUser;
            return user;
        } catch (error) {
            console.log("No se pudo parsear User");
            return null;
        }
    }
    SetUser = (user: typeUser) => localStorage.setItem('user', JSON.stringify(user));
    // weather
    GetWeatherAndForecast = () => localStorage.getItem('weather');
    SetWeatherAndForecast = (weatherAndForecast: string) => localStorage.setItem('weather', weatherAndForecast);
}

export const localStorageHelper = new LocalStorageHelper();
