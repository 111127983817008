import { H2, HorizontalSelector } from 'src/components/_commons';
import { helpers } from 'src/services';
import { useState } from 'react';

export const AdminsAVUshers = () => {
    const [weekNumber, setWeekNumber] = useState(helpers.GetWeekNumber(new Date()));

    return (
        <>
        <H2 title={'ASIGNACIONES DE AUDIO, VIDEO Y ACOMODADORES'} />
        <HorizontalSelector
            label={`Semana ${weekNumber}`}
            setPrevious={() => {
                // const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                setWeekNumber(x => x - 1);
            }}
            setNext={() => {
                // const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
                setWeekNumber(x => x + 1);
            }}
        />
        <div>
            <h5>En desarrollo</h5>
        </div>
        </>
    )
}
