import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeBoardItem, typeBoardItemType, typeBoardSection } from '@monorepo/models';

class BoardServices {
    private Base = pointer.board;
    async AddSection(title: string): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'POST', { title });
        return !!data?.success;
    }
    async DeleteSection(section: typeBoardSection): Promise<boolean> {
        const body = {
            section: {
                id: section.id,
                items: section.items.map(i => ({ ...i, data: null })),
                order: section.order,
                title: section.title
            }
        };
        const data = await api.SendRequest(this.Base, 'DELETE', body);
        return !!data?.success;
    }
    async EditSectionTitle(sectionId: number, sectionTitle: string, title: string): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { sectionId, sectionTitle, title });
        return !!data?.success;
    }
    async GetSections(): Promise<typeBoardSection[] | null> {
        const data = await api.SendRequest(this.Base, 'GET');
        if (!data?.success || !data?.boardSections) return null;
        return data.boardSections;
    }
    async ReorderSections(sections: typeBoardSection[]): Promise<boolean> {
        const body = {
            sections: sections.map(section => ({
                id: section.id,
                items: section.items.map(i => ({ ...i, data: null })),
                order: section.order,
                title: section.title
            }))
        };
        const data = await api.SendRequest(this.Base, 'PATCH', body);
        return !!data?.success;
    }
    // ITEMS
    async AddItem(sectionId: number, sectionTitle: string, itemType: typeBoardItemType, title: string | null, content: string | null, file: File | null): Promise<boolean> {
        if (file) {
            const success = await api.SendRequestSendFile(`${this.Base}/item?sectionId=${sectionId}&sectionTitle=${sectionTitle}&itemType=${itemType}`, file);
            return success;
        } else {
            const data = await api.SendRequest(`${this.Base}/item?sectionId=${sectionId}&sectionTitle=${sectionTitle}&itemType=${itemType}`, 'POST', { content, title });
            return !!data?.success;
        }
    }
    async ArchiveFile(sectionId: number, sectionTitle: string, item: typeBoardItem, unarchive: boolean): Promise<boolean> {
        const body = {
            sectionId,
            sectionTitle,
            item: { ...item, data: null },
            unarchive
        };
        const data = await api.SendRequest(`${this.Base}/item`, 'PUT', body);
        return !!data?.success;
    }
    async DeleteItem(sectionId: number, sectionTitle: string, item: typeBoardItem): Promise<boolean> {
        const body = {
            item: { ...item, data: null },
            sectionId,
            sectionTitle
        };
        const data = await api.SendRequest(`${this.Base}/item`, 'DELETE', body);
        return !!data?.success;
    }
    async GetArchivedFile(sectionTitle: string, item: typeBoardItem, openingNewTab: boolean): Promise<boolean> {
        const itemJson = JSON.stringify({ ...item, data: null });
        const success = await api.SendRequestDownloadFile(`${this.Base}/item?sectionTitle=${sectionTitle}&item=${itemJson}`, openingNewTab, item.originalName);
        return success;
    }
    async ReorderItems(sectionId: number, sectionTitle: string, items: typeBoardItem[]): Promise<boolean> {
        const body = {
            items: items.map(i => i.type === 'file' ? { ...i, data: null } : i),
            sectionId,
            sectionTitle
        };
        const data = await api.SendRequest(`${this.Base}/item`, 'PATCH', body);
        return !!data?.success;
    }
}

export const boardServices = new BoardServices();
