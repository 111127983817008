import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorageHelper } from 'src/services';
import { typeUser, unauthenticatedConfig, unauthenticatedUser } from '@monorepo/models';

export const userSlice = createSlice({
    name: 'user',
    initialState: localStorageHelper.GetUser() ?? unauthenticatedUser,
    reducers: {
        logoutReducer: (state) => {
            localStorageHelper.RemoveToken();
            localStorageHelper.SetUser(unauthenticatedUser);
            localStorageHelper.SetConfig(unauthenticatedConfig);
            state = unauthenticatedUser;
            return state;
        },
        refreshUserReducer: (state, action: PayloadAction<typeUser>) => {
            localStorageHelper.SetUser(action.payload);
            state = action.payload;
            return state;
        }
    }
});

export const { logoutReducer, refreshUserReducer } = userSlice.actions;
