import { BsEye } from 'react-icons/bs';
import { CartCalendarModalAdd } from './CartCalendarModalAdd';
import { CartCalendarModalDetails } from './CartCalendarModalDetails';
import { daysOfTheWeekP, daysOfWeekMobileD, months } from 'src/app-config';
import { generalHelpers } from '@monorepo/helpers';
import { typeCartAssignment, typeCartsDoc, typeCartPeriod, typeCartPoint } from '@monorepo/models';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useState, FC, Dispatch, SetStateAction, useMemo } from 'react';

type propsType = {
    cartDoc: typeCartsDoc;
    deleteAssignment?: (assignment: typeCartAssignment) => void;
    month: number;
    point: typeCartPoint;
    readOnly?: boolean;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    year: number;
}

export const CartCalendarItem: FC<propsType> = ({
    cartDoc, deleteAssignment, month, point, readOnly, setRefreshCounter, year
}) => {
    const [newAssignment, setNewAssignment] = useState<typeCartAssignment | null>(null);
    const [selectedAssignment, setSelectedAssignment] = useState<typeCartAssignment | null>(null);
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const enabledPeriods: typeCartPeriod[] = useMemo(() => {
        const periods = readOnly ? [] : [...point.enabledPeriods] as typeCartPeriod[];
        for (let i = 0; i < cartDoc.assignments.length; i++) {
            const assignment = cartDoc.assignments[i];
            if (assignment.pointId !== point.id) continue;
            const aPeriod: typeCartPeriod = {
                endHour: assignment.endHour ?? 0,
                endMinutes: assignment.endMinutes ?? 0,
                id: assignment.id,
                startHour: assignment.startHour ?? 0,
                startMinutes: assignment.startMinutes ?? 0
            };
            if (!periods.some(p => p.endHour === aPeriod.endHour && p.endMinutes === aPeriod.endMinutes && p.startHour === aPeriod.startHour && p.startMinutes === aPeriod.startMinutes)) {
                periods.push(aPeriod);
            }
        }
        periods.sort((p1, p2) => p1.startHour - p2.startHour || p1.startMinutes - p2.startMinutes || p1.endHour - p2.endHour || p1.endMinutes - p2.endMinutes);
        return periods;
    }, [cartDoc.assignments, point.enabledPeriods, point.id, readOnly]);

    return (
        <div className={`calendar border rounded ${isDarkMode ? 'bg-dark' : ''} shadow-sm mb-5`}>
            {selectedAssignment &&
                <CartCalendarModalDetails
                    closeModal={() => setSelectedAssignment(null)}
                    deleteAssignment={deleteAssignment}
                    selectedAssignment={selectedAssignment}
                />
            }
            {newAssignment &&
                <CartCalendarModalAdd
                    cartDoc={cartDoc}
                    closeModal={() => setNewAssignment(null)}
                    newAssignment={newAssignment}
                    setRefreshCounter={setRefreshCounter}
                />
            }
            <h3 className={'text-center mb-3 mt-2'}>
                {point.name} - {months[month]}
            </h3>
            <div className={'calendar-header d-flex border-bottom'}>
                <div className={'calendar-header-time flex-fill p-1'}></div>
                {(isMobile ? daysOfWeekMobileD : daysOfTheWeekP).slice(1).map(day =>
                    <div key={day} className={'calendar-header-day flex-fill text-center fw-bold border-start'}>
                        {day}
                    </div>
                )}
            </div>
            <div>
                {enabledPeriods.map(period =>
                    <div key={period.id} className={'calendar-row d-flex align-items-start border-bottom py-1'}>
                        <div className={'calendar-time flex-fill text-center fw-bold border-end'}>
                            <span>{generalHelpers.GetTimeFromPeriodObject(period)}</span>
                        </div>
                        {Array.from({ length: 7 }, (_, i) => i).filter(i => !!i).map(weekday => {
                            const assignment = cartDoc.assignments.find(a =>
                                a.pointId === point.id
                                && a.year === year
                                && a.month === month
                                && a.weekday === weekday
                                && a.startHour === period.startHour
                                && a.startMinutes === period.startMinutes
                                && a.endHour === period.endHour
                                && a.endMinutes === period.endMinutes
                            );
                            return (
                                <div key={weekday}
                                    className={'calendar-cell flex-fill text-center border-start pointer custom-card'}
                                    onClick={assignment
                                        ? () => setSelectedAssignment(assignment)
                                        : readOnly ? () => {} : () => {
                                            setNewAssignment({
                                                address: point.address,
                                                endHour: period.endHour,
                                                endMinutes: period.endMinutes,
                                                id: Date.now(),
                                                month,
                                                name: point.name,
                                                participants: [],
                                                pickupCartPoint: point.pickupCartPoint,
                                                pointId: point.id,
                                                startHour: period.startHour,
                                                startMinutes: period.startMinutes,
                                                wasDeleted: false,
                                                weekday,
                                                year
                                            });
                                        }
                                    }
                                >
                                    {assignment ?
                                        (isMobile ?
                                            <div className={'calendar-assignment rounded p-2'}>
                                                <BsEye />
                                            </div>
                                            :
                                            <div className={'calendar-assignment rounded'}>
                                                {assignment.participants?.map(p =>
                                                    <div key={p.userId} className={`${p.name} ${p.lastName}`.length > 12 ? 'marquee' : ''}>
                                                        {p.name} {p.lastName}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                        :
                                        <div className={'muted'} style={{ height: '120px' }}></div>
                                    }
                                </div>
                            );
                        })}
                    </div>
                )}
                <div>
                    <div className={'text-center mt-1 fw-bold'}>
                        <p className={'mb-1'}>
                            Punto de retiro: {point.pickupCartPoint}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}
