import { FC, useEffect, useMemo, useState } from 'react';
import { H2, Loading } from 'src/components/_commons';
import { io } from 'socket.io-client';
import { SERVER } from 'src/app-config';
import { socketIOEvents } from '@monorepo/helpers';
import { TelephonicAssignmentModal } from './subcomponents/TelephonicAssignmentModal';
import { TelephonicResetModal } from './subcomponents/TelephonicResetModal';
import { telephonicServices, userServices } from 'src/services';
import { typeTelephonicTerritory, typeUser } from '@monorepo/models';
import { useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useNavigate } from 'react-router-dom';
import { UsersSelector } from 'src/components/_commons';

const socket = io(SERVER, { withCredentials: true });

export const AdminsTelephonic = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [query, setQuery] = useState('');
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [selectedOption, setSelectedOption] = useState(1);
    const [showOpened, setShowOpened] = useState(true);
    const [territories, setTerritories] = useState<typeTelephonicTerritory[] | null>(null);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    const usersToShow: typeUser[] = useMemo(() =>
        users?.filter(x =>
            x.email.toLowerCase().includes(query.toLowerCase())
            || x.name?.toLowerCase().includes(query.toLowerCase())
            || x.lastName?.toLowerCase().includes(query.toLowerCase())
            || `${x.name ?? ''} ${x.lastName ?? ''}`.toLowerCase().includes(query.toLowerCase())
            || `${x.lastName ?? ''} ${x.name ?? ''}`.toLowerCase().includes(query.toLowerCase())
        ) ?? []
    , [query, users]);

    const territoriesToShow: typeTelephonicTerritory[] = useMemo(() => {
        if (!territories) return [];
        const copy = !!query ? [...territories.filter(t => t.assignments.some(a => usersToShow.map(u => u.id).includes(a)))] : [...territories];
        if (selectedOption === 1) {
            return copy.sort((a, b) => parseInt(a.territoryNumber) - parseInt(b.territoryNumber));
        } else if (selectedOption === 2) {
            return copy.sort((a, b) => new Date(a.last ?? '').getTime() - new Date(b.last ?? 0).getTime());
        } else if (selectedOption === 3) {
            return copy.sort((a, b) =>
                a.leftRel === '-' ?
                    parseFloat(b.leftRel ?? '0')
                    :
                    b.leftRel === '-' ?
                        parseFloat(a.leftRel ?? '0')
                        :
                        parseFloat(b.leftRel ?? '0') - parseFloat(a.leftRel ?? '0')
                )
            ;
        } else {
            return copy.sort((a, b) => (b.total ?? 0) - (a.total ?? 0));
        }
    }, [query, selectedOption, territories, usersToShow]);

    useEffect(() => {
        telephonicServices.GetGlobalStatistics().then(_territories => {
            setIsLoading(false);
            if (_territories) setTerritories(_territories);
        });
        userServices.GetAll().then(_users => {
            setUsers(_users);
        });
    }, [refreshCounter]);

    useEffect(() => {
        socket.on(socketIOEvents.userChange, (_congregation: number) => {
            if (_congregation !== user.congregation) return;
            setRefreshCounter(x => x + 1);
        });
        return () => {
            socket.off(socketIOEvents.userChange);
        };
    }, [user.congregation]);

    if (isLoading) return <Loading mt={'60px'} mb={'20px'} />;

    return (
    <>
        <H2 title={'ADMINISTRACIÓN TELEFÓNICA'} />

        <div className={'my-5'}>
            {!!territories?.length &&
                <div className={'row'}>
                    <div className={`col-md-auto card ${isDarkMode ? 'bg-dark text-white' : ''} pt-4 ${isMobile ? 'mb-2' : ''}`} style={{ backgroundColor: '#f6f6f8' }}>
                        <div className={'form-check'}>
                            <label className={`form-check-label ${isDarkMode ? 'text-white' : ''}`} htmlFor={'frd1'}>
                                Ordenar por territorio
                            </label>
                            <input className={'form-check-input ms-0 me-2'}
                                type={'radio'} name={'frd'} id={'frd1'} value={1}
                                onChange={e => setSelectedOption(parseInt(e.target.value))}
                                checked={selectedOption === 1}
                            />
                        </div>
                        <div className={'form-check'}>
                            <label className={`form-check-label ${isDarkMode ? 'text-white' : ''}`} htmlFor={'frd2'}>
                                Ordenar por última vez
                            </label>
                            <input className={'form-check-input ms-0 me-2'}
                                type={'radio'} name={'frd'} id={'frd2'} value={2}
                                onChange={e => setSelectedOption(parseInt(e.target.value))}
                                checked={selectedOption === 2}
                            />
                        </div>
                        <div className={'form-check'}>
                            <label className={`form-check-label ${isDarkMode ? 'text-white' : ''}`} htmlFor={'frd3'}>
                                Ordenar por % de completado
                            </label>
                            <input className={'form-check-input ms-0 me-2'}
                                type={'radio'} name={'frd'} id={'frd3'} value={3}
                                onChange={e => setSelectedOption(parseInt(e.target.value))}
                                checked={selectedOption === 3}
                            />
                        </div>
                        <div className={'form-check'}>
                            <label className={`form-check-label ${isDarkMode ? 'text-white' : ''}`} htmlFor={'frd4'}>
                                Ordenar por tamaño
                            </label>
                            <input className={'form-check-input ms-0 me-2'}
                                type={'radio'} name={'frd'} id={'frd4'} value={4}
                                onChange={e => setSelectedOption(parseInt(e.target.value))}
                                checked={selectedOption === 4}
                            />
                        </div>
                        <div className={'form-check my-2'}>
                            <input className={'form-check-input ms-0 me-2'} type={'checkbox'} id={'fcc1'}
                                value={''} checked={showOpened} onChange={() => setShowOpened(x => !x)}
                            />
                            <label className={`form-check-label ${isDarkMode ? 'text-white' : ''}`} htmlFor={'fcc1'}>
                                Ver Abiertos
                            </label>
                        </div>
                    </div>
                    <div className={'col-md-6'}>
                        <UsersSelector
                            setQuery={setQuery}
                            usersToShow={usersToShow}
                        />
                    </div>
                </div>
            }
        </div>
        <div className={'overflow-auto'}>
            <table className={`table ${isDarkMode ? 'table-dark' : ''} table-hover`}>
                <thead>
                    <tr>
                        <th className={''}>TERR</th>
                        <th className={''}>ASIGNADO A</th>
                        <th className={'text-center'}>ASIGNAR</th>
                        <th className={'text-center'}>ESTADO</th>
                        <th className={'text-center'}>TOTAL</th>
                        <th className={'text-center'}>QUEDAN</th>
                        <th className={'text-center'}>NO ABON.</th>
                        <th className={'text-center'}>ÚLT. VEZ</th>
                        <th className={'text-center'}>ÚLT. RESETEADO</th>
                        <th className={'text-center'}>RESETEAR</th>
                    </tr>
                </thead>
                <tbody>
                    {!!territoriesToShow?.length ?
                        territoriesToShow.map(territory =>
                            <Territory key={territory.territoryNumber}
                                showOpened={showOpened}
                                t={territory}
                                users={users}
                            />
                        )
                        :
                        <tr>
                            <td colSpan={isMobile ? 3 : 7}> No hay datos </td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </>)
}

type propsType = {
    showOpened: boolean;
    t: typeTelephonicTerritory;
    users: typeUser[] | null;
}

const Territory: FC<propsType> = ({ showOpened, t, users }) => {
    const [showAssignmentModal, setShowAssignmentModal] = useState(false);
    const [showResetModal, setShowResetModal] = useState(false);
    const navigate = useNavigate();

    return (
        <tr className={t.opened && !showOpened ? 'd-none' : ''}>
            {showAssignmentModal &&
                <TelephonicAssignmentModal
                    closeModal={() => setShowAssignmentModal(false)}
                    t={t}
                    users={users}
                />
            }
            {showResetModal &&
                <TelephonicResetModal
                    closeModal={() => setShowResetModal(false)}
                    t={t}
                />
            }
            <td className={'pointer'} onClick={() => navigate(`/telefonica/${t.territoryNumber}`)}>
                {t.territoryNumber}
            </td>
            <td style={{ maxWidth: '350px' }}>
                {!!users?.filter(u => u.phoneAssignments?.includes(t.number))?.length ?
                    users?.filter(u => u.phoneAssignments?.includes(t.number)).map((u, i, t) =>
                        <div key={u.id} className={i === t.length - 1 ? '' : 'mb-1'}>
                            {u.name} {u.lastName}
                        </div>
                    )
                    :
                    '-'
                }
            </td>
            <td className={'text-center'}>
                <button className={'btn btn-general-blue'} onClick={() => setShowAssignmentModal(true)}>
                    CAMBIAR
                </button>
            </td>
            <td className={`text-center ${t.opened ? 'bg-success' : 'bg-danger'} `}>
                {t.opened ? 'ABIERTO' : 'CERRADO'}
            </td>
            <td className={'text-center'}>{t.total}</td>
            <td className={'text-center'}>{t.left}</td>
            <td className={'text-center'}>{t.unsubscribed}</td>
            <td className={'text-center'}>{t.last}</td>
            <td className={'text-center'}>2023-01-01</td>
            <td className={'text-center'}>
                <button className={'btn btn-general-blue'} onClick={() => setShowResetModal(true)}>
                    RESETEAR
                </button>
            </td>
        </tr>
    )
}
