import { boardServices, helpers } from 'src/services';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { typeBoardSection } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    sections: typeBoardSection[] | null;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardAddSection: FC<propsType> = ({ sections, setCounterReload }) => {
    const [title, setTitle] = useState('');
    const [showForm, setShowForm] = useState(false);
    const isDarkMode = useGetIsDarkMode();
    const dispatch = useDispatch()

    const isValid: boolean = useMemo(() =>
        title.length > 5
        && !sections?.map(s => helpers.NormalizeString(s.title)).includes(helpers.NormalizeString(title))
    , [sections, title]);

    const addBoardSection = async () => {
        if (!isValid) return;
        dispatch(showLoadingModalReducer());
        const success = await boardServices.AddSection(title);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
            setTitle('');
            setShowForm(false);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    return (
        <div>
            {showForm ?
                <div className={`card ${isDarkMode ? 'bg-dark text-white' : ''}`}
                    style={{ margin: '60px auto', maxWidth: '450px', padding: '25px' }}
                >
                    <div className={'card-title text-center mb-4'}> CREAR SECCIÓN DE TABLERO </div>

                    <div className={'mb-3'}>
                        <input type={'text'}
                            autoComplete={'off'}
                            className={'form-control'}
                            placeholder={'Título'}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            onKeyDown={e => e.key === 'Enter' ? addBoardSection() : null}
                            autoFocus
                        />
                    </div>

                    <button className={'btn btn-general-blue my-2'}
                        onClick={addBoardSection}
                        disabled={!isValid}
                    >
                        Aceptar
                    </button>

                    <button className={'btn btn-general-red'}
                        onClick={() => {
                            setTitle('');
                            setShowForm(false);
                        }}
                    >
                        Cancelar
                    </button>
                </div>
                :
                <div className={'text-center mt-5'}>
                    <button className={'btn btn-general-blue'}
                        style={{ width: '220px' }}
                        onClick={() => setShowForm(true)}
                    >
                        Agregar Sección de Tablero
                    </button>
                </div>
            }
        </div>
    )
}
