import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typePublicMinistryDoc } from '@monorepo/models';

class PublicMinistryServices {
    private _base = pointer.publicMinistry;
    //
    GetData = async (): Promise<typePublicMinistryDoc | null> => {
        const data = await api.SendRequest(this._base, 'GET');
        return data?.publicMinistryDoc ?? null;
    }
}

export const publicMinistryServices = new PublicMinistryServices();
