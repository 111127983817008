import { cartServices } from 'src/services';
import { Checkbox } from './_Checkbox';
import { daysOfTheWeekP, months } from 'src/app-config';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { Loading } from 'src/components/_commons';
import { typeCartsDoc, typeCartPeriod, typeNotificationsDoc } from '@monorepo/models';

type propsType = {
    notificationDoc: typeNotificationsDoc;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartsNotifications: FC<propsType> = ({ notificationDoc, setRefreshCounter }) => {
    const [cartDoc, setCartDoc] = useState<typeCartsDoc | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        cartServices.GetDoc().then(_cartDoc => {
            setIsLoading(false);
            const filtered = _cartDoc?.assignments
                ?.filter(a => !_cartDoc.points?.find(p => p.id === a.pointId)?.wasDeleted)
                .filter(a => new Date(a.year, a.month) >= new Date(new Date().getFullYear(), new Date().getMonth()))
                .sort((a1, a2) => (a1.name ?? '').localeCompare(a2.name ?? '') || new Date(a1.year, a1.month).getTime() - new Date(a2.year, a2.month).getTime() || a1.weekday - a2.weekday || (a1.startHour ?? 0) - (a2.startHour ?? 0))
                ?? [];
            if (_cartDoc) setCartDoc({ ..._cartDoc, assignments: filtered });
        });
    }, []);

    return (
        <div>
            <Checkbox
                checked={notificationDoc.enabledCarts}
                label={'Recordar el día anterior por WhatsApp'}
                newNotificationDoc={{ ...notificationDoc, enabledCarts: !notificationDoc.enabledCarts }}
                setRefreshCounter={setRefreshCounter}
            />
            {isLoading ?
                <Loading mt={'50px'} />
                :
                <div>
                    <h3>Asignados</h3>
                    {!!cartDoc?.assignments.length ?
                        cartDoc.assignments.map(a =>
                            <li key={a.id} className={'mt-2'}>
                                <span className={'fw-bold'}>{a.participants[0].name} {a.participants[0].lastName}</span>
                                &nbsp;<span className={a.participants[0].phoneNumber ? 'bg-success text-white' : 'bg-secondary text-white'}>(Número {a.participants[0].phoneNumber || 'no cargado'})</span>
                                &nbsp;y <span className={'fw-bold'}>{a.participants[1].name} {a.participants[1].name}</span>
                                &nbsp;<span className={a.participants[1].phoneNumber ? 'bg-success text-white' : 'bg-secondary text-white'}>(Número {a.participants[1].phoneNumber || 'no cargado'})</span>
                                &nbsp;en <span className={'fw-bold'}>{a.name}</span>
                                &nbsp;los {daysOfTheWeekP[a.weekday]}
                                &nbsp;de {months[a.month]}/{a.year}
                                &nbsp;a las {generalHelpers.GetTimeFromPeriodObject(a as typeCartPeriod)} hs.
                            </li>
                        )
                        :
                        <>No hay ninguna asignación</>
                    }
                </div>
            }
        </div>
    )
}
