import { Loading } from 'src/components/_commons';
import { PasswordAuthentication } from './subcomponents/PasswordAuthentication';
import { PhoneAuthentication } from './subcomponents/PhoneAuthentication';
import { refreshUserReducer, setConfigurationReducer, setValuesAndOpenAlertModalReducer } from 'src/store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetCurrentUser } from 'src/custom-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { userServices } from 'src/services';

export const LoginPage = () => {
    const [loading, setLoading] = useState(false);
    const [returnUrl, setReturnUrl] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const [showPasswordless, setShowPasswordless] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const openAlertModalHandler = (title: string, message: string, animation: number, execution?: Function): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title,
            message,
            animation,
            execution
        }));
    }

    const loginUsingToken = async (newToken: string): Promise<void> => {
        setLoading(true);
        const { user, config } = await userServices.GetUserByToken(newToken);
        setLoading(false);
        if (!user || !config) {
            openAlertModalHandler("Problemas (2)", "Refrescar la página; ver si hay internet", 2);
            return;
        }
        dispatch(refreshUserReducer(user));
        dispatch(setConfigurationReducer(config));
    }

    useEffect(() => {
        setTimeout(() => {
            const url = searchParams.get('url');
            const returnUrlSs = window.sessionStorage.getItem('returnUrl');
            if (url && (!returnUrlSs || JSON.parse(returnUrlSs).expirity < Date.now() || JSON.parse(returnUrlSs).returnUrl !== url)) {
                setReturnUrl(url);
            }
        }, 300);
        setTimeout(() => {
            if (user.isAuth) {
                if (returnUrl) {
                    window.sessionStorage.setItem('returnUrl', JSON.stringify({ returnUrl, expirity: Date.now() + 2000 }));
                    navigate(returnUrl);
                } else {
                    navigate('/');
                }
            }
        }, 500);
    }, [navigate, returnUrl, searchParams, user.isAuth]);

    return (<>
        {showPasswordless ?
            <PhoneAuthentication
                loginUsingToken={loginUsingToken}
                openAlertModalHandler={openAlertModalHandler}
                usePassword={() => setShowPasswordless(false)}
            />
            :
            <PasswordAuthentication
                loginUsingToken={loginUsingToken}
                openAlertModalHandler={openAlertModalHandler}
                returnUrl={returnUrl}
                usePasswordless={() => setShowPasswordless(true)}
            />
        }

        {loading && <Loading mt={'40px'} />}

    </>)
}
