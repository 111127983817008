import { BsTrash } from 'react-icons/bs';
import { CartAssignmentAdd } from './CartAssignmentAdd';
import { daysOfTheWeekP, months } from 'src/app-config';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { helpers } from 'src/services';
import { typeCartsDoc, typeCartAssignment, typeCartPeriod } from '@monorepo/models';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    cartDoc: typeCartsDoc;
    deleteAssignment: (assignment: typeCartAssignment) => void;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartAssignmentList: FC<propsType> = ({ cartDoc, deleteAssignment, setRefreshCounter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [showFormAdd, setShowFormAdd] = useState(false);
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const sample: typeCartAssignment[] = useMemo(() =>
        cartDoc.assignments.filter(a => {
            const dateLimit = new Date(a.year, a.month) >= new Date(new Date().getFullYear(), new Date().getMonth());
            const lowerSearchTerm = searchTerm.toLowerCase();
            const monthMatch = months[a.month].toLowerCase().includes(lowerSearchTerm);
            const participantMatch = a.participants.some(p =>
                `${p.name?.toLowerCase()} ${p.lastName?.toLowerCase()}`.includes(lowerSearchTerm)
                || `${p.lastName?.toLowerCase()} ${p.name?.toLowerCase()}`.includes(lowerSearchTerm)
            );
            const pointMatch = a.name?.toLowerCase().includes(lowerSearchTerm);
            const weekdayMatch = daysOfTheWeekP[a.weekday].toLowerCase().includes(lowerSearchTerm);
            return dateLimit && (monthMatch || participantMatch || pointMatch || weekdayMatch);
        })
    , [cartDoc.assignments, searchTerm]);

    useEffect(() => {
        helpers.GoToTop();
    }, [showFormAdd]);

    return (
        <div className={'container my-3'} style={{ maxWidth: '850px' }}>
            <div className={'d-flex justify-content-between align-items-center my-4'}>
                <h2 className={'text-center flex-grow-1'}>
                    {!showFormAdd ?
                        <>Asignaciones - <span className={'small'}>Viendo {sample.length}</span></>
                        :
                        'Agregar Asignación'
                    }
                </h2>
                <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'} ${isMobile ? 'btn-sm' : ''}`}
                    onClick={() => setShowFormAdd(!showFormAdd)}
                >
                    {showFormAdd ? 'Cancelar' : 'Agregar Asignación'}
                </button>
            </div>
            {showFormAdd ?
                <CartAssignmentAdd
                    cartDoc={cartDoc}
                    closeModal={() => setShowFormAdd(false)}
                    newAssignment={null}
                    setRefreshCounter={setRefreshCounter}
                />
                :
                <>
                <div>
                    <input type={'text'}
                        className={'form-control mb-4'}
                        placeholder={'Buscar por punto, día o hermano...'}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                </div>
                <div className={'row'}>
                    {sample?.map(a =>
                        <div key={a.id} className={'mb-5'}>
                            <div className={`card ${isDarkMode ? 'bg-dark text-white border-light' : ''}`}>
                                <div className={'card-body'}>
                                    <h5 className={'card-title text-center'}>
                                        {a.name}
                                    </h5>
                                    <p className={'card-text my-1'}>
                                        Mes: <strong>{months[a.month]} {a.year}</strong>
                                    </p>
                                    <p className={'card-text my-1'}>
                                        Dia: <strong>{daysOfTheWeekP[a.weekday]}</strong>
                                    </p>
                                    <p className={'card-text my-1'}>
                                        Horario: <strong>{generalHelpers.GetTimeFromPeriodObject(a as typeCartPeriod)}</strong>
                                    </p>
                                    <div className={'card-text my-1'}>
                                        Asignados: <strong>{a.participants[0].name} {a.participants[0].lastName}</strong> y <strong>{a.participants[1].name} {a.participants[1].lastName}</strong>
                                    </div>
                                </div>
                                <div className={`card-footer d-flex justify-content-end ${isDarkMode ? 'border-light' : ''}`}>
                                    <button className={'btn btn-general-red'}
                                        onClick={() => deleteAssignment(a)}
                                    >
                                        Eliminar <BsTrash />
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                </>
            }
        </div>
    )
}
