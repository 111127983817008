import { FC, useState } from 'react';
import { houseToHouseServices, hthHelper } from 'src/services';
import { HTHItemCard } from 'src/components/_commons';
import { HTHObservationsForm } from '..';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeObservation, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetCurrentUser } from 'src/custom-hooks';

type propsType = {
    closeShowAddFormHandler: () => void;
    currentFace: typePolygon;
    observation: typeObservation;
    territoryNumber: typeTerritoryNumber;
}

export const HTHObservationsItem: FC<propsType> = ({ closeShowAddFormHandler, currentFace, observation, territoryNumber }) => {
    const [showForm, setShowForm] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const user = useGetCurrentUser();

    const editHandler = (): void => {
        closeShowAddFormHandler();
        setShowForm(true);
    }

    const deleteHandler = (): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: '¿Eliminar Observación?',
            message: `Se va a eliminar esta observación de la Manzana ${hthHelper.MaskTheBlock(currentFace.block, config.usingLettersForBlocks)}, cara ${currentFace.street}: "${observation.text}"`,
            execution: () => {
                houseToHouseServices.DeleteObservation(territoryNumber, currentFace.block, currentFace.face, observation.id).then((success: boolean) => {
                    if (!success) {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            mode: 'alert',
                            title: 'Algo falló',
                            message: 'Refrescar la página e intentar otra vez',
                            animation: 2
                        }));
                        return;
                    }
                });
            }
        }));
    }

    const closeShowFormHandler = (): void => {
        setShowForm(false);
    }

    return (
        <>
            <HTHItemCard
                creatorId={observation.creatorId}
                date={observation.date}
                deleteHandler={deleteHandler}
                editHandler={editHandler}
                text={observation.text}
            />

            {/* edit observation form */}
            {(user.isAdmin || observation.creatorId === user.id) && showForm &&
                <HTHObservationsForm
                    closeShowFormHandler={closeShowFormHandler}
                    currentFace={currentFace}
                    territoryNumber={territoryNumber}
                    // specific
                    editText={observation.text}
                    idEdit={observation.id}
                />
            }
        </>
    )
}
