import { FC, useEffect, useState } from 'react';
import { telephonicHelper } from 'src/services';
import { typeHousehold } from '@monorepo/models';
import { WarningToaster } from 'src/components/_commons';

type propsType = {
    households?: typeHousehold[];
}

export const FewHouseholdsWarning: FC<propsType> = ({ households }) => {
    const [numberOfFreePhones, setNumberOfFreePhones] = useState<number>();
    const [showToaster, setShowToaster] = useState(true);

    const closeWarningToaster = (): void => setShowToaster(false);
    
    useEffect(() => {
        if (households && households.length) setNumberOfFreePhones(telephonicHelper.GetNumberOfFreePhones(households));
        return () => setNumberOfFreePhones(undefined);
    }, [households]);

    return (
        <>
            {showToaster && numberOfFreePhones !== undefined && numberOfFreePhones < 50 &&
                <WarningToaster
                    bodyText={numberOfFreePhones > 0 ?
                        <span> A este territorio le quedan solo {numberOfFreePhones} teléfonos para llamar </span>
                        :
                        <span> No quedan teléfonos para llamar </span>}
                    closeWarningToaster={closeWarningToaster}
                    headerText={<strong>Recordar pedir otro</strong>}
                />
            }
        </>
    )
}
