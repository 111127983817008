import { H2, Hr, Loading } from 'src/components/_commons';
import { houseToHouseServices, hthHelper } from 'src/services';
import { HTHAllBuildings, HTHAllDoNotCalls } from 'src/components/_commons';
import { io } from 'socket.io-client';
import { SERVER } from 'src/app-config';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { socketIOEvents } from '@monorepo/helpers';
import { typeBlock, typeHTHTerritory, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useGetIsMobile, useIdleTimeout } from 'src/custom-hooks';
import { useLocation, useNavigate } from 'react-router';

const socket = io(SERVER, { withCredentials: true });

export const HTHSearchPage = () => {
    const queryString = new URLSearchParams(useLocation().search);
    //
    const block = queryString.get('manzana') as typeBlock ?? null;
    const congregation = parseInt(queryString.get('grupo') || '0');
    const territoryNumber = queryString.get('lugar') as typeTerritoryNumber;
    //
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [territoryHTH, setTerritoryHTH] = useState<typeHTHTerritory | null>(null);
    const [usingLettersForBlocks, setUsingLettersForBlocks] = useState(false);
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();

    useIdleTimeout(5*60*1000, () => (() => navigate('/')));

    useEffect(() => {
        if (!congregation || isNaN(congregation) || !territoryNumber) return;
        houseToHouseServices.GetTerritory(territoryNumber, congregation, block).then(response => {
            setIsLoading(false);
            if (!response) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos; tal vez no haya internet",
                    animation: 2
                }));
                return;
            }
            if (response.notSharedToday) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "Este edificio no fue compartido hoy por un capitán de salida",
                    animation: 2
                }));
                return;
            }
            if (!response.hthTerritory) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos",
                    animation: 2
                }));
                return;
            }
            setTerritoryHTH(response.hthTerritory);
            if (response.usingLettersForBlocks !== undefined) {
                setUsingLettersForBlocks(!!response.usingLettersForBlocks);
            }
        });
    // eslint-disable-next-line
    }, [block, congregation, refreshCounter, territoryNumber]);

    useEffect(() => {
        socket.on(socketIOEvents.hthChange, (_congregation: number, _territoryNumber: typeTerritoryNumber) => {
            if (_congregation !== congregation) return;
            if (!!_territoryNumber && _territoryNumber !== territoryNumber) return;
            console.log("Refrescando por uso de un usuario");
            setTimeout(() => {
                setRefreshCounter(x => x + 1);
            }, Math.floor(Math.random() * 2000));
        });
        return () => {
            socket.off(socketIOEvents.hthChange);
        };
    // eslint-disable-next-line
    }, [congregation, territoryNumber]);

    if (isLoading) return <Loading mt={'80px'} />;

    return (<>
        {territoryHTH &&
            <div>
                <H2 title={`${isMobile ? 'T' : 'TERRITORIO '}${territoryNumber} ${!!block ? `Manzana ${hthHelper.MaskTheBlock(block, usingLettersForBlocks)}` : ''}`} />

                <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />

                <HTHAllDoNotCalls
                    hthTerritory={territoryHTH}
                />

                <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />

                <HTHAllBuildings
                    block={block}
                    congregation={congregation}
                    territoryHTH={territoryHTH}
                    usingLettersForBlocks={usingLettersForBlocks}
                />
            </div>
        }
    </>)
}
