import { FC } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { Modal } from 'react-bootstrap';
import { typeCartAssignment, typeCartPeriod } from '@monorepo/models';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    deleteAssignment?: (assignment: typeCartAssignment) => void;
    selectedAssignment: typeCartAssignment;
}

export const CartCalendarModalDetails: FC<propsType> = ({ closeModal, deleteAssignment, selectedAssignment }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton className={isDarkMode ? 'bg-dark text-white' : ''}>
                <Modal.Title>Información de la Asignación</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark text-white' : ''}>
                <p>
                    <strong>Punto:</strong> {selectedAssignment.name}
                </p>
                <p>
                    <strong>Horario:</strong> {generalHelpers.GetTimeFromPeriodObject(selectedAssignment as typeCartPeriod)}
                </p>
                <p>
                    <strong>Hermanos asignados:</strong>
                </p>
                <ul>
                    {selectedAssignment.participants?.map(p =>
                        <li key={p.userId}>
                            {p.name} {p.lastName}
                        </li>
                    )}
                </ul>
            </Modal.Body>
            <Modal.Footer className={isDarkMode ? 'bg-dark' : ''}>
                {deleteAssignment &&
                    <button className={'btn btn-general-red'}
                        onClick={() => {
                            closeModal();
                            deleteAssignment(selectedAssignment);
                        }}
                    >
                        Eliminar
                    </button>
                }
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
