import { createSlice } from '@reduxjs/toolkit';
import { localStorageHelper } from 'src/services';

export const darkModeSlice = createSlice({
    name: 'darkMode',
    initialState: { isDarkMode: localStorageHelper.GetDarkMode() },
    reducers: {
        changeDarkModeReducer: (state) => {
            const newValue: boolean = !localStorageHelper.GetDarkMode();
            localStorageHelper.SetDarkMode(newValue);
            state = { isDarkMode: newValue }
            return state;
        }
    }
});

export const { changeDarkModeReducer } = darkModeSlice.actions;
