import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeBlock, typeHTHTerritory, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    blockToMove: { territory: typeHTHTerritory, block: typeBlock };
    close: () => void;
    newAmountOfTerritories: number;
    setNewHthTerritories: Dispatch<SetStateAction<typeHTHTerritory[]>>;
}

export const HTHRestructuringMove: FC<propsType> = ({
    blockToMove, close, newAmountOfTerritories, setNewHthTerritories
}) => {
    const [newTerritoryNumber, setNewTerritoryNumber] = useState<typeTerritoryNumber>(blockToMove.territory.territoryNumber as typeTerritoryNumber);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const accept = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se va a cambiar esta manzana del territorio ${blockToMove.territory.territoryNumber} al ${newTerritoryNumber}`,
            animation: 3,
            mode: 'confirm',
            execution: () => {
                setNewHthTerritories(x => x.map(y =>
                    y.territoryNumber === blockToMove.territory.territoryNumber ? ({
                        ...y,
                        map: {
                            ...y.map,
                            polygons: y.map.polygons.filter(p => p.block !== blockToMove.block)
                        }
                    })
                    :
                    y.territoryNumber === newTerritoryNumber ? ({
                        ...y,
                        map: {
                            ...y.map,
                            polygons: [
                                ...y.map.polygons,
                                ...blockToMove.territory.map.polygons
                                    .filter(p => p.block === blockToMove.block)
                                    .map(p => ({ ...p, block: (Math.max(...[...new Set(y.map.polygons.map(p => parseInt(p.block)) ?? [])]) + 1) as unknown as typeBlock }))
                            ]
                        }
                    })
                    :
                    y
                ));
                close();
            }
        }));
    }

    return (
        <Modal
            backdrop={'static'}
            backdropClassName={''}
            contentClassName={isDarkMode ? 'bg-secondary' : ''}
            keyboard={false}
            onHide={close}
            show={true}
            size={'xl'}
        >
            <Modal.Header className={'bg-general-blue'} closeButton />

            <Modal.Body className={'text-center'}>
                <Form.Group className={'fw-bolder m-2'}>
                    <Form.Label className={'mt-3'}>
                        Mover del Territorio {blockToMove.territory.territoryNumber} al {newTerritoryNumber}
                    </Form.Label>
                    <div className={'d-flex justify-content-center'}>
                        <Form.Select
                            className={'text-center'}
                            style={{ width: '170px' }}
                            value={newTerritoryNumber}
                            onChange={e => setNewTerritoryNumber(e.target.value.toString() as typeTerritoryNumber)}
                        >
                            {Array.from({ length: newAmountOfTerritories }, (_, i) => i + 1).map(n =>
                                <option key={n} value={n}>{n}</option>
                            )}
                        </Form.Select>
                    </div>
                </Form.Group>

                <div className={'mt-5'}>
                    <button className={'btn btn-general-blue btn-size12 w-100 mx-auto mb-2'}
                        style={{ maxWidth: '300px' }}
                        onClick={accept}
                        disabled={newTerritoryNumber === blockToMove.territory.territoryNumber}
                    >
                        Aceptar
                    </button>
                </div>
                <div>
                    <button className={'btn btn-general-red btn-size12 w-100 mx-auto mb-4'}
                        style={{ maxWidth: '300px' }}
                        onClick={close}
                    >
                        Cancelar
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
