import { boardServices, helpers } from 'src/services';
import { Container, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { Loading } from 'src/components/_commons';
import { typeBoardSection } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetShowingLoadingModal } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    section: typeBoardSection;
    sections: typeBoardSection[];
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardSectionsTitleModal: FC<propsType> = ({ closeModal, section, sections, setCounterReload }) => {
    const [title, setTitle] = useState(section.title);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const showingLoadingModal = useGetShowingLoadingModal();

    const isValid: boolean = useMemo(() =>
        !showingLoadingModal
        && title.length > 5
        && title !== section.title
        && !sections?.filter(s => s.title !== section.title).map(s => helpers.NormalizeString(s.title)).includes(helpers.NormalizeString(title))
    , [showingLoadingModal, section, sections, title]);

    const save = async () => {
        if (!isValid) return;
        dispatch(showLoadingModalReducer());
        const success = await boardServices.EditSectionTitle(section.id, section.title, title);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
            closeModal();
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo guardar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    return (
        <Modal show={true}
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>Cambiar título a la sección '{section.title}'</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <Container className={'pb-3'} style={{ maxWidth: '700px' }}>
                    <FloatingLabel className={'text-dark mt-3 mb-3'}
                        label={'Título'}
                    >
                        <Form.Control type={'text'}
                            className={'form-control'}
                            placeholder={''}
                            value={title}
                            onChange={e => e.target.value.length < 41 ? setTitle(e.target.value) : null}
                            autoFocus
                        />
                    </FloatingLabel>

                    {showingLoadingModal ?
                        <Loading mt={'40px'} mb={'30px'} white={isDarkMode} />
                        :
                        <>
                        <div className={'text-center'}>
                            <button className={'btn btn-general-blue d-block w-100 mt-5 mx-auto'}
                                style={{ fontWeight: 'bolder', height: '50px', maxWidth: '350px' }}
                                onClick={save}
                                disabled={!isValid}
                            >
                                GUARDAR
                            </button>
                            <button className={`btn btn-general-red d-block w-100 mt-2 mb-3 mx-auto`}
                                style={{ fontWeight: 'bolder', height: '50px', maxWidth: '350px' }}
                                onClick={closeModal}
                            >
                                CANCELAR
                            </button>
                        </div>
                        </>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
