import { ReturnBtn } from '.';
import { useEffect, useState } from 'react';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

export const FloatingWidgets = () => {
    const [scrollDown, setScrollDown] = useState(false);
    const config = useGetConfig();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    useEffect(() => {
        document.addEventListener('scroll', () => {
            if (window.scrollY > 100) setScrollDown(true);
            else setScrollDown(false);
        })
        return () => setScrollDown(false);
    }, []);

    return (<>
        <div className={scrollDown ? 'd-none' : ''}>
            <ReturnBtn />
        </div>
    
        <div>

            {window.location.pathname !== '/' && ((isMobile && !scrollDown) || !isMobile) &&
                <div
                    style={{
                        left: '50%',
                        marginTop: '55px',
                        pointerEvents: 'none',
                        position: 'fixed',
                        top: 0,
                        transform: 'translateX(-50%)',
                        zIndex: 3
                    }}
                >
                    <div style={{ pointerEvents: 'auto' }}>
                        <button className={`btn btn-general-red d-block mt-2 ${isMobile ? 'btn-sm' : ''}`}
                            onClick={() => window.location.reload()}
                        >
                            Refrescar
                        </button>
                    </div>
                </div>
            }

            {user.isAuth && ((isMobile && !scrollDown) || !isMobile) &&
                <div className={`${isDarkMode ? 'text-white' : ''}`}
                    style={{
                        marginRight: '18px',
                        marginTop: '5px',
                        position: 'fixed',
                        right: 0,
                        zIndex: 1
                    }}
                >
                    <p className={'text-end mb-1'} style={{ fontSize: isMobile ? '.8rem' : '.9rem' }}>
                        {isMobile ? user.email.split('@')[0] : user.email}
                    </p>
                    {/* {user.rolesAsText !== 'Usuario' &&
                        <p className={'text-end mb-1'} style={{ fontSize: isMobile ? '.7rem' : '.9rem' }}>
                            {user.rolesAsText}
                        </p>
                    } */}
                    <p className={'text-end'} style={{ fontSize: isMobile ? '.7rem' : '.8rem' }}>
                        {config.name}
                    </p>
                </div>
            }
        </div>
    </>)
}
