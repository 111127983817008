import { H2, Loading } from 'src/components/_commons';
import { LogsCard } from './subcomponents/LogsCard';
import { logServices } from 'src/services';
import { typeLogsDoc } from '@monorepo/models';
import { useGetCurrentUser, useGetIsMobile } from 'src/custom-hooks';
import { useState, useEffect, useMemo } from 'react';

export const AdminsLogs = () => {
    const user = useGetCurrentUser();
    //
    const [activeLog, setActiveLog] = useState<typeLogsDoc | null>(null);
    const [congregationForLogs, setCongregationForLogs] = useState(user.congregation);
    const [isLoading, setIsLoading] = useState(true);
    const [logs, setLogs] = useState<typeLogsDoc[] | null>(null);
    const isMobile = useGetIsMobile();

    useEffect(() => {
        setIsLoading(true);
        logServices.GetAll(false, null, null).then(data => {
            setIsLoading(false);
            if (!data?.logs) return;
            setLogs(data.logs);
        });
    }, []);

    const logsToShow: typeLogsDoc[] = useMemo(() =>
        logs?.filter(l => l.congregation === congregationForLogs) ?? []
    , [congregationForLogs, logs]);

    return (<>
        <H2 title={'LOGS DE LA APLICACIÓN'} />

        {isLoading ?
            <Loading mt={'50px'} />
            :
            <>
                {!!logsToShow.length && logsToShow.some(x => !!x.logs?.length) ?
                    <>
                    <div className={'d-flex justify-content-center'}>
                        <select className={'form-select my-3 maxw-400'}
                            value={congregationForLogs}
                            onChange={e => {
                                setCongregationForLogs(parseInt(e.target.value) || 0);
                                setActiveLog(null);
                            }}
                        >
                            {[...new Set(logs?.map(l => l.congregation))].sort((c1, c2) => c1 - c2).map(c =>
                                <option key={c} value={c}>
                                    Congregación {c}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className={'btn-group d-flex justify-content-center'} role={'group'}>
                        {isMobile ?
                            <select className={'form-select mt-3'}
                                value={activeLog?.title ?? '0'}
                                onChange={e => setActiveLog(logsToShow.find(l => l.title === e.target.value) ?? null)}
                            >
                                <option value={'0'}>Seleccionar</option>
                                {logsToShow.sort((l1, l2) => l1.title.localeCompare(l2.title)).map(log =>
                                    <option key={log.title} value={log.title}>
                                        {log.title}
                                    </option>
                                )}
                            </select>
                            :
                            <>
                            {logsToShow.filter(l => !!l.logs?.length).sort((l1, l2) => l1.title.localeCompare(l2.title)).map((log, i) =>
                                <button key={log.title}
                                    type={'button'}
                                    className={`small btn ${activeLog?.title === log.title ? 'btn-general-blue' : 'btn-secondary'} ${i === 0 ? 'rounded-start' : ''} ${i === logsToShow.length - 1 ? 'rounded-end' : ''}`}
                                    onClick={() => setActiveLog(log)}
                                >
                                    {log.title}
                                </button>
                            )}
                            </>
                        }
                    </div>
                    </>
                    :
                    <h4 className='text-center mt-5'>No hay datos</h4>
                }

                {!!activeLog &&
                    <LogsCard key={activeLog.title}
                        activeLog={activeLog}
                        congregationForLogs={congregationForLogs}
                        setActiveLog={setActiveLog}
                        setLogs={setLogs}
                    />
                }
            </>
        }
    </>)
}
