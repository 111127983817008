import { typeCartAssignment, typeCartParticipant } from '@monorepo/models';

class CartHelper {
    GetPriorityPoints(participant: typeCartParticipant, cartAssignments: typeCartAssignment[], month: number, year: number): number {
        let priorityPoints = 0;
        if (participant.priority === 1) priorityPoints += 60;
        if (participant.priority === 2) priorityPoints += 30;
        if (participant.hasParticipatedBefore) priorityPoints += 50;
        participant.availabilities.forEach(() => {
            priorityPoints -= 20;
        });
        cartAssignments.filter(a => a.participants.some(p => p.userId === participant.userId) && a.year === year && a.month === month).forEach(() => {
            priorityPoints -= 50;
        });
        return priorityPoints;
    }
    GetTimeFromPeriodData(startHour: number, startMinutes: number, endHour: number, endMinutes: number): string {
        return `${startHour.toString().padStart(2, '0')}:${startMinutes.toString().padStart(2, '0')}-${endHour.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
    }
}

export const cartHelper = new CartHelper();
