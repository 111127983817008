import { typeBlock, typeFace } from '@monorepo/models';
import buildInfo from './build-timestamp.json';

export const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const domain = window.location.origin;

export const appName = domain.includes('almagro') ? 'Congregación Almagro' : 'Misericordia Web';

export const SERVER = (isLocalhost ?
    'http://localhost:4005'
    :
    'https://misericordia-web-service-3v5m7nxntq-rj.a.run.app'
);

export const pointer = {
    board: `${SERVER}/api/board`,
    campaign: `${SERVER}/api/campaign`,
    cart: `${SERVER}/api/cart`,
    config: `${SERVER}/api/config`,
    email: `${SERVER}/api/email`,
    geocoding: `${SERVER}/api/geocoding`,
    houseToHouse: `${SERVER}/api/house-to-house`,
    log: `${SERVER}/api/log`,
    meeting: `${SERVER}/api/meeting`,
    notification: `${SERVER}/api/notification`,
    publicMinistry: `${SERVER}/api/public-ministry`,
    telephonic: `${SERVER}/api/telephonic`,
    user: `${SERVER}/api/user`,
    weather: `${SERVER}/api/weather`
};

export const recaptchaPublicKey = '6LfDIdIZAAAAAElWChHQZq-bZzO9Pu42dt9KANY9';

export const firebaseConfig = {
    apiKey: "AIzaSyBJsKT5_hO1U1gC_-9grUxEnCLKo1LmbM4",
    authDomain: "misericordia-web-frontend.firebaseapp.com",
    projectId: "misericordia-web-frontend",
    storageBucket: "misericordia-web-frontend.firebasestorage.app",
    messagingSenderId: "907494751799",
    appId: "1:907494751799:web:800bd03ddfb6ae661a09b2"
};

export const lastDeployTimestamp = buildInfo.buildTimestamp ?? 0;

export const hthConfigOptions = {
    blocks: ['1', '2', '3', '4', '5', '6'] as typeBlock[],
    faces: ['A', 'B', 'C', 'D', 'E', 'F'] as typeFace[],
    buildingDoorNumbers: Array.from({ length: 30 }, (_, i) => i + 1),  // 1-30
    buildingLevels: Array.from({ length: 51 }, (_, i) => i)  // PB-50
};

export const daysOfWeekDesktop = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];  // to be removed
export const daysOfWeekMobile = ['L', 'M', 'Mi', 'J', 'V', 'S'];  // D
export const daysOfWeekMobileD = ['D', 'L', 'M', 'Mi', 'J', 'V', 'S'];  // D
export const daysOfTheWeekP = ['Domingos', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábados'];
export const daysOfTheWeekS = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

export const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
];

export const googleMapConfig = {
    googleMapsApiKey: isLocalhost ? 'AIzaSyCWWH0mdRMiQ2fIsWD_xcV7AqXCyQVg594' : 'AIzaSyBPcwNALtiEb3_s-mHvS8R5WxBb_jyRYhE',
    id: 'ad09b84d2db0b86d',
    language: 'es',
    libraries: ['maps', 'places'] as any,
    region: 'AR'
};

export const placesBounds = {
    east: -58.353243,
    north: -34.562694,
    south: -34.705957,
    west: -58.530468
};

export const initCoordinates = { lat: -34.6258549, lng: -58.4343211 };

export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const breakingPoint = 992;    // < mobile
export const generalBlue = '#4a6da7';
export const generalBlueDark = '#25457d';
export const generalRed = '#b02a37';
