import { FC } from 'react';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useNavigate } from 'react-router';
import { WeatherAndForecast } from '.';

type propsType = {
    classes: string;
    congregation?: number;
    showForecast: boolean;
    territories: number[];
    url: string;
}

export const TerritoryNumberBlock: FC<propsType> = ({ classes, congregation, showForecast, territories, url }) => {
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();

    return (
        <div className={`card card-body mt-4 ${isDarkMode ? 'bg-dark' : ''}`}>
            <div className={'container pt-0 mb-0'}>
                <div className={'row'}
                    style={{
                        justifyContent: 'space-evenly',
                        padding: isMobile ? '20px 10px' : '40px'
                    }}
                >
                    {!!territories?.length && territories.map(territory =>
                        <button key={territory}
                            className={`btn ${classes}`}
                            onClick={() => navigate(congregation ?
                                `/buscador?grupo=${congregation}&lugar=${territory}`
                                :
                                `${url}/${territory.toString()}`)
                            }
                            style={{
                                borderRadius: '15px',
                                height: isMobile ? '60px' : '100px',
                                margin: '0 1% 40px 1%',
                                width: isMobile ? '90px' : '120px'
                            }}
                        >
                            <h2 className={'h-100 mt-1 mx-auto'}
                                style={{
                                    fontFamily: '"Arial Black", Gadget, sans-serif',
                                    fontSize: isMobile ? '1.8rem' : '2.3rem',
                                    padding: isMobile ? '5%' : '20%'
                                }}
                            >
                                {territory}
                            </h2>
                        </button>
                    )}
                    {showForecast &&
                        <WeatherAndForecast showWeather={false} showForecast0={true} />
                    }
                </div>
            </div>
        </div>
    )
}
