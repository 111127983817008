import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { localStorageHelper } from 'src/services';
import { typeConfigsDoc, unauthenticatedConfig } from '@monorepo/models';

const configLocalStorage = localStorageHelper.GetConfig ? localStorageHelper.GetConfig() : alert("No se cargó Config LS Service");

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState: configLocalStorage ?? unauthenticatedConfig,
    reducers: {
        setConfigurationReducer: (state, action: PayloadAction<typeConfigsDoc>) => {
            if (Number.isInteger(action.payload.numberOfTerritories)) {
                state = action.payload;
                localStorageHelper.SetConfig(action.payload);
            }
            return state;
        }
    }
});

export const { setConfigurationReducer } = configurationSlice.actions;
