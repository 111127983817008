import { FC, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer } from 'src/store';
import { houseToHouseServices, hthHelper } from 'src/services';
import { Loading } from '../Loading';
import { typeAddress, typeHTHBuilding, typeHTHTerritory, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig } from 'src/custom-hooks';

type propsType = {
    closeEdit: () => void;
    currentBuilding: typeHTHBuilding;
    currentFace: typePolygon;
    territoryNumber: typeTerritoryNumber;
}

export const HTHBuildingModalToEdit: FC<propsType> = ({ closeEdit, currentBuilding, currentFace, territoryNumber }) => {
    const [hthTerritory, setHthTerritory] = useState<typeHTHTerritory | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [newNumberOfNumbers, setNewNumberOfNumbers] = useState<1 | 2 | 3>(currentBuilding.streetNumber3 ? 3 : currentBuilding.streetNumber2 ? 2 : 1);
    const [newPolygonId, setNewPolygonId] = useState(currentFace.id);
    const [newStreetNumber, setNewStreetNumber] = useState(currentBuilding.streetNumber);
    const [newStreetNumber2, setNewStreetNumber2] = useState(currentBuilding.streetNumber2 ?? null);
    const [newStreetNumber3, setNewStreetNumber3] = useState(currentBuilding.streetNumber3 ?? null);
    const [newTerritoryNumber, setNewTerritoryNumber] = useState(territoryNumber);
    const config = useGetConfig();
    const dispatch = useDispatch();

    const isValid: boolean = useMemo(() =>
        !!newStreetNumber
        && !!hthTerritory
        && (newNumberOfNumbers === 3 ? !!newStreetNumber2 && !!newStreetNumber3 :
            newNumberOfNumbers === 2 ? !!newStreetNumber2 : true)
        && (!newStreetNumber3 || (!!newStreetNumber2 && newStreetNumber3 > newStreetNumber2 && newStreetNumber2 > newStreetNumber))
        && (!newStreetNumber2 || (newStreetNumber2 > newStreetNumber))
    , [hthTerritory, newNumberOfNumbers, newStreetNumber, newStreetNumber2, newStreetNumber3]);

    const save = async () => {
        if (!isValid || !hthTerritory) return;
        setIsLoading(true);
        const currentBuildingAddress: typeAddress = {
            block: currentFace.block,
            face: currentFace.face,
            street: currentFace.street,
            streetNumber: currentBuilding.streetNumber,
            streetNumber2: currentBuilding.streetNumber2,
            streetNumber3: currentBuilding.streetNumber3,
            territoryNumber
        };
        const newBuildingAddress: typeAddress = {
            block: hthTerritory.map.polygons.find(p => p.id === newPolygonId)?.block ?? currentFace.block,
            face: hthTerritory.map.polygons.find(p => p.id === newPolygonId)?.face ?? currentFace.face,
            street: hthTerritory.map.polygons.find(p => p.id === newPolygonId)?.street ?? currentFace.street,  // for logging
            streetNumber: newStreetNumber,
            streetNumber2: newStreetNumber2 ?? undefined,
            streetNumber3: newStreetNumber3 ?? undefined,
            territoryNumber: newTerritoryNumber
        };
        const success = await houseToHouseServices.EditBuildingAddress(currentBuildingAddress, newBuildingAddress);
        setIsLoading(false);
        dispatch(hideLoadingModalReducer());
        if (success) {
            closeEdit();
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo editar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    useEffect(() => {
        setHthTerritory(null);
        houseToHouseServices.GetTerritory(newTerritoryNumber).then(response => {
            if (!response?.hthTerritory) return;
            setHthTerritory(response.hthTerritory);
        });
    }, [newTerritoryNumber]);
    
    return (
        <div className={'text-center'}>
            <Form.Group className={'fw-bolder m-2'}>
                <Form.Label> Territorio </Form.Label>
                <div className={'d-flex justify-content-center'}>
                    <Form.Select
                        className={'text-center'}
                        style={{ width: '170px' }}
                        value={newTerritoryNumber}
                        onChange={e => setNewTerritoryNumber(e.target.value.toString() as typeTerritoryNumber)}
                    >
                        {Array.from({ length: config.numberOfTerritories }, (_, i) => i + 1).map(n =>
                            <option key={n} value={n}> {n} </option>
                        )}
                    </Form.Select>
                </div>

                {hthTerritory ?
                    <>
                    <Form.Label className={'mt-3'}> Cara </Form.Label>
                    <div className={'d-flex justify-content-center'}>
                        <Form.Select
                            style={{ width: '370px' }}
                            value={newPolygonId}
                            onChange={e => setNewPolygonId(parseInt(e.target.value))}
                        >
                            {hthTerritory.map.polygons.map(p =>
                                <option key={p.id} value={p.id}> Manzana {hthHelper.MaskTheBlock(p.block, config.usingLettersForBlocks)}, Cara {hthHelper.MaskTheFace(p.face, config.usingLettersForBlocks)} - Calle {p.street} </option>
                            )}
                        </Form.Select>
                    </div>
                    </>
                    :
                    <Loading white={false} mt={'20px'} />
                }

            </Form.Group>

            <Form.Group className={'fw-bolder'}>
                <Form.Label className={'mt-3'}> Cantidad de Números </Form.Label>
                <div className={'d-flex justify-content-center'}>
                    <Form.Select
                        className={'text-center'}
                        style={{ width: '170px' }}
                        value={newNumberOfNumbers}
                        onChange={e => {
                            const value = parseInt(e.target.value) as 1 | 2 | 3;
                            setNewNumberOfNumbers(value);
                            if (value < 3) setNewStreetNumber3(null);
                            if (value === 1) setNewStreetNumber2(null);
                        }}
                    >
                        <option value={1}> 1 </option>
                        <option value={2}> 2 </option>
                        <option value={3}> 3 </option>
                    </Form.Select>
                </div>
            </Form.Group>

            <div className={'d-flex justify-content-center mb-3'}>
                <Form.Group
                    className={'fw-bolder m-2'}
                    style={{ width: '120px' }}
                >
                    <Form.Label> Número 1 </Form.Label>
                    <Form.Control
                        type={'number'}
                        max={'100000'}
                        min={'1'}
                        value={newStreetNumber ? newStreetNumber : ""}
                        onChange={e => setNewStreetNumber(parseInt(e.target.value))}
                        autoFocus
                    />
                </Form.Group>
                {newNumberOfNumbers > 1 && <>
                    <Form.Group
                        className={'fw-bolder m-2'}
                        style={{ width: '120px' }}
                    >
                        <Form.Label> Número 2 </Form.Label>
                        <Form.Control
                            type={'number'}
                            max={'100000'}
                            min={'1'}
                            value={newStreetNumber2 ? newStreetNumber2 : ""}
                            onChange={e => setNewStreetNumber2(parseInt(e.target.value))}
                        />
                    </Form.Group>
                    {newNumberOfNumbers > 2 &&
                        <Form.Group
                            className={'fw-bolder m-2'}
                            style={{ width: '120px' }}
                        >
                            <Form.Label> Número 3 </Form.Label>
                            <Form.Control
                                type={'number'}
                                max={'100000'}
                                min={'1'}
                                value={newStreetNumber3 ? newStreetNumber3 : ""}
                                onChange={e => setNewStreetNumber3(parseInt(e.target.value))}
                            />
                        </Form.Group>
                    }
                </>}
            </div>

            {isLoading ?
                <Loading white={false} />
                :
                <>
                <div>
                    <button className={'btn btn-success btn-size12 w-100 mx-auto mt-1 mb-2'}
                        style={{ maxWidth: '300px' }}
                        onClick={save}
                        disabled={!isValid}
                    >
                        Guardar Cambios
                    </button>
                </div>
                <div>
                    <button className={'btn btn-general-red btn-size12 w-100 mx-auto mb-4'}
                        style={{ maxWidth: '300px' }}
                        onClick={closeEdit}
                    >
                        Cancelar Edición
                    </button>
                </div>
                </>
            }
        </div>
    )
}
