import 'react-confirm-alert/src/react-confirm-alert.css';
import { closeAlertModalReducer } from 'src/store';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useGetAlertModal } from 'src/custom-hooks';

export const adjustModalStyles = (): NodeJS.Timeout => setTimeout((): void => {
    const bodyElements: HTMLCollectionOf<Element> = document.getElementsByClassName('react-confirm-alert-body');
    bodyElements[0]?.classList?.add('text-center');
    bodyElements[0]?.firstElementChild?.classList?.add('h3');
    bodyElements[0]?.firstElementChild?.classList?.add('mb-3');
    const buttonGroupElements: HTMLCollectionOf<Element> = document.getElementsByClassName('react-confirm-alert-button-group');
    buttonGroupElements[0]?.classList?.add('d-block');
    buttonGroupElements[0]?.classList?.add('m-auto');
    buttonGroupElements[0]?.classList?.add('mt-4');
    buttonGroupElements[0]?.firstElementChild?.classList?.add('bg-danger');
}, 200);

export const insertAnimationForAlertModal = (animationNumber: number): NodeJS.Timeout => setTimeout((): void => {
    if (!animationNumber) return;
    const tag = document.createElement('img');
    if (animationNumber === 1)
        // tag.src = 'https://upload.wikimedia.org/wikipedia/commons/7/73/Flat_tick_icon.svg';
        tag.src = '../../svg_ok.svg';
    else if (animationNumber === 2)
        // tag.src = 'https://upload.wikimedia.org/wikipedia/commons/8/8f/Flat_cross_icon.svg';
        tag.src = '../../svg_fail.svg';
    else if (animationNumber === 3)
        tag.src = '../../svg_warning.svg';
    else if (animationNumber === 4)
        // tag.src = 'https://pic.onlinewebfonts.com/svg/img_570882.png';
        tag.src = '../../card.png';
    else
        return;
    tag.alt = "Failed";
    tag.classList.add('w-50');
    tag.classList.add('pb-3');
    tag.classList.add('animate__animated');
    tag.classList.add('animate__heartBeat');
    const bodyElements: HTMLCollectionOf<Element> = document.getElementsByClassName('react-confirm-alert-body');
    bodyElements[0]?.prepend(tag);
}, 200);

export const AlertModal = () => {
    const alertModal = useGetAlertModal();
    const dispatch = useDispatch();

    const { mode, title, message, execution, animation, executionOnCancelling } = alertModal;

    useEffect(() => {
        const closeModalHandler = () => dispatch(closeAlertModalReducer())
        confirmAlert({
            title,
            message,
            buttons:
                execution ?
                    mode === 'alert' ?
                    [
                        {
                            label: 'ACEPTAR',
                            onClick: () => execution()
                        }
                    ]
                    :
                    [
                        {
                            label: 'ACEPTAR',
                            onClick: () => execution()
                        },
                        {
                            label: 'CANCELAR',
                            onClick: () => {
                                if (executionOnCancelling) executionOnCancelling()
                                closeModalHandler()
                            }
                        }
                    ]
                :
                    [
                        {
                            label: 'ACEPTAR',
                            onClick: () => {
                                if (executionOnCancelling) executionOnCancelling()
                                closeModalHandler()
                            }
                        }
                    ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            willUnmount: () => closeModalHandler()
            // overlayClassName: "text-center",
            // onClickOutside: () => {},
            // onKeypressEscape: () => {},
        })
        adjustModalStyles()
        if (animation) insertAnimationForAlertModal(animation)
    }, [animation, dispatch, execution, executionOnCancelling, message, mode, title])

    return (<></>)
}
