import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeCartAssignment, typeCartsDoc, typeCartParticipant, typeCartPeriod, typeCartPoint } from '@monorepo/models';

class CartServices {
    private Base = pointer.cart;
    async AddAssignment(assignment: typeCartAssignment): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/assignment`, 'POST', { assignment });
        return !!data?.success;
    }
    async AddEnabledPeriod(point: typeCartPoint, period: typeCartPeriod): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/period`, 'POST', { period, point });
        return !!data?.success;
    }
    async AddParticipant(participant: typeCartParticipant): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/participant`, 'POST', { participant });
        return !!data?.success;
    }
    async AddPoint(point: typeCartPoint): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/point`, 'POST', { point });
        return !!data?.success;
    }
    async DeleteAssignment(assignment: typeCartAssignment): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/assignment`, 'DELETE', { assignment });
        return !!data?.success;
    }
    async DeleteEnabledPeriod(point: typeCartPoint, period: typeCartPeriod): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/period`, 'DELETE', { period, point });
        return !!data?.success;
    }
    async DeleteParticipant(participant: typeCartParticipant): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/participant`, 'DELETE', { participant });
        return !!data?.success;
    }
    async DeletePoint(point: typeCartPoint): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/point`, 'DELETE', { point });
        return !!data?.success;
    }
    async GetDoc(): Promise<typeCartsDoc | null> {
        const data = await api.SendRequest(this.Base, 'GET');
        return data?.cartDoc ?? null;
    }
    async UpdateParticipant(participant: typeCartParticipant): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/participant`, 'PUT', { participant });
        return !!data?.success;
    }
    async UpdatePoint(point: typeCartPoint): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/point`, 'PUT', { point });
        return !!data?.success;
    }
}

export const cartServices = new CartServices();
