import { api } from './_apiInterface';
import { localStorageHelper } from '../helpers/localStorageHelper';
import { pointer } from 'src/app-config';
import { typeWeatherAndForecast } from '@monorepo/models';

class WeatherAndForecastServices {
    private Base = pointer.weather;
    async Get(): Promise<typeWeatherAndForecast | null> {
        try {
            const weatherInLS = localStorageHelper.GetWeatherAndForecast();
            if (weatherInLS) {
                const weatherInLSObj = JSON.parse(weatherInLS) as typeWeatherAndForecast;
                if (weatherInLSObj.date && weatherInLSObj.date + 120000 > Date.now()) {
                    return {
                        weather: weatherInLSObj.weather,
                        forecast: weatherInLSObj.forecast
                    };
                }
            }
        } catch (error) {
            console.log(error);
        }
        const data = await api.SendRequest(this.Base, 'GET', null, false);
        if (!data || !data.success || (!data.weather && !data.forecast)) {
            return null;
        }
        const weatherAndForecast: typeWeatherAndForecast = {
            date: Date.now(),
            forecast: data.forecast,
            weather: data.weather
        };
        const weatherAndForecastString = JSON.stringify(weatherAndForecast);
        localStorageHelper.SetWeatherAndForecast(weatherAndForecastString);
        return {
            weather: data.weather,
            forecast: data.forecast
        };
    }
}

export const weatherAndForecastServices = new WeatherAndForecastServices();
