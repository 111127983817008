import { Container, Modal } from 'react-bootstrap';
import { FC, useState } from 'react';
import { helpers, telephonicServices } from 'src/services';
import { Hr, Loading } from 'src/components/_commons';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeTelephonicTerritory } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    t: typeTelephonicTerritory;
}

export const TelephonicResetModal: FC<propsType> = ({ closeModal, t }) => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const resetHandler = async (selectedOption: 1 | 2 | 3 | 4): Promise<void> => {
        if (!selectedOption) return;
        let message: string = '';
        if (selectedOption === 1) {
            message = 'Esta opción resetea los predicados más viejos (más de 6 meses) pero deja los no abonados como están';
        } else if (selectedOption === 2) {
            message = 'Esta opción resetea todos los predicados pero deja los no abonados como están';
        } else if (selectedOption === 3) {
            message = 'Esta opción resetea los predicados y no abonados más viejos (de más de 6 meses)';
        } else {
            message = 'Esta opción resetea todos los predicados y los no abonados';
        }
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: `¿Resetear Territorio ${t.territoryNumber}?`,
            message,
            execution: async (): Promise<void> => {
                if (!selectedOption) return;
                setIsLoading(true);
                const modifiedCount = await telephonicServices.ResetTerritory(t.territoryNumber, selectedOption);
                setIsLoading(false);
                if (modifiedCount === null) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Error',
                        message: 'Algo falló y no se pudo resetear el territorio.',
                        animation: 2
                    }));
                } else if (modifiedCount > 0) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Terminado',
                        message: `Se resetearon las viviendas usando la opción ${selectedOption}`,
                        // execution: () => window.location.reload(),
                        animation: 1
                    }));
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'No se hicieron cambios',
                        message: `Parece que con la opción ${selectedOption} no queda ninguna vivienda para resetear.`
                    }));
                }
            }
        }));
    }

    return (
        <Modal show={true}
            fullscreen={'md-down'}
            onHide={closeModal}
            size={'lg'}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>Reseteos del territorio {t.territoryNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <Container className={'mb-2'} style={{ maxWidth: '500px' }}>
                    {!!t.stateOfTerritory.resetDates.length &&
                        <div className={`${isDarkMode ? 'text-white' : ''} my-4`}>
                            {t.stateOfTerritory.resetDates.map(reset =>
                                <h4 key={reset.date}>
                                    &nbsp; -El {helpers.TimeConverter(reset.date)} con la opción {reset.option}
                                </h4>
                            )}
                        </div>
                    }

                    <Hr />

                    {isLoading ?
                        <Loading mt={'50px'} />
                        :
                        <div className={'container mt-4'}>
                            <button className={'btn btn-general-red w-100 mb-2 py-3'}
                                onClick={() => resetHandler(1)}
                            >
                                Resetear los de más de 6 meses menos los no abonados
                            </button>
                            <button className={'btn btn-general-red w-100 mb-2 py-3'}
                                onClick={() => resetHandler(2)}
                            >
                                Resetear todos menos los no abonados
                            </button>
                            <button className={'btn btn-general-red w-100 mb-2 py-3'}
                                onClick={() => resetHandler(3)}
                            >
                                Resetear los de más de 6 meses incluso los no abonados
                            </button>
                            <button className={'btn btn-general-red w-100 mb-2 py-3'}
                                onClick={() => resetHandler(4)}
                            >
                                Resetear absolutamente todos
                            </button>
                        </div>
                    }
                </Container>
            </Modal.Body>
        </Modal>
    )
}
