import { H2 } from 'src/components/_commons';
import { hideLoadingModalReducer, showLoadingModalReducer } from 'src/store';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useNavigate } from 'react-router';
import { useState, useEffect, useMemo } from 'react';
import * as Subpages from '.';

type typeAdminsSection = '' | 'attendance' | 'av-ushers' | 'board' | 'carts' | 'config' | 'groups' | 'hth' | 'logs' | 'midweek-meetings' | 'notifications' | 'public-meetings' | 'public-ministry' | 'reports' | 'telephonic' | 'users';

export const AdminsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const navigate = useNavigate();
    const user = useGetCurrentUser();
    //
    const card = `card ${isDarkMode ? 'bg-dark text-white border-dark' : ''} mb-3`;
    const cardHeader = `card-header ${isMobile ? '' : 'd-flex justify-content-start'} ${isDarkMode ? 'text-white border-light' : ''}`;
    const stylesHeader = { display: isMobile ? 'block' : '', marginInline: isMobile ? 'auto' : '20px', paddingTop: '10px', width: '170px' };
    const classesH3 = `${isMobile ? 'text-center py-1' : ''} m-0`;
    const classesBtn = `btn btn-general-blue btn-size12 d-block ${isMobile ? 'mx-auto' : ''} mt-2 mb-0`;
    const stylesBtn = { marginRight: isMobile ? undefined : '15px', width: isMobile ? '280px' : '200px' };

    const currentSection: typeAdminsSection = useMemo(() => {
        const relativePath = window.location.pathname as string;
        if (relativePath.startsWith('/admins/asistencia')) return 'attendance';
        if (relativePath.startsWith('/admins/av-y-acomodadores')) return 'av-ushers';
        if (relativePath.startsWith('/admins/carritos')) return 'carts';
        if (relativePath.startsWith('/admins/casa-en-casa')) return 'hth';
        if (relativePath.startsWith('/admins/config')) return 'config';
        if (relativePath.startsWith('/admins/grupos')) return 'groups';
        if (relativePath.startsWith('/admins/informes')) return 'reports';
        if (relativePath.startsWith('/admins/logs')) return 'logs';
        if (relativePath.startsWith('/admins/notificaciones')) return 'notifications';
        if (relativePath.startsWith('/admins/reuniones-entresemana')) return 'midweek-meetings';
        if (relativePath.startsWith('/admins/reuniones-findesemana')) return 'public-meetings';
        if (relativePath.startsWith('/admins/salidas')) return 'public-ministry';
        if (relativePath.startsWith('/admins/tablero')) return 'board';
        if (relativePath.startsWith('/admins/telefonica')) return 'telephonic';
        if (relativePath.startsWith('/admins/usuarios')) return 'users';
        return '';
    // eslint-disable-next-line
    }, [window.location.pathname]);

    useEffect(() => {
        if (isLoading) dispatch(showLoadingModalReducer());
        else dispatch(hideLoadingModalReducer());
    }, [dispatch, isLoading]);

    return (
        <div>
            <style>
                {`
                    @media (min-width: 992px) and (max-width: 1239px) {
                        .title-admins {
                            display: none !important;
                        }
                    }
                `}
            </style>
            <div className={currentSection === '' ? '' : 'd-none'}>
                <H2 title={'ADMINISTRADORES'} />
                <div className={card}>
                    <div className={cardHeader}>
                        <div className={'title-admins'} style={stylesHeader}>
                            <h3 className={classesH3}>
                                Predicación
                            </h3>
                        </div>
                        {user.isHthAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/casa-en-casa')}
                            >
                                Casa en Casa
                            </button>
                        }
                        {user.isTelephonicAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/telefonica')}
                            >
                                Telefónica
                            </button>
                        }
                        {user.isCartsAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/carritos')}
                            >
                                Carritos
                            </button>
                        }
                        {user.isBoardAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/salidas')}
                            >
                                Servicio del Campo
                            </button>
                        }
                    </div>
                </div>
                {user.isBoardAdmin && <>
                    <div className={card}>
                        <div className={cardHeader}>
                            <div className={'title-admins'} style={stylesHeader}>
                                <h3 className={classesH3}>
                                    Reuniones
                                </h3>
                            </div>
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/reuniones-entresemana')}
                            >
                                Vida y Ministerio
                            </button>
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/reuniones-findesemana')}
                            >
                                Reunión Pública
                            </button>
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/av-y-acomodadores')}
                            >
                                AV y Acomodadores
                            </button>
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/asistencia')}
                            >
                                Asistencia
                            </button>
                        </div>
                    </div>
                    <div className={card}>
                        <div className={cardHeader}>
                            <div className={'title-admins'} style={stylesHeader}>
                                <h3 className={classesH3}>
                                    Personas
                                </h3>
                            </div>
                            {user.isAdmin && <>
                                <button className={classesBtn}
                                    style={stylesBtn}
                                    onClick={() => navigate('/admins/usuarios')}
                                >
                                    Usuarios
                                </button>
                                <button className={classesBtn}
                                    style={stylesBtn}
                                    onClick={() => navigate('/admins/grupos')}
                                >
                                    Grupos
                                </button>
                                <button className={classesBtn}
                                    style={stylesBtn}
                                    onClick={() => navigate('/admins/notificaciones')}
                                >
                                    Notificaciones
                                </button>
                            </>}
                            {user.isBoardAdmin &&
                                <button className={classesBtn}
                                    style={stylesBtn}
                                    onClick={() => navigate('/admins/informes')}
                                >
                                    Informes
                                </button>
                            }
                        </div>
                    </div>
                </>}
                <div className={card}>
                    <div className={cardHeader}>
                        <div className={'title-admins'} style={stylesHeader}>
                            <h3 className={classesH3}>
                                Sistema
                            </h3>
                        </div>
                        {user.isBoardAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/tablero')}
                            >
                                Tablero
                            </button>
                        }
                        <button className={classesBtn}
                            style={stylesBtn}
                            onClick={() => navigate('/admins/logs')}
                        >
                            Logs
                        </button>
                        {user.isAdmin &&
                            <button className={classesBtn}
                                style={stylesBtn}
                                onClick={() => navigate('/admins/config')}
                            >
                                Configuración
                            </button>
                        }
                    </div>
                </div>
            </div>
            {currentSection === 'attendance' && <Subpages.AdminsMeetingAttendance />}
            {currentSection === 'av-ushers' && <Subpages.AdminsAVUshers />}
            {currentSection === 'board' && <Subpages.AdminsBoard />}
            {currentSection === 'carts' && <Subpages.AdminsCarts />}
            {currentSection === 'config' && <Subpages.AdminsConfig />}
            {currentSection === 'groups' && <Subpages.AdminsGroups />}
            {currentSection === 'hth' && <Subpages.AdminsHTH />}
            {currentSection === 'logs' && <Subpages.AdminsLogs />}
            {currentSection === 'midweek-meetings' && <Subpages.AdminsMidweekMeetings />}
            {currentSection === 'notifications' && <Subpages.AdminsNotificacions />}
            {currentSection === 'public-meetings' && <Subpages.AdminsPublicMeetings />}
            {currentSection === 'public-ministry' && <Subpages.AdminsPublicMinistry />}
            {currentSection === 'reports' && <Subpages.AdminsMonthlyReports />}
            {currentSection === 'telephonic' && <Subpages.AdminsTelephonic />}
            {currentSection === 'users' && <Subpages.AdminsUsers setIsLoading={setIsLoading} />}
        </div>
    )
}
