import { AlertModal, DarkModeButton, FloatingWidgets, Footer, LoadingModal, NavBar } from 'src/components/_commons';
import { appName, breakingPoint } from 'src/app-config';
import { changeMobileModeReducer, logoutReducer, refreshUserReducer, setConfigurationReducer } from 'src/store';
import { helpers, userServices } from 'src/services';
import { MainRouter } from 'src/routers/MainRouter';
import { Suspense, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetAlertModal, useGetIsDarkMode, useGetIsMobile, useGetShowingLoadingModal } from 'src/custom-hooks';
import { useLocation } from 'react-router-dom';

export const App = () => {
    const [refresh, setRefresh] = useState(false);
    const alertModal = useGetAlertModal();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const location = useLocation();
    const showingLoadingModal = useGetShowingLoadingModal();

    useEffect(() => {
        window.addEventListener('resize', (event: any) => {
            const width: number = event.target.screen.width;
            if ((isMobile && width >= breakingPoint) || (!isMobile && width < breakingPoint)) {
                dispatch(changeMobileModeReducer({ isMobile: width < breakingPoint }));
            }
        });
    }, [dispatch, isMobile]);

    useEffect(() => {
        document.title = appName;
        userServices.GetUserByToken().then(response => {
            if (response.logout) {
                dispatch(logoutReducer());
                return;
            }
            if (!response.user || !response.config) {
                return;
            }
            dispatch(refreshUserReducer(response.user));
            dispatch(setConfigurationReducer(response.config));
        });
    }, [refresh]);

    useEffect(() => {
        helpers.GoToTop();
        if (['casa-en-casa', 'telefonica'].includes(location.pathname)) {
            setRefresh(x => !x);
        }
    }, [location.pathname]);

    return (
        <Suspense fallback={(<div> Cargando... </div>)}>

            <div style={{ backgroundColor: isDarkMode ? 'black' : 'white' }}>

                <div style={{ position: 'fixed', width: '100%', zIndex: 4 }}>
                    <NavBar />
                    <FloatingWidgets />
                    {alertModal.showingAlertModal && <AlertModal />}
                    {showingLoadingModal && <LoadingModal />}
                </div>

                <div style={{
                    maxWidth: isMobile ? '95%' : '90%',
                    paddingTop: '75px',
                    margin: 'auto',
                    minHeight: 'calc(100vh - 80px)'
                }}>
                    <MainRouter />
                    <DarkModeButton />
                </div>

                <Footer />

            </div>
        </Suspense>
    )
}
