import { BsArrowLeftCircleFill } from 'react-icons/bs';
import { ConfirmationResult, getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { firebaseConfig } from 'src/app-config';
import { initializeApp } from 'firebase/app';
import { Loading } from '../../_commons';
import { useGetIsDarkMode, useGetRecaptchaToken } from 'src/custom-hooks';
import { userServices } from 'src/services';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

type propsType = {
    loginUsingToken: (newToken: string) => Promise<void>;
    openAlertModalHandler: (title: string, message: string, animation: number, execution?: Function) => void;
    usePassword: () => void;
}

export const PhoneAuthentication: FC<propsType> = ({ loginUsingToken, openAlertModalHandler, usePassword }) => {
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [phone, setPhone] = useState('+54911');
    const getRecaptchaToken = useGetRecaptchaToken();
    const isDarkMode = useGetIsDarkMode();
    const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);

    const isValidSendPhoneNumber: boolean = useMemo(() =>
        phone.length > 9 && !isLoading
    , [isLoading, phone]);

    const isValidVerifyCode: boolean = useMemo(() =>
        !!confirmationResult && otp.length > 3 && !isLoading
    , [confirmationResult, isLoading, otp]);

    const sendPhoneNumber = async () => {
        if (!isValidSendPhoneNumber || !recaptchaVerifierRef.current) return;
        setIsLoading(true);
        let result: ConfirmationResult;
        try {
            result = await signInWithPhoneNumber(auth, phone, recaptchaVerifierRef.current);
        } catch (error) {
            console.error('Error enviando código:', error);
            return;
        } finally {
            setIsLoading(false);
        }
        setConfirmationResult(result);
        openAlertModalHandler('Número enviado', 'Cargar en el segundo campo la contraseña que llegue al celular', 1);
    }

    const verifyCode = async () => {
        if (!isValidVerifyCode || !confirmationResult) return;
        let firebaseToken: string | null = null;
        setIsLoading(true);
        try {
            const firebaseUserCredential = await confirmationResult.confirm(otp);
            firebaseToken = await firebaseUserCredential.user.getIdToken();
        } catch (error) {
            console.error('Error verificando código:', error);
            openAlertModalHandler('Algo falló', '', 2);
        } finally {
            setIsLoading(false);
        }
        if (!firebaseToken) {
            openAlertModalHandler('Algo falló', 'El servicio externo de contraseñas por SMS parece no funcionar', 2);
            return;
        }
        const recaptchaToken = await getRecaptchaToken();
        if (!recaptchaToken) {
            openAlertModalHandler("Problemas (6)", "Refrescar la página", 2);
            return;
        }
        setIsLoading(true);
        const response = await userServices.LoginByPhone(firebaseToken, recaptchaToken);
        setIsLoading(false);
        if (!response?.success || !response.newToken) {
            openAlertModalHandler('Algo falló', 'El código no es correcto o el teléfono no está registrado en la aplicación; hablar con un administrador', 2);
            return;
        }
        loginUsingToken(response.newToken);
    }

    useEffect(() => {
        if (!recaptchaVerifierRef.current) {
            recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: 'invisible',
            });
        }
    }, []);

    return (
        <div className={`${isDarkMode ? 'text-white' : ''} mt-5`}>
            <h3 className={'text-center'}>
                Recibir una contraseña de un único uso al celular
            </h3>
            <p className={'text-center mt-4 mb-2'}>
                *El número debe estar previamente registrado por un administrador
            </p>
            <p className={'text-center mb-2'}>
                **El número debe estar en formato internacional (comúnmente +54911 y los siguientes 8 números)
            </p>
            <p className={'text-center mb-5'}>
                ***El código tiene una hora de tolerancia (OTP significa 'one time password')
            </p>
            {isLoading && <Loading mt={'40px'} />}
            <div className={'container d-flex justify-content-center text-center mt-4 mb-2'}>
                <input type='text'
                    className={'form-control'}
                    style={{ width: '250px' }}
                    placeholder={'N° de tel. celular'}
                    value={phone}
                    onChange={e => isLoading ? null : setPhone(e.target.value)}
                    onKeyUp={e => e.key === 'Enter' ? sendPhoneNumber() : null}
                />
                <button className={'btn btn-general-blue ms-1'}
                    style={{ width: '140px' }}
                    onClick={sendPhoneNumber}
                    disabled={!isValidSendPhoneNumber}
                >
                    Enviar Código
                </button>
            </div>
            <div className={'container d-flex justify-content-center text-center my-1'}>
                <input type='number'
                    className={'form-control'}
                    style={{ width: '250px' }}
                    placeholder='Código OTP'
                    value={otp}
                    onChange={e => isLoading ? null : setOtp(e.target.value)}
                    onKeyUp={e => e.key === 'Enter' ? verifyCode() : null}
                />
                <button className={'btn btn-general-blue ms-1'}
                    style={{ width: '140px' }}
                    onClick={verifyCode}
                    disabled={!isValidVerifyCode}
                >
                    Verificar OTP
                </button>
            </div>
            <div id={'recaptcha-container'}></div>
            <div>
                <p className={`${isDarkMode ? 'bg-white' : ''} text-center py-2 mt-5 mb-2`}
                    style={{
                        color: '#0000cd',
                        fontSize: '1rem',
                        textDecoration: 'underline'
                    }}
                >
                    <BsArrowLeftCircleFill />
                    <span className={`ms-2 pointer`}
                        onClick={usePassword}
                    >
                        Ingresar con contraseña
                    </span>
                </p>
            </div>
        </div>
    )
}
