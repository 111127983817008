class Helpers {
    GetWeekNumber = (date: Date): number => {
        const firstJanuary = new Date(date.getFullYear(), 0, 1);
        const firstMonday = new Date(firstJanuary);
        firstMonday.setDate(firstJanuary.getDate() + (1 - firstJanuary.getDay() + 7) % 7);
        const diffInTime = date.getTime() - firstMonday.getTime();
        const diffInDays = Math.floor(diffInTime / (1000 * 60 * 60 * 24));
        return Math.floor(diffInDays / 7) + 1;
    }
    // GetWeeksInYear = (year: number): number => {
    //     let weeks = 0;
    //     const startOfYear = new Date(year, 0, 1);
    //     const endOfYear = new Date(year, 11, 31);
    //     const firstMonday = new Date(year, 0, 1);
    //     firstMonday.setDate(startOfYear.getDate() + (1 - startOfYear.getDay() + 7) % 7);
    //     if (firstMonday >= startOfYear && firstMonday <= endOfYear) {
    //         for (let date = firstMonday; date <= endOfYear; date.setDate(date.getDate() + 7)) {
    //             weeks++;
    //         }
    //     }
    //     return weeks;
    // }
    GoToTop = (top = 0) => window.scrollTo({ top, behavior: 'smooth' });
    NormalizeString = (value: string): string => {
        return `${value.toLowerCase()
            .replace(/\s+/g, '-')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ñ/g, 'n')}`
        ;
    }
    TimeConverter = (UNIX_timestamp: number, withHour: boolean = true): string => {
        try {
            const a: Date = new Date(UNIX_timestamp);
            const months = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
            const year = a.getFullYear();
            const month = months[a.getMonth()];
            const date = a.getDate();
            const hour = a.getHours();
            const min = a.getMinutes() < 10 ? "0" + a.getMinutes() : a.getMinutes();
            let time: string;
            if (withHour) {
                time = date + " " + month + " " + year + " - " + hour + ":" + min + " hs";
            } else {
                time = date + " " + month + " " + year;
            }
            return time;
        } catch (error) {
            console.log(error);
            return "Error en la fecha...";
        }
    }
}

export const helpers = new Helpers();
