import { CartParticipantFormAvailability } from './CartParticipantFormAvailability';
import { cartServices } from 'src/services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { priorities, typeCartAvailability, typeCartsDoc, typeCartParticipant, typeCartPriority } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser, useGetIsDarkMode, useGetShowingLoadingModal } from 'src/custom-hooks';
import { useState, FC, Dispatch, SetStateAction, useMemo } from 'react';
import Select from 'react-select';

type propsType = {
    cartDoc: typeCartsDoc;
    goBack: () => void;
    isEditInit: boolean;
    isSelfRegistration: boolean;
    selectedParticipant: typeCartParticipant | null;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    setSelectedParticipant: Dispatch<SetStateAction<typeCartParticipant | null>>;
}

export const CartParticipantForm: FC<propsType> = ({
    cartDoc, goBack, isEditInit, isSelfRegistration, selectedParticipant, setRefreshCounter, setSelectedParticipant
}) => {
    const user = useGetCurrentUser();
    //
    const [availabilities, setAvailabilities] = useState<typeCartAvailability[]>(selectedParticipant?.availabilities ?? []);
    const [comments, setComments] = useState(selectedParticipant?.comments ?? '');
    const [hasParticipatedBefore, setHasParticipatedBefore] = useState(selectedParticipant?.hasParticipatedBefore ?? false);
    const [isEdit, setIsEdit] = useState(isEditInit);
    const [isEnabled, setIsEnabled] = useState(selectedParticipant ? selectedParticipant.isEnabled : !!user.isCartsAdmin);
    const [priority, setPriority] = useState(selectedParticipant?.priority ?? priorities[0].value);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const showingLoadingModal = useGetShowingLoadingModal();

    const isValid: boolean = useMemo(() =>
        !!selectedParticipant
    , [selectedParticipant]);

    const updateParticipant = async () => {
        if (!selectedParticipant || !isEdit) return;
        const participant: typeCartParticipant = {
            availabilities,
            comments,
            email: selectedParticipant.email,  // send for logs
            hasParticipatedBefore,
            isEnabled,
            priority,
            userId: selectedParticipant.userId
        };
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se van a modificar los datos de ${selectedParticipant.name} ${selectedParticipant.lastName}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await cartServices.UpdateParticipant(participant);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    if (isSelfRegistration) {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            mode: 'alert',
                            title: 'Logrado',
                            message: '',
                            animation: 1,
                            execution: goBack
                        }));
                    } else {
                        goBack();
                    }
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const createParticipant = async () => {
        if (!selectedParticipant) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a suscribir a un participante: ${selectedParticipant.name} ${selectedParticipant.lastName}`,
            execution: async () => {
                const participant: typeCartParticipant = {
                    availabilities,
                    comments,
                    email: selectedParticipant.email,  // send for logs
                    isEnabled,
                    hasParticipatedBefore,
                    priority,
                    userId: selectedParticipant.userId
                };
                dispatch(showLoadingModalReducer());
                const success = await cartServices.AddParticipant(participant);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    if (isSelfRegistration) {
                        dispatch(setValuesAndOpenAlertModalReducer({
                            mode: 'alert',
                            title: 'Logrado',
                            message: '',
                            animation: 1,
                            execution: goBack
                        }));
                    } else {
                        goBack();
                    }
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    return (
        <div className={'container'}>
            <form onSubmit={e => {
                e.preventDefault();
                if (isEdit) {
                    updateParticipant();
                } else {
                    createParticipant();
                }
            }}>
                <div className={'row mb-5'}>
                    <div className={'col-md-6 col-sm-12 m-auto'}>
                        <label className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                            Seleccionar Usuario
                        </label>
                        <Select className={'text-black'}
                            options={cartDoc.users
                                ?.sort((u1, u2) => (u1.name ?? '').localeCompare((u2.name ?? '')))
                                ?? []
                            }
                            getOptionLabel={participant => `${participant.name} ${participant.lastName} - ${participant?.email}`}
                            getOptionValue={participant => participant?.userId?.toString() ?? ''}
                            value={selectedParticipant}
                            onChange={participant => {
                                setSelectedParticipant(participant);
                                if (!participant) return;  // ...
                                setAvailabilities(participant.availabilities ?? []);
                                setHasParticipatedBefore(participant.hasParticipatedBefore ?? false);
                                setPriority(participant.priority ?? priorities[0].value);
                                setComments(participant.comments ?? '');
                                setIsEdit(!!participant.availabilities?.length);  // || participant.wasDeleted
                            }}
                            isDisabled={isSelfRegistration}
                            autoFocus={!isSelfRegistration}
                            required
                        />
                    </div>
                </div>
                {selectedParticipant &&
                    <>
                    <div className={'row mb-3'}>
                        <div className={'col-md-3'}>
                            <label className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                                Nombre
                            </label>
                            <input type={'text'}
                                className={'form-control'}
                                value={selectedParticipant.name}
                                readOnly
                            />
                        </div>
                        <div className={'col-md-3'}>
                            <label className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                                Apellido
                            </label>
                            <input type={'text'}
                                className={'form-control'}
                                value={selectedParticipant.lastName}
                                disabled
                            />
                        </div>
                        <div className={'col-md-3'}>
                            <label className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                                Teléfono
                            </label>
                            <input type={'text'}
                                className={'form-control'}
                                value={selectedParticipant.phoneNumber || 'No registrado'}
                                disabled
                            />
                        </div>
                        {!isSelfRegistration &&
                            <div className={'col-md-3'}>
                                <label className={'form-label'}>Prioridad</label>
                                <select className={'form-select'}
                                    value={priority}
                                    onChange={e => setPriority(parseInt(e.target.value) as typeCartPriority)}
                                    required
                                >
                                    {priorities.map(p =>
                                        <option key={p.value} value={p.value}>{p.label}</option>
                                    )}
                                </select>
                            </div>
                        }
                    </div>

                    {!isSelfRegistration &&
                        <div className={`form-check col-md-6 col-sm-12 d-flex align-items-center justify-content-center ${isDarkMode ? '' : 'bg-light'} w-100 gap-4 py-1 mt-4 mb-2 pointer`}
                            onClick={() => setIsEnabled(x => !x)}
                        >
                            <input type={'checkbox'}
                                className={'form-check-input checkbox-md checkbox-red pointer'}
                                style={{ width: '25px', height: '25px' }}
                                checked={isEnabled}
                                onChange={() => {}}
                            />
                            <label className={`form-check-label fw-bold ${isDarkMode ? 'text-white' : ''} fs-5`}>
                                ¿Está habilitado?
                            </label>
                        </div>
                    }

                    <div className={'w-100 py-1 mt-4 mb-2'}>
                        <textarea className={'form-control'}
                            value={comments}
                            placeholder={'Algún comentario breve (opcional)'}
                            onChange={e => e.target.value.length < 100 ? setComments(e.target.value) : ''}
                        />
                    </div>

                    <div className={`form-check col-md-6 col-sm-12 d-flex align-items-center justify-content-center ${isDarkMode ? '' : 'bg-light'} w-100 gap-4 py-1 mt-4 mb-2`}
                        onClick={() => setHasParticipatedBefore(x => !x)}
                    >
                        <input type={'checkbox'}
                            className={'form-check-input checkbox-md checkbox-red'}
                            style={{ width: '25px', height: '25px' }}
                            checked={hasParticipatedBefore}
                            onChange={() => {}}
                        />
                        <label className={`form-check-label fw-bold ${isDarkMode ? 'text-white' : ''} fs-5`}>
                            ¿Ya participó alguna vez?
                        </label>
                    </div>

                    <hr />

                    <CartParticipantFormAvailability
                        availabilities={availabilities}
                        cartDoc={cartDoc}
                        setAvailabilities={setAvailabilities}
                    />

                    <div className={'d-flex justify-content-center mt-5'}>
                        {!showingLoadingModal &&
                            <button type={'submit'}
                                className={'btn btn-general-blue btn-block btn-lg mt-3 mb-5'}
                                style={{ width: '50%' }}
                                disabled={!isValid}
                            >
                                Guardar
                            </button>
                        }
                    </div>
                    </>
                }
            </form>
        </div>
    )
}
