import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { CartAssignmentList } from './CartAssignmentList';
import { CartCalendar } from './calendar/CartCalendar';
import { cartServices } from 'src/services';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { generalBlue } from 'src/app-config';
import { helpers } from 'src/services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { typeCartsDoc, typeCartAssignment } from '@monorepo/models';
import { useDispatch } from 'react-redux';

type propsType = {
    cartDoc: typeCartsDoc;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

type typeOption = 'calendar' | 'list';

const options: typeOption[] = ['calendar', 'list'];

export const CartAssignment: FC<propsType> = ({ cartDoc, setRefreshCounter }) => {
    const [radioValue, setRadioValue] = useState<typeOption>('calendar');
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();

    const deleteAssignment = (assignment: typeCartAssignment) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar esta Asignación de ${assignment.participants[0].name} ${assignment.participants[0].lastName} y ${assignment.participants[1].name} ${assignment.participants[1].lastName} en ${assignment.name}. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await cartServices.DeleteAssignment(assignment);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    useEffect(() => {
        helpers.GoToTop();
    }, [showFormAdd]);

    return (
        <div>
            <div className={'row px-4'}>
                <div className={'col-lg'}>
                    <ButtonGroup className={'d-flex flex-row w-100 mt-3 mb-2'}>
                        {options.map((radio, idx) =>
                            <ToggleButton key={idx}
                                id={`radio-${idx}`}
                                type={'radio'}
                                className={`${radioValue === radio ? '' : 'bg-secondary'} py-2`}
                                style={{ backgroundColor: radioValue === radio ? generalBlue : undefined }}
                                checked={radioValue === radio}
                                value={radio}
                                onChange={e => setRadioValue(e.currentTarget.value as typeOption)}
                            >
                                {radio === radioValue ? 'Viendo' : 'Ver'} {radio === 'calendar' ? 'Calendarios' : 'Lista'}
                            </ToggleButton>
                        )}
                    </ButtonGroup>
                </div>
            </div>
            {radioValue === 'calendar' ?
                <CartCalendar
                    cartDoc={cartDoc}
                    deleteAssignment={deleteAssignment}
                    nextMonth={false}
                    setRefreshCounter={setRefreshCounter}
                    thisMonth={false}
                />
                :
                <CartAssignmentList
                    cartDoc={cartDoc}
                    deleteAssignment={deleteAssignment}
                    setRefreshCounter={setRefreshCounter}
                />
            }
        </div>
    )
}
