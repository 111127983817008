import { BsArrowBarDown } from 'react-icons/bs';
import { Container } from 'react-bootstrap';
import { generalBlue, SERVER } from 'src/app-config';
import { H2, Hr, HTHAllBuildings, HTHAllDoNotCalls, Loading, WarningToaster } from 'src/components/_commons';
import { houseToHouseServices } from 'src/services';
import { HTHMapSection } from '.';
import { io } from 'socket.io-client';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { socketIOEvents } from '@monorepo/helpers';
import { typeDoNotCall, typeHTHTerritory, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetCurrentUser, useGetIsMobile } from 'src/custom-hooks';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';

const socket = io(SERVER, { withCredentials: true });

export const HouseToHousePage = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const queryParams = Object.fromEntries(urlSearchParams.entries());
    //
    const b = queryParams.b;
    const f = queryParams.f;
    //
    const [currentFace, setCurrentFace] = useState<typePolygon | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshCounter, setRefreshCounter] = useState(1);
    const [showSearchSection, setShowSearchSection] = useState(false);
    const [territoryHTH, setTerritoryHTH] = useState<typeHTHTerritory | null>(null);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const territoryNumber = useParams<any>().territoryNumber as typeTerritoryNumber;
    const user = useGetCurrentUser();

    useEffect(() => {
        if (refreshCounter === 1) setIsLoading(true);
        houseToHouseServices.GetTerritory(territoryNumber).then(response => {
            setIsLoading(false);
            const hthTerritory0 = response?.hthTerritory ?? null;
            if (!hthTerritory0) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: `No se pudo recuperar el territorio ${territoryNumber}`,
                    animation: 2
                }));
                return;
            }
            setTerritoryHTH(hthTerritory0);
            setCurrentFace(x => {
                if (!x) {
                    if (refreshCounter === 1 && b && f) {
                        const currentFace0: typePolygon|null = hthTerritory0.map.polygons?.find(y => y.block === b && y.face === f) ?? null;
                        if (currentFace0) {
                            if (currentFace0.doNotCalls) {
                                currentFace0.doNotCalls = currentFace0.doNotCalls.sort((a: typeDoNotCall, b: typeDoNotCall) => a.streetNumber - b.streetNumber);
                            }
                            if (currentFace0.observations) {
                                currentFace0.observations = currentFace0.observations.reverse();
                            }
                        }
                        return currentFace0;
                    } else {
                        return x;
                    }
                }
                const currentFace0: typePolygon|null = hthTerritory0.map.polygons?.find(y => y.block === x.block && y.face === x.face) ?? null;
                if (currentFace0) {
                    if (currentFace0.doNotCalls) {
                        currentFace0.doNotCalls = currentFace0.doNotCalls.sort((a: typeDoNotCall, b: typeDoNotCall) => a.streetNumber - b.streetNumber);
                    }
                    if (currentFace0.observations) {
                        currentFace0.observations = currentFace0.observations.reverse();
                    }
                }
                return currentFace0;
            });
            hthTerritory0.map.polygons.forEach(x => {
                x.completionData?.completionDates?.forEach(y => {
                    // console.log("Closed:", new Date(y))
                    // const date = new Date(y)
                    // const month = date.getUTCMonth()
                    // const year = date.getUTCFullYear()
                    // console.log("Closed:", date)
                    // if (monthAndYear && monthAndYear.month === month && monthAndYear.year === year) return
                    // if (monthAndYear) twoMonths = true
                    // monthAndYear = { month, year }
                });
                x.completionData?.reopeningDates?.forEach(y => {
                    // console.log("Opened:", new Date(y))
                });
            })
        });
    }, [b, dispatch, f, refreshCounter, territoryNumber]);

    useEffect(() => {
        socket.on(socketIOEvents.hthChange, (_congregation: number, _territoryNumber: typeTerritoryNumber) => {
            if (_congregation !== user.congregation) return;
            if (!!territoryNumber && _territoryNumber !== territoryNumber) return;
            console.log("Refrescando por uso de un usuario");
            setTimeout(() => {
                setRefreshCounter(x => x + 1);
            }, Math.floor(Math.random() * 2000));
        });
        return () => {
            socket.off(socketIOEvents.hthChange);
        };
    // eslint-disable-next-line
    }, [territoryNumber, user.congregation]);

    return (<>
        <H2 title={`CASA EN CASA - T${territoryNumber}`} />

        {isLoading && <Loading mt={'60px'} />}

        {territoryHTH && (!socket || !socket.connected) &&
            <div style={{ marginTop: '30px', position: 'fixed', zIndex: 4 }}>
                <WarningToaster
                    bodyText={"Refrescar la página y verificar que hay internet"}
                    headerText={<strong>Hay un problema de conexión</strong>}
                />
            </div>
        }

        {(user.isBuildingsAdmin || user.hthAssignments?.includes(parseInt(territoryNumber))) &&
            <HTHMapSection
                currentFace={currentFace}
                setCurrentFace={setCurrentFace}
                setTerritoryHTH={setTerritoryHTH}
                territoryHTH={territoryHTH}
                territoryNumber={territoryNumber}
            />
        }

        {territoryHTH && <>
            <Container>
                <h1 className={'container pointer btn-general-blue text-white py-3'}
                    style={{
                        backgroundColor: generalBlue,
                        fontSize: isMobile ? '1.8rem' : '2.4rem',
                        fontWeight: 'bold',
                        margin: isMobile ? '30px auto 20px auto' : '60px auto 40px auto',
                        textAlign: 'center'
                    }}
                    onClick={() => setShowSearchSection(x => !x)}
                >
                    {showSearchSection ? 'OCULTAR BUSCADOR' : 'VER BUSCADOR'} <BsArrowBarDown size={isMobile ? '1.6rem' : '1.4rem'} />
                </h1>
                {showSearchSection && <>
                    <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />
                    <HTHAllDoNotCalls
                        hthTerritory={territoryHTH}
                    />
                    <Hr classes={'text-center my-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />
                    <HTHAllBuildings
                        block={null}
                        congregation={user.congregation}
                        territoryHTH={territoryHTH}
                        usingLettersForBlocks={config.usingLettersForBlocks}
                    />
                    <Hr classes={'text-center mt-5 mx-auto'} styles={{ maxWidth: isMobile ? '95%' : '85%' }} />
                </>}   
            </Container>
        </>}
    </>)
}
