import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeLogType, typeResponseData } from '@monorepo/models';

class LogServices {
    private Base = pointer.log;
    async GetAll(all: boolean, type: typeLogType | null, congregation: number | null): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}?all=${all}&type=${type ?? ''}&congregation=${congregation ?? ''}`, 'GET');
        if (!data || !data.success || (!data.logs && !data.log)) return null;
        return data;
    }
}

export const logServices = new LogServices();
