import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeCoords } from '@monorepo/models';

class GeocodingServices {
    private Base = pointer.geocoding;
    async GetCoordinatesFromAddress(address: string): Promise<typeCoords | null> {
        if (!address) return null;
        const data = await api.SendRequest(`${this.Base}/address`, 'POST', { address });
        if (!data?.success || !data?.coordinates) return null;
        return data.coordinates;
    }
    
    async GetAddressFromCoordinates(coordinates: typeCoords): Promise<string|null> {
        if (!coordinates || !coordinates.lat || !coordinates.lng) return null;
        const data = await api.SendRequest(`${this.Base}/coordinates`, 'POST', { coordinates });
        if (!data?.success || !data?.address) return null;
        return data.address;
    }
    
    async GetStreetFromCoordinates(coordinates: typeCoords): Promise<string|null> {
        if (!coordinates || !coordinates.lat || !coordinates.lng) return null;
        const data = await api.SendRequest(`${this.Base}/street`, 'POST', { coordinates });
        if (!data?.success || !data?.street) return null;
        return data.street;
    }
}

export const geocodingServices = new GeocodingServices();
