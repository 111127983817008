import { houseToHouseServices } from 'src/services';
import { HTHBuildingModal } from 'src/components/_commons';
import { io } from 'socket.io-client';
import { SERVER } from 'src/app-config';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { socketIOEvents } from '@monorepo/helpers';
import { typeAddress, typeBlock, typeFace, typeHTHBuilding, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useIdleTimeout } from 'src/custom-hooks';
import { useNavigate, useParams } from 'react-router';

const socket = io(SERVER, { withCredentials: true });

export const HTHBuildingPage = () => {
    const params = useParams();
    //
    const block = params.block as typeBlock;
    const congregation = parseInt(params.congregation || '');
    const face = params.face as typeFace;
    const streetNumber = parseInt(params.streetNumber || '');
    const territoryNumber = params.territoryNumber as typeTerritoryNumber;
    //
    const [currentBuilding, setCurrentBuilding] = useState<typeHTHBuilding|null>(null);
    const [currentFace, setCurrentFace] = useState<typePolygon|null>(null);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const closeBuildingModalHandler = () => navigate('/');

    useIdleTimeout(5*60*1000, () => closeBuildingModalHandler());

    useEffect(() => {
        if (!congregation || isNaN(congregation) || !territoryNumber || !block || !face || !streetNumber)
            return;
        houseToHouseServices.GetBuilding(congregation, territoryNumber, block, face, streetNumber).then(response => {
            if (!response) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "No se pudieron recuperar los datos; tal vez no haya internet",
                    animation: 2
                }));
                return;
            }
            if (response.notSharedToday) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "Este edificio no fue compartido hoy por un capitán de salida",
                    animation: 2
                }));
                return;
            }
            const t = response.hthTerritory;
            const f = t?.map.polygons.find(x => x.block === block && x.face === face);
            const b = f?.buildings?.find(x => x.streetNumber === streetNumber);
            if (!t || !f || !b) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: 'Algo falló',
                    message: 'No se encontró el edificio; tal vez no haya internet',
                    animation: 2
                }));
                return;
            }
            setCurrentFace(f);
            setCurrentBuilding(b);
        });
    }, [block, congregation, face, refreshCounter, streetNumber, territoryNumber]);

    useEffect(() => {
        socket.on(socketIOEvents.hthChange, (_congregation: number, _territoryNumber: typeTerritoryNumber, _address?: typeAddress) => {
            if (_congregation !== congregation) return;
            if (!!_territoryNumber && _territoryNumber !== territoryNumber) return;
            if (!!_address && (_address.block !== block || _address.face !== face || _address.streetNumber !== streetNumber)) return;
            console.log("Refrescando por uso de un usuario");
            setTimeout(() => {
                setRefreshCounter(x => x + 1);
            }, Math.floor(Math.random() * 2000));
        });
        return () => {
            socket.off(socketIOEvents.hthChange);
        };
    // eslint-disable-next-line
    }, [block, congregation, face, streetNumber, territoryNumber]);

    return (
        <>
            {currentBuilding && currentFace && !!territoryNumber &&
                <HTHBuildingModal
                    closeBuildingModalHandler={closeBuildingModalHandler}
                    congregation={congregation}
                    currentBuilding={currentBuilding}
                    currentFace={currentFace}
                    territoryNumber={territoryNumber}
                />
            }
        </>
    )
}
