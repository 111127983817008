import { FC, FormEvent, useState } from 'react';
import { houseToHouseServices } from 'src/services';
import { HTHForm } from 'src/components/_commons';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeDoNotCall, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetCurrentUser } from 'src/custom-hooks';

type propsType = {
    closeShowFormHandler: () => void;
    currentFace: typePolygon;
    territoryNumber: typeTerritoryNumber;
}

export const HTHDoNotCallsForm: FC<propsType> = ({ closeShowFormHandler, currentFace, territoryNumber }) => {
    const user = useGetCurrentUser();
    const dispatch = useDispatch();
    const [doorBell, setDoorBell] = useState("");
    const [streetNumber, setStreetNumber] = useState(0);
    const date: string = new Date(new Date().getTime()-(new Date().getTimezoneOffset()*60*1000)).toISOString().split('T')[0];

    const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        if (!user || streetNumber < 1) return;
        const newDoNotCall: typeDoNotCall = {
            creatorId: user.id,
            date,
            doorBell: doorBell.trim(),
            id: Date.now(),
            streetNumber
        };
        houseToHouseServices.AddDoNotCall(territoryNumber, currentFace.block, currentFace.face, currentFace.id, newDoNotCall).then((success: boolean) => {
            if (success) {
                closeShowFormHandler();
                setStreetNumber(0);
                setDoorBell('');
            } else {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Algo falló",
                    message: "Refrescar e intentar de nuevo",
                    animation: 2
                }));
            }
        });
    }

    const cancelFormHandler = (): void => {
        closeShowFormHandler();
        setStreetNumber(0);
        setDoorBell('');
    }

    const setDoorBellHandler = (doorBell0: string): void => setDoorBell(doorBell0);

    const setStreetNumberHandler = (streetNumber0: number): void => setStreetNumber(streetNumber0);

    return (
        <HTHForm
            cancelFormHandler={cancelFormHandler}
            currentFace={currentFace}
            isDoNotCallForm={true}
            submitHandler={submitHandler}
            territoryNumber={territoryNumber}
            // specific
            date={date}
            doorBell={doorBell}
            setDoorBellHandler={setDoorBellHandler}
            setStreetNumberHandler={setStreetNumberHandler}
            streetNumber={streetNumber}
        />
    )
}
