import { CartCalendarItem } from '../../admins-page/carts/assignment/calendar/CartCalendarItem';
import { cartServices } from 'src/services';
import { Document, Page } from 'react-pdf';
import { FaArrowLeft, FaArrowRight, FaDownload } from 'react-icons/fa';
import { FC, useEffect, useState } from 'react';
import { Hr, Loading } from 'src/components/_commons';
import { typeBoardItem, typeBoardSection, typeCartsDoc } from '@monorepo/models';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    section: typeBoardSection;
}

export const BoardSection: FC<propsType> = ({ section }) => {
    return (
        <div>
            <style>{`
                .react-pdf__Page__canvas {width: 100% !important; height: auto !important;}
            `}</style>
            {section.items.sort((i1, i2) => i1.order - i2.order).filter(i => !i.archived).map(item =>
                <div key={item.id} className={'mb-5'}>
                    {item.type === 'carts' &&
                        <Carts />
                    }
                    {item.type === 'file' &&
                        <File file={item} />
                    }
                    {item.type === 'text' &&
                        <Text item={item} />
                    }
                </div>
            )}
        </div>
    )
}

const File = ({ file }: { file: typeBoardItem }) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const isMobile = useGetIsMobile();

    if (!file.data) return <>Archivo sin contenido</>;

    return (
        <div key={file.date} className={'w-100 h-auto overflow-hidden mt-5'} style={{ backgroundColor: 'lightgrey' }}>
            <Hr />
            {!!numPages &&
                <div className={'d-flex justify-content-between align-items-center my-2 mx-5'}>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        style={{ width: isMobile ? '60px' : undefined }}
                        onClick={() => setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1))}
                        disabled={pageNumber <= 1}
                    >
                        <FaArrowLeft /> {isMobile ? '' : 'Anterior'}
                    </button>
                    <span className={isMobile ? '' : 'ms-1'}>
                        Página {pageNumber} de {numPages}
                    </span>
                    <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''}`}
                        style={{ width: isMobile ? '60px' : undefined }}
                        onClick={() => setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages))}
                        disabled={pageNumber >= numPages}
                    >
                        {isMobile ? '' : 'Siguiente'} <FaArrowRight />
                    </button>
                </div>
            }
            <div className={'text-center my-2'}>
                <a href={URL.createObjectURL(new Blob([new Uint8Array(file.data)]))}
                    className={`btn btn-general-red btn-sm ${isMobile ? '' : 'py-2'} px-3`}
                    download={file.originalName}
                >
                    <FaDownload className={'mb-1 me-2'} /> Descargar
                </a>
            </div>
            <div className={'m-3'}>
                <Document
                    file={{ url: URL.createObjectURL(new Blob([new Uint8Array(file.data)], { type: 'application/pdf' })) }}
                    loading={<Loading mt={'90px'} mb={'50px'} />}
                    onLoadSuccess={e => setNumPages(e.numPages)}
                >
                    <Page className={'w-100 h-auto'} pageNumber={pageNumber} scale={1.5} />
                </Document>
            </div>
        </div>
    )
}

const Carts = () => {
    const [cartDoc, setCartDoc] = useState<typeCartsDoc | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const isDarkMode = useGetIsDarkMode();

    useEffect(() => {
        setIsLoading(true);
        cartServices.GetDoc().then(_cartDoc => {
            setIsLoading(false);
            setCartDoc(_cartDoc);
        });
    }, []);

    if (isLoading) return <Loading mt={'40px'} />;

    if (!cartDoc) return <>Error recuperando los datos</>;

    return (
        <div className={isDarkMode ? 'bg-dark text-white' : ''}>
            {cartDoc.points?.filter(p => !p.wasDeleted && cartDoc.assignments.some(a => a.pointId === p.id)).map(point =>
                <div key={point.id}>
                    <CartCalendarItem
                        cartDoc={cartDoc}
                        month={new Date().getMonth()}
                        point={point}
                        readOnly={true}
                        setRefreshCounter={() => {}}
                        year={new Date().getFullYear()}
                    />
                </div>
            )}
            {new Date().getDate() > 24 &&
                cartDoc.points?.filter(p => !p.wasDeleted && cartDoc.assignments.some(a => a.pointId === p.id)).map(point =>
                    <div key={point.id}>
                        <CartCalendarItem
                            cartDoc={cartDoc}
                            month={(new Date().getMonth() + 1) % 12}
                            point={point}
                            readOnly={true}
                            setRefreshCounter={() => {}}
                            year={new Date().getMonth() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear()}
                        />
                    </div>
                )
            }
        </div>
    )
}

const Text = ({ item }: { item: typeBoardItem }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={`card ${isDarkMode ? 'bg-dark text-white' : ''}`}>
            <h1 className={'p-3'}>
                {item.title}
            </h1>
            <p className={'using-line-breaks ps-3'}>
                {item.content}
            </p>
        </div>
    )
}
