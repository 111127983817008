import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeMeetingsDoc, typeMidweekMeeting, typePublicMeeting } from '@monorepo/models';

class MeetingServices {
    private _base = pointer.meeting;
    //
    Get = async (): Promise<typeMeetingsDoc | null> => {
        const data = await api.SendRequest(this._base, 'GET');
        return data?.meetingDoc ?? null;
    }
    GetNewMidweek = async (weekNumber: number, year: number): Promise<typeMidweekMeeting | null> => {
        const data = await api.SendRequest(`${this._base}/midweek?weekNumber=${weekNumber}&year=${year}`, 'GET');
        return data?.midweekMeeting ?? null;
    }
    GetNewPublic = async (weekNumber: number, year: number): Promise<typePublicMeeting | null> => {
        const data = await api.SendRequest(`${this._base}/public?weekNumber=${weekNumber}&year=${year}`, 'GET');
        return data?.publicMeeting ?? null;
    }
}

export const meetingServices = new MeetingServices();
