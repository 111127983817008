import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { CartsNotifications } from './subcomponents/CartsNotifications';
import { generalBlue } from 'src/app-config';
import { H2, Loading } from 'src/components/_commons';
import { notificationServices } from 'src/services';
import { NotificationsModal } from './subcomponents/NotificationsModal';
import { notificationTypes, typeNotificationsDoc, typeNotificationType } from '@monorepo/models';
import { useEffect, useState } from 'react';

export const AdminsNotificacions = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [notificationDoc, setnotificationDoc] = useState<typeNotificationsDoc | null>(null);
    const [radioValue, setRadioValue] = useState<typeNotificationType | null>(null);
    const [refreshCounter, setRefreshCounter] = useState(0);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        notificationServices.GetDoc().then(_notificationDoc => {
            setIsLoading(false);
            if (_notificationDoc) setnotificationDoc(_notificationDoc);
        });
    }, [refreshCounter]);

    if (isLoading) return <Loading mt={'80px'} />;

    if (!notificationDoc) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    return (
        <>
        {showModal &&
            <NotificationsModal
                closeModal={() => setShowModal(false)}
            />
        }
        <H2 title={'NOTIFICACIONES'} />
        {/* <div className={'text-center'}>
            <button className={'btn btn-general-blue'} onClick={() => {}}>
                Cómo funciona
            </button>
        </div> */}
        <div className={'row'}>
            <div className={'col-lg'}>
                <ButtonGroup className={'d-flex flex-column flex-lg-row w-100 mt-3 mb-2'}>
                    {notificationTypes.map((radio, idx) =>
                        <ToggleButton key={idx}
                            id={`radio-${idx}`}
                            type={'radio'}
                            className={`${radioValue === radio.type ? '' : 'bg-secondary'} py-2`}
                            style={{ backgroundColor: radioValue === radio.type ? generalBlue : undefined }}
                            checked={radioValue === radio.type}
                            value={radio.type}
                            onChange={e => setRadioValue(e.currentTarget.value as typeNotificationType)}
                        >
                            {radio.label}
                        </ToggleButton>
                    )}
                </ButtonGroup>
            </div>
        </div>

        {radioValue === 'carts' &&
            <CartsNotifications
                notificationDoc={notificationDoc}
                setRefreshCounter={setRefreshCounter}
            />
        }
        </>
    )
}
