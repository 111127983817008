import { Dispatch, FC, SetStateAction } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { Loading } from 'src/components/_commons';
import { notificationServices } from 'src/services';
import { typeNotificationsDoc } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile, useGetShowingLoadingModal } from 'src/custom-hooks';

type propsType = {
    checked: boolean;
    label: string;
    newNotificationDoc: typeNotificationsDoc;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const Checkbox: FC<propsType> = ({ checked, label, newNotificationDoc, setRefreshCounter }) => {
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();
    const showingLoadingModal = useGetShowingLoadingModal();

    const setOption = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se van a ${checked ? 'deshabilitar' : ' habilitar'} las notificaciones automáticas de Carritos por WhatsApp`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await notificationServices.SetOptions(newNotificationDoc);
                dispatch(hideLoadingModalReducer());
                if (!success) {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        mode: 'alert',
                        title: 'Error',
                        message: 'Falló el cambio de opción. Mirar los logs.'
                    }));
                    return;
                }
                setRefreshCounter(x => x + 1);
            }
        }));
    }

    return (
        <div>
            {showingLoadingModal ?
                <Loading mt={'30px'} />
                :
                <div className={`container card ${isDarkMode ? 'bg-dark text-white' : ''} py-3 my-5 bg-secondary text-white hover-primary pointer`}
                    style={{ width: isMobile ? '400px' : '600px' }}
                    onClick={setOption}
                >
                    <div className={'d-flex row justify-content-center align-items-center'}>
                        <div className={'col-3 text-end'}>
                            <input type={'checkbox'}
                                className={'form-check-input checkbox-md checkbox-red pointer'}
                                checked={checked}
                                onChange={() => {}}
                            />
                        </div>
                        <div className={'col'}>
                            <h5 className={`${isDarkMode ? 'text-white' : ''} pt-2`}>
                                {label}
                            </h5>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
