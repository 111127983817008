import { BsArrowBarDown } from 'react-icons/bs';
import { FC, useState } from 'react';
import { generalBlue } from 'src/app-config';
import { HTHObservationsForm, HTHObservationsItem } from '../';
import { typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    currentFace: typePolygon;
    territoryNumber: typeTerritoryNumber;
}

export const HTHObservations: FC<propsType> = ({ currentFace, territoryNumber }) => {
    const [show, setShow] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const isMobile = useGetIsMobile();

    const closeShowFormHandler = () => setShowForm(false);

    return (
        <div style={{ marginTop: '100px', marginBottom: '50px' }}>
            
            <h1 className={'py-3 text-center text-white d-block mx-auto pointer rounded-3'}
                style={{
                    backgroundColor: !!currentFace.observations?.length ? generalBlue : 'gray',
                    marginBottom: '50px',
                    width: isMobile ? '100%' : '90%'
                }}
                onClick={() => setShow(x => !x)}
            >
                {!!currentFace.observations?.length ? 'OBSERVACIONES' : 'No hay Observaciones en esta cara'} <BsArrowBarDown size={isMobile ? '2rem' : '1.4rem'} />
            </h1>

            {show && <>
                {!!currentFace.observations?.length && currentFace.observations.map(observation =>
                    <HTHObservationsItem
                        closeShowAddFormHandler={closeShowFormHandler}
                        currentFace={currentFace}
                        key={observation.id}
                        observation={observation}
                        territoryNumber={territoryNumber}
                    />
                )}

                <button className={'btn btn-general-blue btn-size12 d-block mx-auto'}
                    style={{ marginTop: '50px', width: '250px' }}
                    onClick={() => setShowForm(!showForm)}
                >
                    {showForm ? 'Ocultar' : 'Agregar Observación'}
                </button>

                {showForm &&
                    <HTHObservationsForm
                        closeShowFormHandler={closeShowFormHandler}
                        currentFace={currentFace}
                        territoryNumber={territoryNumber}
                        editText={''}
                        idEdit={0}
                    />
                }
            </>}

        </div>
    )
}
