import { FC, useEffect, useState } from 'react';
import { Loading } from 'src/components/_commons';
import { meetingServices } from 'src/services';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typePublicMeeting, typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { userServices } from 'src/services';
import Select from 'react-select';

type propsType = {
    cancel: () => void;
    weekNumber: number;
    year: number;
}   

export const PublicMeetingsCreate: FC<propsType> = ({ cancel, weekNumber, year }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [newPublicMeeting, setNewPublicMeeting] = useState<typePublicMeeting | null>(null);
    const [users, setUsers] = useState<typeUser[] | null>(null);
    const dispatch = useDispatch();

    const saveHandler = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a crear la reunión de fin de semana ${weekNumber} de ${year}`,
            execution: async () => {

            }
        }));
    }

    const cancelHandler = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a cancelar la creación de la reunión de fin de semana ${weekNumber} de ${year}`,
            animation: 3,
            execution: cancel
        }));
    }

    useEffect(() => {
        userServices.GetAll().then(_users => {
            setUsers(_users);
        });
        meetingServices.GetNewPublic(weekNumber, year).then(_newPublicMeeting => {
            setIsLoading(false);
            console.log(_newPublicMeeting);
            if (!_newPublicMeeting) return;
            setNewPublicMeeting(_newPublicMeeting);
        });
    }, []);

    if (isLoading) return <Loading mt={'40px'} />;

    if (!newPublicMeeting) return <>No se pudieron recuperar los datos</>;

    return (
        <div className={'mt-5'}>
            {newPublicMeeting.sections.map((section, i) =>
                <div key={i}>
                    <h3 className={`${i === 1 ? 'bg-green' : i === 2 ? 'bg-yellow' : i === 3 ? 'bg-general-red' : 'bg-general-blue'} text-white py-1 ps-3 mt-4`}>
                        {section.title}
                    </h3>
                    {section.segments.map((segment, j) =>
                        <div key={j}>
                            <div>
                                {!!j && <hr />}
                                <h5 className={'mt-3'}>
                                    {segment.subtitle}
                                </h5>
                            </div>
                            {segment.descriptions.map((description, k) =>
                                <p key={k} className={'ps-3 my-1'}>
                                    {description}
                                </p>
                            )}
                            <div className={'d-flex mt-3'}>
                                <div style={{ width: '100px' }}>Auditorio</div>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        options={users ?? []}
                                        getOptionLabel={u => `${u.name} ${u.lastName}`}
                                        getOptionValue={u => u?.id.toString()}
                                        value={users?.find(u => u.id === 1)}
                                        onChange={() => {}}
                                    />
                                </div>
                                <div style={{ width: '100px' }}>Ayudante</div>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        options={users ?? []}
                                        getOptionLabel={u => `${u.name} ${u.lastName}`}
                                        getOptionValue={u => u?.id.toString()}
                                        value={users?.find(u => u.id === 1)}
                                        onChange={() => {}}
                                    />
                                </div>
                                <div style={{ width: '100px' }}>Sala Aux.</div>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        options={users ?? []}
                                        getOptionLabel={u => `${u.name} ${u.lastName}`}
                                        getOptionValue={u => u?.id.toString()}
                                        value={users?.find(u => u.id === 1)}
                                        onChange={() => {}}
                                    />
                                </div>
                                <div style={{ width: '100px' }}>Ayudante Sala Aux.</div>
                                <div style={{ width: '200px' }}>
                                    <Select
                                        options={users ?? []}
                                        getOptionLabel={u => `${u.name} ${u.lastName}`}
                                        getOptionValue={u => u?.id.toString()}
                                        value={users?.find(u => u.id === 1)}
                                        onChange={() => {}}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className={'d-flex justify-content-center gap-2 mt-5'}>
                <button className={'btn btn-general-blue'}
                    style={{ width: '150px' }}
                    onClick={saveHandler}
                    disabled
                >
                    Guardar
                </button>
                <button className={'btn btn-general-red'}
                    style={{ width: '150px' }}
                    onClick={cancelHandler}
                >
                    Cancelar
                </button>
            </div>
        </div>
    )
}
