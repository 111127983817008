import { H2, Hr } from 'src/components/_commons';
import { localStorageHelper } from 'src/services';
import { useEffect } from 'react';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

export const NewsPage = () => {
    const isDarkMode = useGetIsDarkMode();
    //
    const blockClassName = `container ${isDarkMode ? 'text-white' : ''} mb-5`;
    const isMobile = useGetIsMobile();
    const itemClassName = `my-3`;
    const style = { maxWidth: '100%', height: 'auto', maxHeight: '500px', margin: isMobile ? '20px 0' : '40px' };
    const titleClassName = `${isMobile ? 'h4' : 'h3'} mt-4 mb-3`;

    useEffect(() => {
        localStorageHelper.SetNewsVisited();
    }, []);

    return (<>
        <H2 title='Novedades' />

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>20 de Marzo de 2025</div>
            <div className={itemClassName}>-Se agregó la opción de reestructuración de territorios para Casa en Casa (solo administradores).</div>
            <div className={itemClassName}>-Se agregó la opción de ingreso a la aplicación usando SMS (solo celulares previamente registrados por un administrador).</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>03 de Marzo de 2025</div>
            <div className={itemClassName}>-Se corrigió el contador de edificios libres que no sumaba portería.</div>
            <div className={itemClassName}>-Se agregó edición de edificios (solo administradores): admite cambio de territorio/manzana/calle y numeración (admite también agregado y edición de segundo y tercer número, ejemplo: Av. Rivadavia 6133/6135/6137).</div>
            <div className={'text-center'}>
                <img src={`${process.env.PUBLIC_URL}/news/hth-edit.png`}
                    alt={'Editar edificio'}
                    style={style}
                />
            </div>
            <div className={'text-center'}>
                <img src={`${process.env.PUBLIC_URL}/news/hth-edit-1.png`}
                    alt={'Editar edificio'}
                    style={style}
                />
            </div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>29 de Septiembre de 2024</div>
            <div className={itemClassName}>-Se sacaron de la sección de Usuarios los controles de asignaciones de Casa en Casa y de Telefónica.</div>
            <div className={itemClassName}>-Se llevó el control de asignaciones de Casa en Casa a la sección de Casa en Casa para Administradores.</div>
            <div className={itemClassName}>-Se llevó el control de asignaciones de Telefónica a la sección de Telefónica para Administradores.</div>
            <div className={itemClassName}>-Para Telefónica, los controles de reseteo de territorios también se llevaron a la sección de Telefónica para Administradores y ya no están en la pestaña de Estadísticas.</div>
            <div className={itemClassName}>-Las opciones de Configuración para Casa en Casa se movieron a la sección de Casa en Casa para Administradores.</div>
            <div className={itemClassName}>-Todo Carritos se llevó a Administradores.</div>
            <div className={itemClassName}>-Se lanzó la nueva versión del Tablero, más configurable, con posibilidad de ordenar ítems y archivar PDFs.</div>
            <div className={itemClassName}>-Se separaron en dos distintos links y páginas los selectores de territorios para Casa en Casa y Telefónica.</div>
            <div className={itemClassName}>-Se crearon secciones en Administradores para las nuevas características en desarrollo: salidas de predicación, asignaciones en las reuniones, asistencia a las reuniones, informes de predicación, formulario S13, grupos de predicación y notificaciones.</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>25 de Agosto de 2024</div>
            <div className={itemClassName}>-Se mejoró la pantalla de consulta de logs.</div>
            <div className={itemClassName}>-Se mejoró la sección de edificios en Estadísticas de Casa en Casa.</div>
            <div className={itemClassName}>-Se agregó la consulta de asignaciones de Carritos en Mi Usuario.</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>21 de Agosto de 2024</div>
            <div className={itemClassName}>-Se agregó en Estadísticas de Casa en Casa un mapa para consultar los edificios predicados por día o por rango de días.</div>
            <div className={itemClassName}>-Se creó un Tablero para subir archivos con información de las reuniones y salidas. Es una primera etapa.</div>
            <div className={itemClassName}>-Se agregó el rol de Administrador del Tablero.</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>04 de Agosto de 2024</div>
            <div className={itemClassName}>-Se lanzó la opción para Administradores Generales de suscribir los números celulares de los usuarios con doble autenticación (consentimiento explícito) para que los usuarios comiencen a usar el chatbot de WhatsApp (aun sin funciones).</div>
            <div className={itemClassName}>-Se agregó en Mi Usuario la opción de eliminar el número celular propio.</div>
            <div className={itemClassName}>-Se agregó en Mi Usuario una consulta de los datos personales que guarda la aplicación.</div>
            <div className={itemClassName}>-Se dio acceso a Logs parciales a Administradores no Generales según tema (Administradores de Casa en Casa ven Logs de Casa en casa, Administradores de Carritos ven Logs de Carritos, etc.).</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>31 de Julio de 2024</div>
            <div className={itemClassName}>-Se agregó la nueva aplicación para la gestión de los Carritos/Exhibidores, en etapa de pruebas finales.</div>
            <div className={itemClassName}>-Se agregó la opción en Casa en Casa de compartir el link único de los edificios de un territorio mediante código QR. Tocar por segunda vez el botón <i>"Copiar link único de todo el Territorio para compartir"</i> y aparece el QR.</div>
            <div className={itemClassName}>-Se agregó la opción en Mi Usuario de eliminar la cuenta propia, para complir con el requerimiento de uso de datos personales.</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>29 de Julio de 2024</div>
            <div className={itemClassName}>-Se agregó la opción en Casa en Casa de compartir los edificios de un territorio o de una manzana mediante un único link de acceso a una página con buscador de edificios.</div>
            <div className={itemClassName}>-Se agregó la página 'Sobre Nosotros' para complir con los requisitos formales de la API de WhatsApp. Las páginas 'Términos de Uso' y 'Privacidad' se habían agregado por el mismo motivo para la API de Gmail.</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>19 de Julio de 2024</div>
            <div className={itemClassName}>-Se corrigió una falla que causaba caídas de servicio en los territorios de Casa en Casa.</div>
            <div className={itemClassName}>-Se agregó un servicio de cache para acelerar las cargas de los territorios de Casa en Casa.</div>
            <div className={itemClassName}>-Se agregó (solo para Administradores Generales) una ventana de consulta de los administradores de la aplicación para la congregación en Administradores-Usuarios ("Ver Roles").</div>
        </div>

        <Hr />

        <div className={blockClassName}>
            <div className={titleClassName}>11 de Julio de 2024</div>
            <div className={itemClassName}>-Se insertaron 2 aplicaciones como "microservicios" construídas sobre una tecnología distinta a la de esta aplicación para comprobar la compatibilidad. La primera es una aplicación para armar hojas de asignaciones para las reuniones de entresemana y está plenamente funcionando. La segunda es un prototipo de aplicación para la administración de los carritos/exhibidores, aun en desarrollo.</div>
            <div className={itemClassName}>-Se cambió el sistema de roles. Primeramente, el "Manager de Edificios" pasó a llamarse "Administrador de Edificios".</div>
            <div className={itemClassName}>-Se creó el rol de "Administrador de Casa en Casa" que tiene los mismos permisos que el "Administrador de Edificios" (entrar y usar todos los territorios de Casa en Casa, crear y eliminar edificios y compartir edificios mediantes links) pero también puede asignar y desasignar territorios de Casa en Casa a otros usuarios, compartir edificios por 30 días y ver las estadísticas de Casa en Casa. Lo único que no puede hacer es editar los mapas, que sigue siendo atribución exclusiva del Administrador General.</div>
            <div className={itemClassName}>-Los roles de "Administrador de Edificios" y "Administrador de Casa en Casa" se excluyen mutuamente, o sea no se puede tener ambos a la vez.</div>
            <div className={itemClassName}>-Se creó el rol de "Administrador de Telefónica" que tiene permisos sobre todos los territorios de telefónica, las estadísticas y el reseteo de teléfonos, y la capacidad de asignar y desasignar territorios de telefónica a otros usuarios.</div>
            <div className={itemClassName}>-Se creó el rol de "Administrador de Carritos" para la aplicación en desarrollo.</div>
            <div className={itemClassName}>-El "Administrador General" tiene los mismos permisos que todos los roles juntos y además la posibilidad de modificar usuarios (eliminar, asignar roles, resetear contraseñas), consultar los logs y editar los mapas y la configuración de la congregación.</div>
            <div className={itemClassName}>-Se creó esta página de novedades.</div>
            <div className={itemClassName}>-Ahora el mensaje para compartir edificios contiene los nombres de las calles que forman la manzana debajo de la presentación de cada manzana para facilitar su identificación. También se mejoró el formato del mensaje aprovechando las nuevas características de WhatsApp.</div>
            <div className={itemClassName}>-En el Selector de Territorios de Casa en Casa se agregó la opción "Dónde Estoy - Dirección" para ingresar una dirección y que la aplicación devuelva a qué territorio, manzana y cara pertenece, con un acceso directo para llegar ahí. La anterior opción pasó a llamarse "Dónde Estoy - Mapa", que es una herramienta útil pero problemática porque requiere permisos especiales para que la aplicación use la ubicación del dispositivo del usuario y suele ser bloqueada por el sistema operativo.</div>
            <div className={itemClassName}>-Se agregó la pantalla "Cómo instalar esta aplicación" con instrucciones para instalar la aplicación en Android, iOS y Windows.</div>
            <div className={itemClassName}>-Se reparó el mapa en Telefónica que había dejado de funcionar por una actualización de Google Drive.</div>
            <div className={itemClassName}>-En el portero "simple" de Casa en Casa ahora toda la caja del timbre es clickeable.</div>
        </div>
    </>)
}
