import { BsCalendar, BsPen, BsPerson, BsShare, BsTrash } from 'react-icons/bs';
import { CartParticipantDetailsModal } from './CartParticipantDetailsModal';
import { CartParticipantForm } from './CartParticipantForm';
import { cartServices, helpers } from 'src/services';
import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';
import { domain } from 'src/app-config';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { priorities, typeCartsDoc, typeCartParticipant } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import WhatsappShareButton from 'react-share/lib/WhatsappShareButton';

type propsType = {
    cartDoc: typeCartsDoc;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
}

export const CartParticipant: FC<propsType> = ({ cartDoc, setRefreshCounter }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedParticipant, setSelectedParticipant] = useState<typeCartParticipant | null>(null);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [showFormAdd, setShowFormAdd] = useState(false);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const sample: typeCartParticipant[] = useMemo(() =>
        cartDoc.participants?.filter(p =>
            // !p.wasDeleted
            helpers.NormalizeString(p.name ?? '').includes(helpers.NormalizeString(searchTerm))
            || helpers.NormalizeString(p.lastName ?? '').includes(helpers.NormalizeString(searchTerm))
            || (p.phoneNumber ?? '').includes(searchTerm)
            // || p.availabilities?.some(a =>
            //     helpers.NormalizeString(a.name ?? '').includes(helpers.NormalizeString(searchTerm))
            //     || a.enabledPeriods?.some(h => helpers.NormalizeString(daysOfTheWeekP[h.weekday ?? -1]).includes(helpers.NormalizeString(searchTerm)))
            // )
        ).sort((p1, p2) => (p1.name ?? '').localeCompare(p2.name ?? '')) || []
    , [cartDoc.participants, searchTerm]);

    const handleDelete = (participant: typeCartParticipant) => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar a ${participant.name} ${participant.lastName} y ya no podrá tener nuevas asignaciones en los Carritos. Esta acción no elimina las asignaciones aun en curso.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await cartServices.DeleteParticipant(participant);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    useEffect(() => {
        helpers.GoToTop();
    }, [showFormAdd]);

    return (
        <div className={'container mt-4'}>
            {showDetailsModal && selectedParticipant &&
                <CartParticipantDetailsModal
                    closeModal={() => {
                        setShowDetailsModal(false);
                        setSelectedParticipant(null);
                    }}
                    participant={selectedParticipant}
                />
            }
            <div className={isMobile ? 'mb-4' : 'd-flex align-items-center justify-content-between'}>
                <h3 className={`${isDarkMode ? 'text-white' : ''} ps-3 mb-4 mt-2`}>
                    {showFormAdd ? 'Agregar/Editar Participante' : 'Listado de Participantes'} <BsPerson className={'mb-2'} />
                </h3>
                <div className={'d-flex gap-3'}>
                    {!showFormAdd &&
                        <WhatsappShareButton
                            className={'btn btn-general-blue btn-sm py-2 px-3 mt-2 mb-2'}
                            url={`${domain}/acceso?url=%2Fcarritos-autorregistro`}
                            windowWidth={660}
                            windowHeight={460}
                        >
                            {isMobile ? 'Comp. Invitación' : 'Compartir Invitación'} <BsShare className={'ms-2'} />
                        </WhatsappShareButton>
                    }
                    <button className={`btn btn-general-${showFormAdd ? 'red' : 'blue'} btn-sm py-2 px-3 mb-2 mt-2`}
                        onClick={() => {
                            setShowFormAdd(x => !x);
                            setSelectedParticipant(null);
                        }}
                    >
                        {showFormAdd ? 'Cancelar' : 'Agregar/Editar Participante'}
                    </button>
                </div>
            </div>

            {showFormAdd ?
                <CartParticipantForm
                    cartDoc={cartDoc}
                    goBack={() => {
                        setSelectedParticipant(null);
                        setShowFormAdd(false);
                    }}
                    isEditInit={!!selectedParticipant}
                    isSelfRegistration={false}
                    selectedParticipant={selectedParticipant}
                    setRefreshCounter={setRefreshCounter}
                    setSelectedParticipant={setSelectedParticipant}
                />
                :
                <div>
                    <input type={'text'}
                        className={'form-control mb-4'}
                        placeholder={'Buscar por nombre, apellidos o teléfono...'}
                        value={searchTerm}
                        onChange={e => setSearchTerm(e.target.value)}
                        autoFocus
                    />
                    <div className={'table-responsive mb-5'}>
                        <table className={`table table-bordered shadow ${isDarkMode ? 'table-dark' : ''}`}>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Apellido</th>
                                    <th>Teléfono</th>
                                    <th>Participó</th>
                                    <th>Prioridad</th>
                                    <th className={'text-center'}>Habilitado</th>
                                    <th className={'text-center'}>Disponibilidad</th>
                                    <th className={'text-center'}>Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!sample.length ?
                                    (sample?.map(p =>
                                        <tr key={p.userId}>
                                            <td>{p.name}</td>
                                            <td>{p.lastName}</td>
                                            <td>{p.phoneNumber}</td>
                                            <td>{p.hasParticipatedBefore ? 'Sí' : 'No'}</td>
                                            <td>{priorities.find(x => x.value === p.priority)?.label}</td>
                                            <td>
                                                <div className={`${p.isEnabled ? 'bg-general-blue' : 'bg-general-red'} text-white rounded-1 text-center`}
                                                    style={{ height: '30px', paddingTop: '2px' }}
                                                >
                                                    {p.isEnabled ? 'Sí' : 'No'}
                                                </div>
                                            </td>
                                            <td>
                                                <button className={'btn btn-general-blue btn-sm w-100'}
                                                    onClick={() => {
                                                        setSelectedParticipant(p);
                                                        setShowDetailsModal(true);
                                                    }}
                                                >
                                                    <BsCalendar className={'mb-1 me-2'} /> Ver
                                                </button>
                                            </td>
                                            <td className={'d-flex align-items-center justify-content-center gap-2'}>
                                                <button className={'btn btn-general-blue btn-sm w-50'}
                                                    onClick={() => {
                                                        setSelectedParticipant(p);
                                                        setShowFormAdd(true);
                                                    }}
                                                >
                                                    <BsPen />
                                                </button>
                                                <button className={'btn btn-general-red btn-sm w-50'}
                                                    onClick={() => handleDelete(p)}
                                                >
                                                    <BsTrash />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan={1000}>No hay ninguno para mostrar</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    )
}
