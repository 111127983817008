import { boardServices } from 'src/services';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { hideLoadingModalReducer, showLoadingModalReducer } from 'src/store';
import { typeBoardSection } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile, useGetShowingLoadingModal } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    section: typeBoardSection;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardUploadFile: FC<propsType> = ({ closeModal, section, setCounterReload }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadStatus, setUploadStatus] = useState('');
    const showingLoadingModal = useGetShowingLoadingModal();
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const upload = async () => {
        if (!selectedFile || showingLoadingModal) return;
        dispatch(showLoadingModalReducer());
        setUploadStatus('Subiendo...');
        const success = await boardServices.AddItem(section.id, section.title, 'file', null, null, selectedFile);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setUploadStatus('Subido!');
            setCounterReload(x => x + 1);
            closeModal();
        } else {
            setUploadStatus('Falló. Mirar los logs.');
        }
    }

    return (
        <div className='container'>
            <h5 className={isDarkMode ? 'text-white' : ''}>Subir un archivo PDF</h5>
            <div className={'row'}>
                <div className={'col-9'}>
                    <input type={'file'}
                        id={'formFile'}
                        className={'form-control form-control-sm'}
                        accept={'application/pdf'}
                        onChange={e => {
                            setUploadStatus('');
                            const file = e.target.files?.[0];
                            if (file && file.type === 'application/pdf') {
                                if (file.name.length > 50) {
                                    setUploadStatus('El nombre del archivo es demasiado largo');
                                } else {
                                    setSelectedFile(file);
                                    setUploadStatus('');
                                }
                            } else {
                                setSelectedFile(null);
                                setUploadStatus('Solo se aceptan PDFs por ahora');
                            }
                        }}
                    />
                </div>
                <div className={'col-3'}>
                    {!showingLoadingModal &&
                        <button className={`btn btn-general-blue ${isMobile ? 'btn-sm' : ''} w-100`}
                            onClick={upload}
                            disabled={!selectedFile}
                        >
                            Aceptar
                        </button>
                    }
                </div>
            </div>
            {uploadStatus &&
                <div className={`mt-3 alert ${uploadStatus.includes('Subido!') ? 'alert-success' : 'alert-danger'}`}>
                    {uploadStatus}
                </div>
            }
        </div>
    )
}
