import { H2, TerritoryNumberBlock } from 'src/components/_commons';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useState } from 'react';

export const TelephonicSelectorPage = () => {
    const user = useGetCurrentUser();
    //
    const [showAll, setShowAll] = useState(!user.phoneAssignments?.length && user.isTelephonicAdmin);
    const config = useGetConfig();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    return (
        <>
            <H2 title={'TELEFÓNICA'} />
            {user.isTelephonicAdmin && user.phoneAssignments?.length !== config.numberOfTerritories &&
                <button className={`btn btn-general-red d-block mx-auto mt-4 ${isMobile ? 'w-75' : 'w-25'}`}
                    onClick={() => setShowAll(x => !x)}
                >
                    {showAll ? 'Ver solo los asignados' : 'Ver todos los territorios'}
                </button>
            }
            {showAll ?
                <TerritoryNumberBlock
                    classes={'btn-general-red animate__animated animate__bounce'}
                    territories={Array.from({ length: config.numberOfTerritories }, (_: any, index: number) => index + 1)}
                    url={'/telefonica'}
                    showForecast={false}
                />
                :
                <>
                    {!!user.phoneAssignments?.length ?
                        <TerritoryNumberBlock
                            classes={'btn-general-red animate__animated animate__bounce'}
                            territories={[...user.phoneAssignments].sort((a: number, b: number) => a - b)}
                            url={'/telefonica'}
                            showForecast={false}
                        />
                        :
                        <h3 className={`text-center my-5 ${isDarkMode ? 'text-white' : ''}`} style={{ }}>
                            No hay territorios de Telefónica asignados <br/> Hablar con el grupo de territorios
                        </h3>
                    }
                </>
            }
        </>
    )
}
