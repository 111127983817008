import { Col1, Col2, Col3, Col4 } from '.';
import { Container, Row } from 'react-bootstrap';
import { Dispatch, FC, SetStateAction, useRef } from 'react';
import { hideLoadingModalReducer, showLoadingModalReducer } from 'src/store';
import { telephonicServices } from 'src/services';
import { typeCallingState, typeHousehold, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    household: typeHousehold;
    setAddressToShowInGoogleMaps: Dispatch<SetStateAction<string>>;
    openAlertModalHandler: (title: string, message: string, animation: number) => void;
    territoryNumber: typeTerritoryNumber;
}

export const TelephonicCard: FC<propsType> = ({ household, openAlertModalHandler, setAddressToShowInGoogleMaps, territoryNumber }) => {
    const dispatch = useDispatch();
    const householdCard = useRef<any>();
    const isDarkMode = useGetIsDarkMode();

    const modifyHouseholdHandler = async (householdId: number,
     callingState: typeCallingState, notSubscribed: boolean, isAssigned: boolean|undefined): Promise<void> => {
        dispatch(showLoadingModalReducer());
        notSubscribed = !!notSubscribed;
        isAssigned = !!isAssigned;
        const updatedHousehold: typeHousehold|null = await telephonicServices.ModifyHousehold(territoryNumber, householdId, callingState, notSubscribed, isAssigned);
        dispatch(hideLoadingModalReducer());
        if (!updatedHousehold) {
            openAlertModalHandler("Algo falló al modificar", "", 2);
        }
    }

    return (
        <div
            className={`card ${household.isAssigned ? 'bg-grey bg-opacity bg-gradient' : isDarkMode ? 'bg-dark text-white' : ''} animate__animated animate__bounceInLeft animate__faster`}
            key={household.householdId}
            ref={householdCard}
            style={{
                border: '1px solid gray',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                marginBottom: '50px'
            }}
        >   
            <Container fluid={'lg'}>

                <Row style={{ margin: '0 25px', paddingBottom: '12px', paddingTop: '15px' }}>

                    <Col1
                        household={household}
                        setAddressToShowInGoogleMaps={setAddressToShowInGoogleMaps}
                        territoryNumber={territoryNumber}
                    />

                    <Col2
                        household={household}
                        card={householdCard}
                    />

                    <Col3
                        household={household}
                        modifyHouseholdHandler={modifyHouseholdHandler}
                    />

                    <Col4
                        household={household}
                        modifyHouseholdHandler={modifyHouseholdHandler}
                    />

                </Row>
            </Container>
        </div>
    )
}
