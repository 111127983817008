import { Card } from 'react-bootstrap';
import { FC, useMemo, useState } from 'react';
import { houseToHouseServices } from 'src/services';
import { Hr, Loading, UsersSelector } from 'src/components/_commons';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeUser } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    users: typeUser[] | null;
}

export const HTHAssignmentsByUser: FC<propsType> = ({ users }) => {
    const [query, setQuery] = useState('');

    const usersToShow: typeUser[] = useMemo(() =>
        users?.filter(x =>
            x.email.toLowerCase().includes(query.toLowerCase())
            || x.name?.toLowerCase().includes(query.toLowerCase())
            || x.lastName?.toLowerCase().includes(query.toLowerCase())
            || `${x.name ?? ''} ${x.lastName ?? ''}`.toLowerCase().includes(query.toLowerCase())
            || `${x.lastName ?? ''} ${x.name ?? ''}`.toLowerCase().includes(query.toLowerCase())
        ) ?? []
    , [query, users]);

    return (
        <div>
            <div className={'mt-5'}>
                {!!users?.length &&
                    <UsersSelector
                        setQuery={setQuery}
                        usersToShow={usersToShow}
                    />
                }
            </div>
            <div className={'container'}>
                <div className={'row justify-content-center'}>
                    {usersToShow?.sort((u1, u2) => u1.email.localeCompare(u2.email)).map(u =>
                        <User key={u.id}
                            u={u}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

type propsType1 = {
    u: typeUser;
}

const User: FC<propsType1> = ({ u }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [toAssign, setToAssign] = useState(0);
    const [toUnassign, setToUnassign] = useState(0);
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const showError = () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'alert',
            title: 'Error',
            message: 'Algo falló al cambiar las asignaciones',
            animation: 2
        }));
    }

    const assign = async (): Promise<void> => {
        setIsLoading(true);
        const success = await houseToHouseServices.AssignTerritory(u, toAssign, null, false);
        setIsLoading(false);
        if (!success) {
            showError();
            return;
        }
        setToAssign(0);
    }

    const unassign = async (): Promise<void> => {
        setIsLoading(true);
        const success = await houseToHouseServices.AssignTerritory(u, null, toUnassign, false);
        setIsLoading(false);
        if (!success) {
            showError();
            return;
        }
        setToUnassign(0);
    }

    const unassignAll = (): void => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: '¿Desasignar todos?',
            message: `Se van a desasignar todos los territorios de Casa en Casa de ${u.email}`,
            execution: async () => {
                setIsLoading(true);
                const success = await houseToHouseServices.AssignTerritory(u, null, null, true);
                setIsLoading(false);
                if (!success) {
                    showError();
                    return;
                }
                setToAssign(0);
            }
        }));
    }

    return (
        <div className={'col-auto'}>

            <Card className={isDarkMode ? 'bg-dark text-white' : ''}
                style={{
                    backgroundColor: '#f6f6f8',
                    margin: '60px auto 0 auto',
                    width: isMobile ? '95%': '400px'
                }}
            >
                <Card.Body style={{ padding: '20px' }}>

                    <Card.Title className={'text-center p-4'}>
                        <div style={{ fontSize: isMobile ? '1.2rem' : '1.6rem' }}>
                            {u.name} {u.lastName}
                        </div>
                        <div>{u.email}</div>
                    </Card.Title>

                    <Hr />

                    <div style={{ fontWeight: 500, fontSize: '1.2rem', textAlign: 'center' }}>
                        Territorios asignados: &nbsp;
                        {!!u.hthAssignments?.length ?
                            u.hthAssignments.sort((a, b) => a - b).map(territoryNumber => (
                                <span key={territoryNumber} className={'d-inline-block'}>
                                    {territoryNumber} &nbsp;
                                </span>
                            ))
                            :
                            <span> ninguno </span>
                        }
                    </div>

                    {isLoading ?
                        <Loading mt={'30px'} />
                        :
                        <div className={'text-center p-2'}>
                            <div style={{ marginTop: '12px' }}>
                                <input type={'number'}
                                    className={'form-control d-inline-block'}
                                    style={{ width: '70px' }}
                                    min={1}
                                    value={toAssign || ''}
                                    onChange={e => setToAssign(parseInt(e.target.value) || 0)}
                                    onKeyDown={e => e.key === 'Enter' && !!toAssign ? assign() : null}
                                />
                                &nbsp;&nbsp;
                                <button className={'btn btn-general-blue'}
                                    style={{ width: '102px' }}
                                    onClick={assign}
                                    disabled={!toAssign}
                                >
                                    &nbsp; Asignar &nbsp;
                                </button>

                            </div>

                            <div style={{ marginTop: '12px' }}>
                                <input type={'number'}
                                    className={'form-control d-inline-block'}
                                    style={{ width: '70px' }}
                                    min={1}
                                    value={toUnassign || ''}
                                    onChange={e => setToUnassign(parseInt(e.target.value) || 0)}
                                    onKeyDown={e => e.key === 'Enter' && !!toUnassign ? unassign() : null}
                                />
                                &nbsp;&nbsp;
                                <button className={'btn btn-general-blue'}
                                    onClick={unassign}
                                    disabled={!toUnassign}
                                >
                                    Desasignar
                                </button>
                            </div>

                            {!!u.hthAssignments?.length &&
                                <div style={{ marginTop: '22px' }}>
                                    <button className={'btn btn-general-blue'}
                                        onClick={unassignAll}
                                    >
                                        Desasignar todos
                                    </button>
                                </div>
                            }
                        </div>
                    }
                </Card.Body>
            </Card>
        </div>
    )
}
