import { api } from './_apiInterface';
import { pointer } from 'src/app-config';
import { typeNotificationsDoc } from '@monorepo/models';

class NotificationServices {
    private _base = pointer.notification;
    //
    GetDoc = async (): Promise<typeNotificationsDoc | null> => {
        const data = await api.SendRequest(this._base, 'GET');
        return data?.notificationDoc ?? null;
    }
    SetOptions = async (notificationDoc: typeNotificationsDoc): Promise<boolean> => {
        const data = await api.SendRequest(this._base, 'PATCH', { notificationDoc });
        return !!data?.success;
    }
}

export const notificationServices = new NotificationServices();
