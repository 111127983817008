import { BsArrowBarDown } from 'react-icons/bs';
import { FC, useState } from 'react';
import { generalBlue } from 'src/app-config';
import { Hr } from 'src/components/_commons';
import { HTHAddBuilding, HTHBuildingItem, HTHShareBuildingButton } from '..';
import { typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useGetCurrentUser, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    currentFace: typePolygon;
    territoryNumber: typeTerritoryNumber;
}

export const HTHBuildings: FC<propsType> = ({ currentFace, territoryNumber }) => {
    const [show, setShow] = useState(false);
    const isMobile = useGetIsMobile();
    const user = useGetCurrentUser();

    return (
        <div style={{ marginTop: '100px', marginBottom: '50px' }}>

            <h1 className={'py-3 text-center text-white d-block mx-auto pointer rounded-3'}
                style={{
                    backgroundColor: !!currentFace.buildings?.length ? generalBlue : 'gray',
                    marginBottom: '50px',
                    width: isMobile ? '100%' : '90%'
                }}
                onClick={() => setShow(x => !x)}
            >
                {!!currentFace.buildings?.length ? "EDIFICIOS" : "No hay edificios en esta cara"}
                &nbsp;<BsArrowBarDown size={isMobile ? '2rem' : '1.4rem'} />
            </h1>

            {show && <>
                {user.isBuildingsAdmin && 
                    <HTHAddBuilding
                        currentFace={currentFace}
                        territoryNumber={territoryNumber}
                    />
                }

                <Hr classes={'mx-auto my-4'} styles={{ maxWidth: '500px' }} />

                {!!currentFace.buildings?.length && <>
                    {currentFace.buildings.sort((b1, b2) => b1.streetNumber - b2.streetNumber).map(building =>
                        <HTHBuildingItem key={building.streetNumber}
                            building={building}
                            currentFace={currentFace}
                            territoryNumber={territoryNumber}
                        />
                    )}

                    <Hr />

                    <HTHShareBuildingButton
                        currentFace={currentFace}
                        territoryNumber={territoryNumber}
                    />
                </>}
            </>}
        </div>
    )
}
