import { GeoLocationModal } from './subcomponents/GeoLocationModal';
import { H2, TerritoryNumberBlock } from 'src/components/_commons';
import { ManualLocationModal } from './subcomponents/ManualLocationModal';
import { useGetConfig, useGetCurrentUser, useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';
import { useState } from 'react';

export const HTHSelectorPage = () => {
    const user = useGetCurrentUser();
    //
    const [showAll, setShowAll] = useState(!user.hthAssignments?.length && user.isBuildingsAdmin);
    const [showGeolocationModal, setShowGeolocationModal] = useState(false);
    const [showManualLocationModal, setShowManualLocationModal] = useState(false);
    const config = useGetConfig();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    return (
        <>
            <H2 title={'CASA EN CASA'} />
            {showManualLocationModal &&
                <ManualLocationModal
                    closeGeolocationModalHandler={() => setShowManualLocationModal(false)}
                />
            }
            {showGeolocationModal &&
                <GeoLocationModal
                    closeModal={() => setShowGeolocationModal(false)}
                />
            }
            <div className={'row mt-4'}>
                <div className={`col-md-3 offset-md-3 ${isMobile ? 'd-flex justify-content-center' : ''} mb-2`}>
                    <button className={`btn btn-general-blue ${isMobile ? 'w-75 mb-3' : 'w-100'}`}
                        onClick={() => setShowManualLocationModal(true)}
                    >
                        Dónde Estoy - Dirección
                    </button>
                </div>
                <div className={`col-md-3 ${isMobile ? 'd-flex justify-content-center' : ''}`}>
                    <button className={`btn btn-general-blue ${isMobile ? 'w-75' : 'w-100'}`}
                        onClick={() => setShowGeolocationModal(true)}
                    >
                        Dónde Estoy - Mapa
                    </button>
                </div>
            </div>
            {user.isBuildingsAdmin &&
                !(!!user.hthAssignments?.length && user.hthAssignments.length === config.numberOfTerritories) &&
                <button className={`btn btn-general-blue d-block mx-auto my-4 ${isMobile ? 'w-75' : 'w-25'}`}
                    onClick={() => setShowAll(x => !x)}
                >
                    {showAll ? 'Ver solo los asignados' : 'Ver todos los territorios'}
                </button>
            }
            {!!user.hthAssignments?.length ?
                user.hthAssignments.length === config.numberOfTerritories &&
                    <h3 className={`text-center ${isDarkMode ? 'text-white' : ''}`}>
                        Este usuario tiene asignados todos los territorios de Casa en Casa
                    </h3>
                :
                !showAll &&
                    <h3 className={`text-center ${isDarkMode ? 'text-white' : ''} mt-3`}>
                        No hay territorios de Casa en Casa asignados <br/> Hablar con el grupo de territorios
                    </h3>
            }
            {(showAll || !!user.hthAssignments) &&
                <TerritoryNumberBlock
                    classes={'btn-general-blue animate__animated animate__rubberBand'}
                    showForecast={true}
                    territories={showAll ?
                        Array.from({ length: config.numberOfTerritories }, (_, i) => i + 1)
                        :
                        [...user.hthAssignments ?? []].sort((a, b) => a - b)
                    }
                    url={'/casa-en-casa'}
                />
            }
        </>
    )
}
