import { ConfigCreateHTHTerritories } from './subcomponents/ConfigCreateHTHTerritories';
import { ConfigSendInvitationNewCongregation } from './subcomponents/ConfigSendInvitationNewCongregation';
import { configServices } from 'src/services';
import { ConfigSetCongregationName } from './subcomponents/ConfigSetCongregationName';
import { generalHelpers } from '@monorepo/helpers';
import { H2 } from 'src/components/_commons';
import { hideLoadingModalReducer, setConfigurationReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetCurrentUser } from 'src/custom-hooks';
import { useNavigate } from 'react-router';
import { useState } from 'react';

const btnClasses = 'btn btn-general-blue btn-size12 d-block mx-auto mt-2 mb-0';

const btnStyles = { width: '400px', minHeight: '80px' };

export const AdminsConfig = () => {
    const [showCreateHthTerritories, setShowCreateHthTerritories] = useState(false);
    const [showInvitationForNewCongregation, setShowInvitationForNewCongregation] = useState(false);
    const [showSetCongregationName, setShowSetCongregationName] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    const downloadBackupHandler = async (withFiles: boolean, all: boolean) => {
        dispatch(showLoadingModalReducer());
        const success = await configServices.DownloadDbBackup(withFiles, all);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                mode: 'alert',
                title: "Algo falló",
                message: "No se pudo generar un Backup de la base de datos. Ver los Logs.",
                animation: 2
            }));
        }
        if (!all) {
            dispatch(setConfigurationReducer({ ...config, dbBackupLastDate: generalHelpers.GetCurrentLocalDate() }));
        }
    }

    return (<>
    
        <H2 title={'CONFIGURACIÓN'} />

        {!showCreateHthTerritories && !showInvitationForNewCongregation && !showSetCongregationName && <>

            <button className={btnClasses}
                style={btnStyles} onClick={() => setShowSetCongregationName(true)}
            >
                {!!config.name ? "Cambiar el nombre de la Congregación" : "Cargar el nombre de la Congregación"}
            </button>

            {!!config.name && !config.numberOfTerritories &&
                <button className={btnClasses}
                    style={btnStyles}
                    onClick={() => setShowCreateHthTerritories(true)}
                >
                    Crear Territorios de Casa en Casa
                </button>
            }

            {/* <h5 className={isDarkMode ? 'text-white' : ''}> Establecer localidad </h5> */}

            {/* <h5 className={isDarkMode ? 'text-white' : ''}> Duración de accesos: 3 meses </h5> */}

            <button className={btnClasses}
                style={btnStyles}
                onClick={() => downloadBackupHandler(true, false)}
            >
                Generar y descargar una copia de seguridad de la base de datos incluyendo archivos del Tablero (
                    {config.dbBackupLastDate ? `última: ${config.dbBackupLastDate}` : "nunca se hizo una"})
            </button>

            <button className={btnClasses}
                style={btnStyles}
                onClick={() => downloadBackupHandler(false, false)}
            >
                Generar y descargar una copia de seguridad de la base de datos sin incluir archivos del Tablero (
                    {config.dbBackupLastDate ? `última: ${config.dbBackupLastDate}` : "nunca se hizo una"})
            </button>

            {user.isSuperAdmin &&
                <>
                <button className={btnClasses}
                    style={btnStyles}
                    onClick={() => downloadBackupHandler(true, true)}
                >
                    Generar y descargar una copia de seguridad de la base de datos de todas las Congregaciones incluyendo archivos del Tablero
                </button>
                <button className={btnClasses}
                    style={btnStyles}
                    onClick={() => downloadBackupHandler(false, true)}
                >
                    Generar y descargar una copia de seguridad de la base de datos de todas las Congregaciones sin incluir archivos del Tablero
                </button>
                <button className={btnClasses}
                    style={btnStyles}
                    onClick={() => setShowInvitationForNewCongregation(true)}
                >
                    Enviar invitación para Congregación nueva
                </button>
                <button className={btnClasses}
                    style={btnStyles}
                    onClick={() => navigate('/gmail')}
                >
                    Renovar credenciales de Gmail
                </button>
            </>}
        </>}
        {showCreateHthTerritories &&
            <ConfigCreateHTHTerritories
                setShowCreateHthTerritories={setShowCreateHthTerritories}
            />
        }
        {showSetCongregationName &&
            <ConfigSetCongregationName
                setShowSetCongregationName={setShowSetCongregationName}
            />
        }
        {showInvitationForNewCongregation &&
            <ConfigSendInvitationNewCongregation
                setShowInvitationForNewCongregation={setShowInvitationForNewCongregation}
            />
        }
    </>)
}
