import { BsTrash } from 'react-icons/bs';
import { cartServices, helpers } from 'src/services';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { typeCartPoint } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode } from 'src/custom-hooks';
import { useState, useEffect, FC, Dispatch, SetStateAction, useMemo } from 'react';

type propsType = {
    pointToEdit: typeCartPoint | null;
    setPointToEdit: Dispatch<SetStateAction<typeCartPoint | null>>;
    setRefreshCounter: Dispatch<SetStateAction<number>>;
    setShowForm: Dispatch<SetStateAction<boolean>>;
}

export const CartPointCrud: FC<propsType> = ({ pointToEdit, setPointToEdit, setRefreshCounter, setShowForm }) => {
    const [address, setAddress] = useState(pointToEdit?.address ?? '');
    const [name, setName] = useState(pointToEdit?.name ?? '');
    const [pickupCartPoint, setPickupCartPoint] = useState(pointToEdit?.pickupCartPoint ?? '');
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();

    const isValid: boolean = useMemo(() =>
        !!name && !!address && !!pickupCartPoint
    , [address, name, pickupCartPoint]);

    const handleCreate = async () => {
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a crear un nuevo Punto: '${name}' en ${address}; se guarda en ${pickupCartPoint}`,
            execution: async () => {
                const point: typeCartPoint = {
                    address,
                    enabledPeriods: [],
                    id: 0,
                    name,
                    pickupCartPoint,
                    wasDeleted: false
                };
                dispatch(showLoadingModalReducer());
                const success = await cartServices.AddPoint(point);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    setShowForm(false);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo agregar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const handleUpdate = async () => {
        if (!pointToEdit) return;
        const point: typeCartPoint = {
            address,
            enabledPeriods: [],
            id: pointToEdit?.id,
            name,
            pickupCartPoint,
            wasDeleted: false
        };
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a editar el punto: ${point.name} de ${point.address}; se guarda en ${point.pickupCartPoint}`,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await cartServices.UpdatePoint(point);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    setShowForm(false);
                    setPointToEdit(null);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo editar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    const handleDelete = () => {
        if (!pointToEdit) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el punto '${name}'. Esta acción es irreversible.`,
            animation: 3,
            execution: async () => {
                dispatch(showLoadingModalReducer());
                const success = await cartServices.DeletePoint(pointToEdit);
                dispatch(hideLoadingModalReducer());
                if (success) {
                    setRefreshCounter(x => x + 1);
                    setShowForm(false);
                } else {
                    dispatch(setValuesAndOpenAlertModalReducer({
                        title: 'Error',
                        message: 'No se pudo eliminar',
                        animation: 2,
                        mode: 'alert'
                    }));
                }
            }
        }));
    }

    useEffect(() => {
        helpers.GoToTop();
    }, []);

    return (
        <form className='container mt-3'
            style={{ maxWidth: '650px' }}
            onSubmit={e => {
                e.preventDefault();
                if (!isValid) return;
                if (pointToEdit) {
                    handleUpdate();
                } else {
                    handleCreate();
                }
            }}
        >
            <div className='mb-3'>
                <label htmlFor={'name'} className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                    Nombre del Punto
                </label>
                <input
                    type={'text'}
                    id={'name'}
                    className={'form-control'}
                    placeholder={'Nombre del punto'}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    required
                />
            </div>
            <div className={'mb-3'}>
                <label htmlFor={'address'} className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                    Dirección
                </label>
                <input
                    type={'text'}
                    id={'address'}
                    className={'form-control'}
                    placeholder={'Dirección exacta del Punto'}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    required
                />
            </div>
            <div className={'mb-3'}>
                <label htmlFor={'pickupCartPoint'} className={`form-label ${isDarkMode ? 'text-white' : ''}`}>
                    Punto de retiro
                </label>
                <input type={'text'}
                    id={'pickupCartPoint'}
                    className={'form-control'}
                    placeholder={'Direccion de retiro del carrito'}
                    value={pickupCartPoint}
                    onChange={e => setPickupCartPoint(e.target.value)}
                    required
                />
            </div>
            <div className={'d-flex justify-content-center mb-4 mt-4 gap-2'}>
                <button type={'submit'}
                    className={'btn btn-general-blue'}
                    disabled={!isValid}
                >
                    {pointToEdit ? 'Guardar Cambios' : 'Agregar Punto'}
                </button>
                {pointToEdit &&
                    <button type={'button'}
                        className={'btn btn-general-red btn-sm px-3'}
                        onClick={handleDelete}
                    >
                        Eliminar <BsTrash />
                    </button>
                }
                <button type={'button'}
                    className={'btn btn-general-red'}
                    onClick={() => {
                        setShowForm(false);
                        setPointToEdit(null);
                    }}
                >
                    Cancelar
                </button>
            </div>
        </form>
    )
}
