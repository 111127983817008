import { domain } from 'src/app-config';
import { httpHeaders } from '@monorepo/helpers';
import { localStorageHelper } from 'src/services';
import { typeMethod, typeResponseData } from '@monorepo/models';
import axios from 'axios';

class Api {
    private GetHeaders = (token: string | null = null, recaptchaToken: string | null = null, isFile = false) => {
        const headers: Record<string, string> = {};
        headers[httpHeaders.accept] = httpHeaders.contentTypeApplicationJson;
        headers[httpHeaders.authorizationToken] = token || localStorageHelper.GetToken() || '';
        headers[httpHeaders.contentType] = isFile ? httpHeaders.contentTypeMultipartFormData : httpHeaders.contentTypeApplicationJson;
        headers[httpHeaders.recaptchaToken] = recaptchaToken ?? '';
        headers[httpHeaders.requestDomain] = domain;
        return headers;
    }
    async SendRequest(url: string, method: typeMethod, body: Record<string, any> | null = null, sendCredentials = true, token: string | null = null, recaptchaToken: string | null = null): Promise<typeResponseData | null> {
        const options: RequestInit = {
            method,
            headers: this.GetHeaders(token, recaptchaToken)
        };
        if (method !== 'GET' && body) {
            options.body = JSON.stringify(body)
        }
        try {
            if (sendCredentials && !localStorageHelper.GetToken() && !token) throw new Error('No autenticado');
            const response = await fetch(url, options);
            const data = await response.json() as typeResponseData | undefined;
            return data ?? null;
        } catch (error) {
            console.log(error);
            return null;
        }
    }
    async SendRequestDownloadFile(url: string, openingNewTab: boolean, fileName?: string): Promise<boolean> {
        try {
            if (!localStorageHelper.GetToken()) throw new Error('No autenticado');
            const response = await fetch(url, {
                method: 'GET',
                headers: this.GetHeaders()
            });
            if (!response) return false;
            const blob = await response.blob();
            const objectUrl = window.URL.createObjectURL(blob);
            if (openingNewTab) {
                window.open(objectUrl, '_blank');
            } else {
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = fileName ?? 'archivo.pdf';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
            window.URL.revokeObjectURL(objectUrl);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    async SendRequestSendFile(url: string, file: File): Promise<boolean> {
        try {
            if (!localStorageHelper.GetToken()) throw new Error('No autenticado');
            const formData = new FormData();
            formData.append('pdf', file);
            const response = await axios.post(url, formData, {
                headers: this.GetHeaders(null, null, true)
            });
            const data = response.data as typeResponseData | null;
            return !!data?.success;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}

export const api = new Api();
