import { BsTrash } from 'react-icons/bs';
import { daysOfTheWeekP } from 'src/app-config';
import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { typeCartAvailability, typeCartsDoc, typeCartPeriod, typeCartPoint } from '@monorepo/models';
import { useGetIsDarkMode, useGetIsMobile } from 'src/custom-hooks';

type propsType = {
    cartDoc: typeCartsDoc;
    availabilities: typeCartAvailability[];
    setAvailabilities: Dispatch<SetStateAction<typeCartAvailability[]>>;
}

export const CartParticipantFormAvailability: FC<propsType> = ({ cartDoc, availabilities, setAvailabilities }) => {
    const [allPeriods, setAllPeriods] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState<typeCartPeriod | null>(null);
    const [selectedPoint, setSelectedPoint] = useState<typeCartPoint | null>(null);
    const [selectedWeekday, setSelectedWeekday] = useState<number | null>(null);
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const isValid: boolean = useMemo(() =>
        !!selectedPoint && selectedWeekday !== null && (!!selectedPeriod || allPeriods)
    , [allPeriods, selectedPeriod, selectedPoint, selectedWeekday]);

    const addAvailability = () => {
        if (!isValid || !selectedPoint || (!allPeriods && !selectedPeriod) || selectedWeekday === null) return;
        if (availabilities.some(a => a.pointId === selectedPoint.id && a.weekday === selectedWeekday &&
            (allPeriods || ((selectedPeriod?.startHour ?? 0) * 60 + (selectedPeriod?.startMinutes ?? 0) < (a.endHour ?? 0) * 60 + (a.endMinutes ?? 0) && (selectedPeriod?.endHour ?? 0) * 60 + (selectedPeriod?.endMinutes ?? 0) > (a.startHour ?? 0) * 60 + (a.startMinutes ?? 0))))
        ) {
            alert('El horario ya existe para este día en este punto');
            return;
        }
        if (allPeriods) {
            let avs: typeCartAvailability[] = [];
            for (let i = 0; i < selectedPoint.enabledPeriods.length; i++) {
                const period = selectedPoint.enabledPeriods[i];
                avs.push({
                    ...selectedPoint,
                    ...period,
                    id: (Date.now() + i * 100),
                    pointId: selectedPoint.id,
                    weekday: selectedWeekday
                });
            }
            setAvailabilities(x => [...x, ...avs]);
        } else {
            const availability: typeCartAvailability = {
                ...selectedPoint,
                ...selectedPeriod,
                id: Date.now(),
                pointId: selectedPoint.id,
                weekday: selectedWeekday
            }
            setAvailabilities(x => [...x, availability]);
        }
        setAllPeriods(false);
        setSelectedPeriod(null);
        setSelectedPoint(null);
        setSelectedWeekday(null);
    }

    return (
        <div className={`row ${isDarkMode ? 'text-white border-light' : ''}`}>
            <div className={'col-md-6'}>
                <div className={'mb-2'}>
                    <h5 className={'text-center my-3'}>
                        DISPONIBILIDAD
                    </h5>
                    <label className={'form-label'}>
                        Punto:
                    </label>
                    <div className={'d-flex flex-wrap gap-1'}>
                        {cartDoc.points
                            ?.filter(p => !p.wasDeleted)
                            .filter(p => !!p.enabledPeriods.length)
                            .map(p =>
                            <button key={p.id}
                                type={'button'}
                                className={`btn btn-outline-general-blue me-2 ${selectedPoint?.id === p.id ? 'btn-general-blue' : ''}`}
                                onClick={() => setSelectedPoint(p)}
                            >
                                {p.name}
                            </button>
                        )}
                    </div>
                </div>

                <div className={'mb-3'}>
                    <label className={'form-label'}>Día:</label>
                    <div className={'d-flex flex-wrap gap-1'}>
                        {Array.from({ length: 7 }, (_, i) => i).filter(i => !!i).map(d =>
                            <button key={d}
                                type={'button'}
                                className={`btn btn-outline-general-blue me-2 ${selectedWeekday === d ? 'btn-general-blue' : ''}`}
                                onClick={() => setSelectedWeekday(d)}>
                                {daysOfTheWeekP[d]}
                            </button>
                        )}
                    </div>
                </div>

                <div className={'mb-3'}>
                    <label className={'form-label'}>
                        Horario:
                    </label>
                    <div className={'d-flex flex-wrap gap-1'}>
                        <button type={'button'}
                            className={`btn btn-outline-general-blue ${allPeriods ? 'btn-general-blue' : ''}`}
                            onClick={() => {
                                setSelectedPeriod(null);
                                setAllPeriods(true);
                            }}
                        >
                            Todos
                        </button>
                        {selectedPoint?.enabledPeriods
                            .sort((p1, p2) => p1.startHour - p2.startHour || p1.startMinutes - p2.startMinutes)
                            .map((horario, index) =>
                            <button key={index}
                                type={'button'}
                                className={`btn btn-outline-general-blue me-2 ${
                                    selectedPeriod?.id === horario.id ? 'btn-general-blue' : ''
                                }`}
                                onClick={() => {
                                    setAllPeriods(false);
                                    setSelectedPeriod(horario);
                                }}
                            >
                                {generalHelpers.GetTimeFromPeriodObject(horario)}
                            </button>
                        )}
                    </div>
                </div>

                <div className={'d-flex flex-column gap-2 w-100 px-4 mt-4'}>
                    <button type={'button'}
                        className={'btn btn-general-blue mb-3 text-center'}
                        onClick={addAvailability}
                        disabled={!isValid}
                    >
                        Agregar Disponibilidad
                    </button>
                </div>
            </div>

            <div className={`col-md-6 ${isMobile ? 'mt-4' : ''}`}>
                <div className={'d-flex flex-column gap-2 w-100 px-4'}
                    style={{ maxHeight: '400px', overflowY: 'auto' }}
                >
                    {!availabilities.length &&
                        <div className={'text-center card shadow rounded p-4'}>
                            Seleccionar Punto, Día y Horario para agregar Disponibilidad
                        </div>
                    }
                    {availabilities
                        ?.sort((a1, a2) => a1.weekday - a2.weekday || (a1.startHour ?? 0) - (a2.startHour ?? 0) || (a1.startMinutes ?? 0) - (a2.startMinutes ?? 0))
                        .map(a =>
                        <li key={a.id} className={'border p-2 rounded shadow list-group-item'}>
                            <article className={'d-flex flex-wrap flex-column gap-2 p-2'}>
                                <div className={`d-flex justify-content-between align-items-center ${isDarkMode ? '' : 'bg-light'} border rounded p-2`}>
                                    <span><span className={'fw-bolder me-2'}>{a.name}</span>{isMobile && <br />} {daysOfTheWeekP[a.weekday]}: {generalHelpers.GetTimeFromPeriodObject(a as typeCartPeriod)}</span>
                                    <div>
                                        <button type={'button'}
                                            className={'btn btn-general-red btn-sm text-nowrap m-auto'}
                                            onClick={() => setAvailabilities(x => x.filter(y => y.id !== a.id))}
                                        >
                                            Eliminar
                                            <BsTrash className={'ms-2'} />
                                        </button>
                                    </div>
                                </div>
                            </article>
                        </li>
                    )}
                </div>
            </div>
        </div>
    )
}
