import { api } from './_apiInterface';
import { localStorageHelper } from '..';
import { pointer } from 'src/app-config';
import { typeConfigsDoc, typeResponseData, typeUser, unauthenticatedConfig } from '@monorepo/models';

class UserServices {
    private Base = pointer.user;
    async ChangeEmail(newEmail: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/token`, 'PUT', { newEmail });
        return data;
    }
    async ChangePassword(team: number, psw: string | null, newPsw: string, id: string | null): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/token`, 'PUT', { team, psw, newPsw, id }, false);
        if (data && data.success && data.newToken) localStorageHelper.SetToken(data.newToken);
        return data;
    }
    async ChangePswOtherUser(email: string): Promise<[string, boolean]|null> {
        const data = await api.SendRequest(`${this.Base}/token`, 'PATCH', { email });
        if (!data || !data.newPassword) return null;
        data.emailSuccess = !!data.emailSuccess;
        return [data.newPassword, data.emailSuccess];
    }
    async DeleteMyAccount(): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'DELETE', { myUser: true });
        return !!data?.success;
    }
    async Delete(userId: number): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'DELETE', { userId });
        return !!data?.success;
    }
    async Edit(email: string, isActive: boolean, roles: number[]): Promise<typeUser | null> {
        const data = await api.SendRequest(this.Base, 'PUT', { email, isActive, roles });
        if (!data || !data.success || !data.user) return null;
        return data.user;
    }
    async GetEmailByEmailLink(congregation: string, id: string): Promise<string | null> {
        const data = await api.SendRequest(`${this.Base}/recovery?id=${id}&team=${congregation}`, 'GET', null, false);
        if (!data || !data.success || !data.email) return null;
        return data.email;
    }
    async GetUserByToken(token: string = ''): Promise<{ user: typeUser | null; config: typeConfigsDoc | null; logout: boolean; }> {
        if (!localStorageHelper.GetToken() && !token) {
            return {
                user: null,
                config: null,
                logout: true
            };
        }
        const data = await api.SendRequest(this.Base, 'GET', null, false, token);
        if (!data) {
            return {
                user: null,
                config: null,
                logout: false
            };
        }
        if (!data.config) {
            data.config = unauthenticatedConfig;
        }
        if (!data.success || !data.user) {
            if (!data.success) {
                localStorageHelper.RemoveToken();
            }
            return {
                user: null,
                config: data.config,
                logout: true
            };
        }
        localStorageHelper.SetUser(data.user);
        return {
            user: data.user,
            config: data.config,
            logout: false
        };
    }
    async GetAll(): Promise<typeUser[] | null> {
        const data = await api.SendRequest(`${this.Base}/all`, 'GET', null);
        if (!data || !data.success || !data.users) return null;
        return data.users;
    }
    async Login(email: string, password: string, recaptchaToken: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/token`, 'POST', { email, password }, false, null, recaptchaToken);
        if (!!data?.success && !!data?.newToken) localStorageHelper.SetToken(data.newToken);
        return data;
    }
    async LoginByPhone(tokenId: string, recaptchaToken: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/token/phone`, 'POST', { tokenId }, false, null, recaptchaToken);
        if (!!data?.success && !!data?.newToken) localStorageHelper.SetToken(data.newToken);
        return data;
    }
    async LogoutAll(): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/token`, 'DELETE');
        if (!data || !data.success || !data.newToken) return false;
        localStorageHelper.SetToken(data.newToken);
        return true;
    }
    async RegisterUser(team: number, email: string, invitationId: string, password: string, recaptchaToken: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(this.Base, 'POST', { email, invitationId, password, team }, false, null, recaptchaToken);
        return data;
    }
    async RegisterAdmins(email: string, password: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(this.Base, 'POST', { email, password }, false);
        return data;
    }
    async SendEmailOTP(email: string, recaptchaToken: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/token/email`, 'POST', { email }, false, null, recaptchaToken);
        return data;
    }
    async SendLinkToRecoverAccount(email: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(this.Base, 'PATCH', { email }, false);
        return data;
    }
    async SetUserPersonalData(userId: number, userEmail: string, name: string, lastName: string, phoneNumber: string): Promise<typeResponseData | null> {
        const data = await api.SendRequest(`${this.Base}/personal-data`, 'PUT', { lastName, name, phoneNumber, userEmail, userId });
        return data;
    }
}

export const userServices = new UserServices();
