import { FC, useState } from 'react';
import { typeHTHTerritory } from '@monorepo/models';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { googleMapConfig } from 'src/app-config';
import { useGetIsMobile, useOnEscapeKey } from 'src/custom-hooks';
import { hthMapStyle, Loading } from 'src/components/_commons';
import { HTHPolygonComponent } from 'src/components/house-to-house-page';

type propsType = {
    close: () => void;
    hthTerritory: typeHTHTerritory;
}

export const HTHRestructuringMap: FC<propsType> = ({ close, hthTerritory }) => {
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const mapLoader = useJsApiLoader(googleMapConfig);
    const isMobile = useGetIsMobile();

    useOnEscapeKey(close);

    if (!mapLoader.isLoaded) return <Loading />;

    return (
        <div className={'offcanvas offcanvas-top show'} style={{ height: '90vh' }} tabIndex={-1}>
            <div className={'offcanvas-header bg-general-blue'}>
                <h3 className={'text-white'}>
                    Territorio {hthTerritory.territoryNumber} en el Mapa
                </h3>
                <button className={'btn-close text-reset'} onClick={close}></button>
            </div>
            <div className={'offcanvas-body bg-dark'}>
                <GoogleMap
                    center={hthTerritory.map.centerCoords}
                    id={googleMapConfig.id}
                    mapContainerClassName={isMobile ? 'position-absolute' : 'd-block m-auto'}
                    mapContainerStyle={{
                        height: isMobile ? '600px' : '70vh',
                        width: isMobile ? '100%' : '90%'
                    }}
                    onLoad={mapInstance => setMap(mapInstance)}
                    options={{
                        center: hthTerritory.map.centerCoords,
                        disableDefaultUI: false,
                        draggable: true,
                        fullscreenControl: true,
                        fullscreenControlOptions: { position: google.maps.ControlPosition.RIGHT_CENTER },
                        isFractionalZoomEnabled: true,
                        mapTypeControl: false,
                        minZoom: 8,
                        panControl: true,
                        streetViewControl: true,
                        styles: hthMapStyle,
                        zoomControl: true,
                        zoomControlOptions: { position: google.maps.ControlPosition.LEFT_BOTTOM }
                    }}
                    zoom={hthTerritory.map.zoom - 1}
                >
                    {!!hthTerritory.map.polygons?.length && hthTerritory.map.polygons.map(polygon =>
                        <HTHPolygonComponent key={polygon.id}
                            currentFace={null}
                            isAddingPolygon={false}
                            isEditingView={false}
                            polygon={polygon}
                            runIntervals={false}
                            selectBlockAndFaceHandler={() => {}}
                            setTerritoryHTH={() => {}}
                            territoryHTH={hthTerritory}
                        />
                    )}
                </GoogleMap>
                {mapLoader.loadError &&
                    <div>
                        <h1>Falló mapa: {mapLoader.loadError.message}</h1>
                        <h2>{mapLoader.loadError.stack}</h2>
                        <h2>{mapLoader.loadError.name}</h2>
                    </div>
                }
            </div>
            <div className={'bg-general-blue'} style={{ height: '10px' }}></div>
        </div>
    )
}
