import { H2 } from 'src/components/_commons';

export const AdminsGroups = () => {

    return (
        <>
        <H2 title={'GRUPOS DE PREDICACIÓN'} />
        <div>
            <h5>En desarrollo</h5>
        </div>
        </>
    )
}
