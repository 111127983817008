import { api } from './_apiInterface';
import { appName, pointer } from 'src/app-config';
import { generalHelpers } from '@monorepo/helpers';
import { typeResponseData } from '@monorepo/models';
import JSZip from 'jszip';

class ConfigServices {
    private Base = pointer.config;
    async DownloadDbBackup(withFiles: boolean, all: boolean): Promise<boolean> {
        const data = await api.SendRequest(`${this.Base}/db-backup?all=${all}&files=${withFiles}`, 'GET');
        if (!data) return false;
        const dbBackups = all ? data.dbBackups : [data.dbBackup];
        if (!dbBackups) return false;
        try {
            const zip = new JSZip();
            const name = `Backup_${generalHelpers.GetCurrentLocalDate()}_${appName.replace(' ', '')}${dbBackups.length > 1 ? '_Completo' : ''}`;
            for (let i = 0; i < dbBackups.length; i++) {
                const dbBackup = dbBackups[i];
                if (!dbBackup?.config) continue;
                if (dbBackup.board) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}BoardItems.json`, JSON.stringify(dbBackup.board, null, 4));
                }
                if (dbBackup.cartDoc) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}CartDoc.json`, JSON.stringify(dbBackup.cartDoc, null, 4));
                }
                if (dbBackup.config) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Config.json`, JSON.stringify(dbBackup.config, null, 4));
                }
                if (dbBackup.houseToHouseTerritories) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}HouseToHouseTerritories.json`, JSON.stringify(dbBackup.houseToHouseTerritories, null, 4));
                }
                if (dbBackup.logs) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Logs.json`, JSON.stringify(dbBackup.logs, null, 4));
                }
                if (dbBackup.telephonicTerritories) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}TelephonicTerritories.json`, JSON.stringify(dbBackup.telephonicTerritories, null, 4));
                }
                if (dbBackup.users) {
                    zip.folder(name)?.file(`${dbBackups.length > 1 ? `C${dbBackup.config.congregation}_` : ''}Users.json`, JSON.stringify(dbBackup.users, null, 4));
                }
            }
            zip.generateAsync({ type: 'blob' }).then((content) => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(content);
                a.download = `${name}.zip`;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            });
        } catch (error) {
            console.log(error);
        }
        return !!data?.success;
    }
    async SendInvitationForNewUser(email: string, newCongregation: boolean = false): Promise<typeResponseData|null> {
        const data = await api.SendRequest(`${this.Base}/invite`, 'PUT', { email, newCongregation });
        return data;
    }
    async SetCongregationName(name: string): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { name });
        return !!data?.success;
    }
    async SetDisableCloseHthFaces(disableCloseHthFaces: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { disableCloseHthFaces });
        return !!data?.success;
    }
    async SetDisableHthBuildingsForUnassignedUsers(disableHthBuildingsForUnassignedUsers: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { disableHthBuildingsForUnassignedUsers });
        return !!data?.success;
    }
    async SetDisableHthFaceObservatios(disableHthFaceObservations: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { disableHthFaceObservations });
        return !!data?.success;
    }
    async SetDisableEditMaps(disableEditHthMaps: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { disableEditHthMaps });
        return !!data?.success;
    }
    async SetUsingLettersForBlocks(useLettersForBlocks: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { useLettersForBlocks });
        return !!data?.success;
    }
    async SetHthIsSharingAll(shareAll: boolean): Promise<boolean> {
        const data = await api.SendRequest(this.Base, 'PATCH', { shareAll });
        return !!data?.success;
    }
}

export const configServices = new ConfigServices();
