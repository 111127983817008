import { daysOfTheWeekP } from 'src/app-config';
import { FC } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { Modal } from 'react-bootstrap';
import { priorities, typeCartParticipant, typeCartPeriod } from '@monorepo/models';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    participant: typeCartParticipant;
}

export const CartParticipantDetailsModal: FC<propsType> = ({ closeModal, participant }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <Modal show={true} onHide={closeModal}>
            <Modal.Header closeButton className={isDarkMode ? 'bg-dark text-white' : ''}>
                <Modal.Title>
                    Detalles de {participant?.name} {participant?.lastName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark text-white' : ''}>
                {participant &&
                    <div>
                        <p>
                            <strong>Nombre:</strong> {participant.name} {participant.lastName}
                        </p>
                        <p>
                            <strong>Teléfono:</strong> {participant.phoneNumber ?? '-'}
                        </p>
                        <p>
                            <strong>Está habilitado:</strong> {participant.isEnabled ? 'Sí' : 'No'}
                        </p>
                        <p>
                            <strong>Participó:</strong> {participant.hasParticipatedBefore ? 'Sí' : 'No'}
                        </p>
                        <p>
                            <strong>Prioridad:</strong> {priorities.find(p => p.value === participant.priority)?.label}
                        </p>
                        <div className="mb-4">
                            <strong>Comentarios:</strong>{' '}
                            <div>{participant.comments ? participant.comments : 'Sin comentarios'}</div>
                        </div>
                        <p>
                            <strong>Disponibilidad:</strong>
                        </p>
                        {!!participant.availabilities.length ?
                            participant.availabilities
                                ?.sort((a1, a2) => (a1.name ?? '').localeCompare(a2.name ?? '') || a1.weekday - a2.weekday || (a1.startHour ?? 0) - (a2.startHour ?? 0) || (a1.startMinutes ?? 0) - (a2.startMinutes ?? 0))
                                .map(a =>
                                <div key={a.id} className={'ms-2'}>
                                    <strong>{a.name} - {daysOfTheWeekP[a.weekday]}</strong>
                                    <ul className={'mb-2'}>
                                        <li>
                                            {generalHelpers.GetTimeFromPeriodObject(a as typeCartPeriod)}
                                        </li>
                                    </ul>
                                </div>
                            )
                            :
                            <div>Ninguna</div>
                        }
                    </div>
                }
            </Modal.Body>
            <Modal.Footer className={isDarkMode ? 'bg-dark text-white' : ''}>
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
