import { FC } from 'react';
import { Spinner } from 'react-bootstrap';
import { useGetIsDarkMode } from 'src/custom-hooks';

type propsType = {
    mb?: string;
    mt?: string;
    white?: boolean;
    big?: boolean;
}

export const Loading: FC<propsType> = ({ big, mb, mt, white }) => {
    const isDarkMode = useGetIsDarkMode();

    return (
        <div className={`text-center ${white !== undefined ? white ? 'text-white' : '' : isDarkMode ? 'text-white' : ''}`}
            style={{
                marginBottom: mb,
                marginTop: mt
            }}
        >
            <Spinner animation={'grow'} role={'status'} /> &nbsp; &nbsp;
            <Spinner animation={'grow'} role={'status'} /> &nbsp; &nbsp;
            <Spinner animation={'grow'} role={'status'} /> &nbsp; &nbsp;
            <Spinner animation={'grow'} role={'status'} /> &nbsp; &nbsp;
            <Spinner animation={'grow'} role={'status'} />

            <br/>
            <br/>

            <span style={{ fontWeight: 'bolder', fontSize: big ? '1.3rem' : undefined }}>
                Cargando...
            </span>

        </div>
    )
}
