import { boardServices } from 'src/services';
import { Dispatch, FC, SetStateAction } from 'react';
import { hideLoadingModalReducer, setValuesAndOpenAlertModalReducer, showLoadingModalReducer } from 'src/store';
import { Modal } from 'react-bootstrap';
import { typeBoardItem, typeBoardSection } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetShowingLoadingModal } from 'src/custom-hooks';

type propsType = {
    closeModal: () => void;
    section: typeBoardSection;
    setCounterReload: Dispatch<SetStateAction<number>>;
}

export const BoardSectionsItemsArchivedFilesModal: FC<propsType> = ({ closeModal, section, setCounterReload }) => {
    const dispatch = useDispatch();
    const isDarkMode = useGetIsDarkMode();
    const showingLoadingModal = useGetShowingLoadingModal();

    const downloadFile = async (file: typeBoardItem, openingNewTab: boolean) => {
        if (showingLoadingModal) return;
        dispatch(showLoadingModalReducer());
        const success = await boardServices.GetArchivedFile(section.title, file, openingNewTab);
        dispatch(hideLoadingModalReducer());
        if (!success) {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo recuperar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    const unarchiveFile = async (file: typeBoardItem) => {
        if (showingLoadingModal) return;
        dispatch(showLoadingModalReducer());
        const success = await boardServices.ArchiveFile(section.id, section.title, file, true);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo desarchivar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    const deleteFile = async (file: typeBoardItem) => {
        if (showingLoadingModal) return;
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: 'Confirmar',
            message: `Se va a eliminar el PDF archivado '${file.originalName}' de la sección '${section.title}'. Esta acción es irreversible.`
        }));
        dispatch(showLoadingModalReducer());
        const success = await boardServices.DeleteItem(section.id, section.title, file);
        dispatch(hideLoadingModalReducer());
        if (success) {
            setCounterReload(x => x + 1);
        } else {
            dispatch(setValuesAndOpenAlertModalReducer({
                title: 'Hubo un error',
                message: 'Algo falló y no se pudo eliminar',
                mode: 'alert',
                animation: 2
            }));
        }
    }

    return (
        <Modal show={true}
            size={'lg'}
            onHide={closeModal}
        >
            <Modal.Header className={isDarkMode ? 'bg-dark text-white' : ''} closeButton>
                <Modal.Title>PDFs archivados de '{section.title}'</Modal.Title>
            </Modal.Header>
            <Modal.Body className={isDarkMode ? 'bg-dark' : ''}>
                <div className={'table-responsive pb-3'}>
                    <table className={`table ${isDarkMode ? 'table-dark' : 'table-light'}`}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Fecha</th>
                                <th className={'text-center'}>Abrir en otra pestaña</th>
                                <th className={'text-center'}>Descargar</th>
                                <th className={'text-center'}>Desarchivar</th>
                                <th className={'text-center'}>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!section.items.filter(i => i.type === 'file' && i.archived).length ?
                                section.items.filter(i => i.type === 'file' && i.archived).map(f =>
                                    <tr key={f.bucketName}>
                                        <td className={'text-nowrap'}>
                                            {f.originalName}
                                        </td>
                                        <td className={'text-nowrap'}>
                                            {new Date(f.id).toLocaleString()}
                                        </td>
                                        <td className={'text-center'}>
                                            <button className={'btn btn-general-red'}
                                                onClick={() => downloadFile(f, true)}
                                                disabled={showingLoadingModal   }
                                            >
                                                Abrir
                                            </button>
                                        </td>
                                        <td className={'text-center'}>
                                            <button className={'btn btn-general-red'}
                                                onClick={() => downloadFile(f, false)}
                                                disabled={showingLoadingModal   }
                                            >
                                                Descargar
                                            </button>
                                        </td>
                                        <td className={'text-center'}>
                                            <button className={'btn btn-general-red'}
                                                onClick={() => unarchiveFile(f)}
                                                disabled={showingLoadingModal   }
                                            >
                                                Desarchivar
                                            </button>
                                        </td>
                                        <td className={'text-center'}>
                                            <button className={'btn btn-general-red'}
                                                onClick={() => deleteFile(f)}
                                                disabled={showingLoadingModal   }
                                            >
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                )
                                :
                                <tr>
                                    <td colSpan={1000}>No hay ningún PDF archivado</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer className={isDarkMode ? 'bg-dark' : ''}>
                <button className={'btn btn-secondary'} onClick={closeModal}>
                    Cerrar
                </button>
            </Modal.Footer>
        </Modal>
    )
}
