import { Container, FloatingLabel, Form } from 'react-bootstrap';
import { emailPattern } from 'src/app-config';
import { FC, useEffect, useMemo, useState } from 'react';
import { localStorageHelper, userServices } from 'src/services';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { useDispatch } from 'react-redux';
import { useGetIsDarkMode, useGetIsMobile, useGetRecaptchaToken } from 'src/custom-hooks';

type propsType = {
    loginUsingToken: (newToken: string) => Promise<void>;
    openAlertModalHandler: (title: string, message: string, animation: number, execution?: Function) => void;
    returnUrl: string | null;
    usePasswordless: () => void;
}

export const PasswordAuthentication: FC<propsType> = ({
    loginUsingToken, openAlertModalHandler, returnUrl, usePasswordless
}) => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const getRecaptchaToken = useGetRecaptchaToken();
    const isDarkMode = useGetIsDarkMode();
    const isMobile = useGetIsMobile();

    const isValidEmail: boolean = useMemo(() =>
        !!email && emailPattern.test(email) && !loading
    , [email, emailPattern, loading]);

    const isValid: boolean = useMemo(() =>
        isValidEmail && password.length >= 3
    , [isValidEmail, password]);

    const loginHandler = async (): Promise<void> => {
        if (!isValid) {
            openAlertModalHandler("Problemas", "Faltan datos", 2);
            return;
        }
        const recaptchaToken = await getRecaptchaToken();
        if (!recaptchaToken) {
            openAlertModalHandler("Problemas (1)", "Refrescar la página", 2);
            return;
        }
        setLoading(true);
        const response = await userServices.Login(email, password, recaptchaToken);
        setLoading(false);
        if (response && response.success && response.newToken) {
            loginUsingToken(response.newToken);
        } else if (!response || response.recaptchaFails) {
            localStorageHelper.SetFailingEmail(email);
            openAlertModalHandler("Problemas (3)", "Refrescar la página", 2);
        } else if (response.expired) {
            openAlertModalHandler("Expirado", "Pasó más de una hora y esta contraseña ya no sirve; pedir otra", 2);
        } else if (response.used) {
            openAlertModalHandler("Contraseña ya utilizada", "Pedir otra", 2);
        } else if (response.isDisabled) {
            openAlertModalHandler("No habilitado", "Usuario deshabilitado por el grupo de territorios... avisarles", 2);
        } else {
            localStorageHelper.SetFailingEmail(email);
            openAlertModalHandler("Datos incorrectos", '', 2);
        }
    }

    const recoverAccountHandler = (): void => {
        if (!isValidEmail) {
            openAlertModalHandler("Escribir el email primero", '', 2);
            return;
        }
        dispatch(setValuesAndOpenAlertModalReducer({
            mode: 'confirm',
            title: "¿Recuperar cuenta?",
            message: `Esto enviará un correo a ${email} para cambiar la contraseña`,
            execution: async () => {
                setLoading(true);
                const response = await userServices.SendLinkToRecoverAccount(email);
                setLoading(false);
                if (response && response.success)
                    openAlertModalHandler(`Se envió un correo a ${email}`, '', 1);
                else if (response && response.noUser)
                    openAlertModalHandler("Este email no es de un usuario registrado:", `${email}`, 2);
                else if (response && response.notSent)
                    openAlertModalHandler("Algo falló", `No se pudo mandar un correo a ${email}`, 2);
                else
                    openAlertModalHandler("Algo falló", '', 2);
            }
        }));
    }

    const sendEmailOTP = (): void => {
        if (!isValidEmail) {
            openAlertModalHandler("Escribir el email primero", '', 2);
            return;
        }
        dispatch(setValuesAndOpenAlertModalReducer({
            title: 'Confirmar',
            message: `Se va a enviar una contraseña de uso único el correo ${email}`,
            mode: 'confirm',
            execution:  async () => {
                const recaptchaToken = await getRecaptchaToken();
                if (!recaptchaToken) {
                    openAlertModalHandler("Problemas (5)", "Refrescar la página", 2);
                    return;
                }
                setLoading(true);
                const response = await userServices.SendEmailOTP(email, recaptchaToken);
                setLoading(false);
                if (response && response.success) {
                    openAlertModalHandler(`Ahora ingresar en el campo de contraseña el código recibido en ${email}`, '', 1);
                } else if (response && response.noUser) {
                    openAlertModalHandler("Este email no es de un usuario registrado:", `${email}`, 2);
                } else if (response && response.notSent) {
                    openAlertModalHandler("Algo falló", `No se pudo mandar un correo a ${email}`, 2);
                } else {
                    openAlertModalHandler("Algo falló", '', 2);
                }
            }
        }));
    }

    useEffect(() => {
        const failingEmail = localStorageHelper.GetFailingEmail();
        if (!failingEmail) {
            document.getElementById('emailInput')?.focus();
            return;
        }
        setEmail(failingEmail);
        document.getElementById('passwordInput')?.focus();
    }, []);

    return (
        <Container className={isDarkMode ? 'bg-dark' : 'bg-light'}
            style={{
                border: '1px solid black',
                borderRadius: '12px',
                boxShadow: '0 4px 8px 0 lightblue, 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                marginBottom: '50px',
                marginTop: '60px',
                maxWidth: '600px',
                padding: '50px 0 0'
            }}
        >

            <h2 className={`text-center mx-auto ${isDarkMode ? 'text-white' : ''}`}
                style={{
                    fontSize: isMobile ? '1.7rem' : '2rem',
                    maxWidth: '90%',
                    textShadow: '0 0 1px gray'
                }}
            >
                INGRESAR {returnUrl && returnUrl !== '/' && `a ${returnUrl}`}
            </h2>

            <Container style={{ maxWidth: '500px', padding: isMobile ? '35px 30px 0' : '35px 0 0' }}>

                <FloatingLabel
                    className={'mb-3 text-dark'}
                    label={"Correo electrónico"}
                >
                    <Form.Control
                        type={'email'}
                        id={"emailInput"}
                        className={'form-control'}
                        autoComplete={'email'}
                        placeholder={email}
                        value={email}
                        onChange={e => setEmail((e.target as HTMLInputElement).value)}
                    />
                </FloatingLabel>

                <FloatingLabel
                    label={"Contraseña"}
                    className={'mb-3 text-dark'}
                >
                    <Form.Control
                        type={showPassword ? 'text' : 'password'}
                        id={"passwordInput"}
                        className={'form-control'}
                        placeholder={password}
                        value={password}
                        onChange={e => setPassword((e.target as HTMLInputElement).value)}
                        onKeyDown={e => e.key === 'Enter' ? loginHandler() : null }
                    />
                </FloatingLabel>

                <button
                    className={'btn btn-general-blue d-block w-100 mt-3'}
                    style={{ fontWeight: 'bolder', height: '50px' }}
                    onClick={loginHandler}
                    disabled={!isValid}
                >
                    ENTRAR
                </button>

                <div className={'row mt-4 mb-2'}>
                    <p className={'col-6 mb-1'}
                        style={{
                            color: '#0000cd',
                            fontSize: '1rem',
                            textDecoration: 'underline'
                        }}
                    >
                        <span className={`pointer ${isDarkMode ? 'text-white' : ''}`}
                            onClick={() => setShowPassword(x => !x)}
                        >
                            {showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                        </span>
                    </p>
                    <p className={'col-6 text-end m-0 mb-2'}
                        style={{
                            color: '#0000cd',
                            fontSize: '1rem',
                            textDecoration: 'underline'
                        }}
                    >
                        <span className={`pointer ${isDarkMode ? 'text-white' : ''}`}
                            onClick={usePasswordless}
                        >
                            Ingresar por SMS
                        </span>
                    </p>
                    <p className={'col-6'}
                        style={{
                            color: '#0000cd',
                            fontSize: '1rem',
                            textDecoration: 'underline'
                        }}
                    >
                        <span className={`pointer ${isDarkMode ? 'text-white' : ''}`}
                            onClick={recoverAccountHandler}
                        >
                            Olvidé mi contraseña
                        </span>
                    </p>
                    <p className={'col-6 text-end'}
                        style={{
                            color: '#0000cd',
                            fontSize: '1rem',
                            textDecoration: 'underline'
                        }}
                    >
                        <span className={`pointer ${isDarkMode ? 'text-white' : ''}`}
                            onClick={sendEmailOTP}
                        >
                            Ingresar por Email
                        </span>
                    </p>
                </div>

            </Container>
        </Container>
    )
}
