import { H2, HorizontalSelector } from 'src/components/_commons';
import { months } from 'src/app-config';
import { useState } from 'react';

export const AdminsMonthlyReports = () => {
    const [currentDate, setCurrentDate] = useState(new Date());

    const previousMonth = () => {
        const prevMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
        setCurrentDate(prevMonth);
    }

    const nextMonth = () => {
        const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
        setCurrentDate(nextMonth);
    }

    return (
        <>
        <H2 title={'INFORMES DE PREDICACIÓN'} />

        <HorizontalSelector
            label={`${months[currentDate.getMonth()]} ${currentDate.getFullYear()}`}
            setNext={nextMonth}
            setPrevious={previousMonth}
        />

        <div>
            <h5>En desarrollo</h5>
        </div>
        </>
    )
}
