import { CopyToClipboard } from 'react-copy-to-clipboard';
import { domain, generalBlue, hthConfigOptions } from 'src/app-config';
import { FaCopy } from 'react-icons/fa';
import { FC, Fragment, useMemo, useState } from 'react';
import { generalHelpers } from '@monorepo/helpers';
import { houseToHouseServices, hthHelper } from 'src/services';
import { QrCode, WhatsAppIcon1 } from 'src/components/_commons';
import { setValuesAndOpenAlertModalReducer } from 'src/store';
import { typeHTHTerritory, typePolygon, typeTerritoryNumber } from '@monorepo/models';
import { useDispatch } from 'react-redux';
import { useGetConfig, useGetIsMobile } from 'src/custom-hooks';
import { WhatsappShareButton } from 'react-share';

const separator = "#####################";
const separator1 = "###########";

type propsType = {
    territoryHTH: typeHTHTerritory;
    territoryNumber: typeTerritoryNumber;
}

export const HTHShareAllBuildingsButtons: FC<propsType> = ({ territoryHTH, territoryNumber }) => {
    const [copiedAllLinksToClipboard, setCopiedAllLinksToClipboard] = useState(false);
    const [copiedUniqueLinkToClipboard, setCopiedUniqueLinkToClipboard] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();
    const shareUrl = `${domain}/buscador?grupo=${config.congregation}&lugar=${territoryNumber}`;

    const shareAllLinksText: string = useMemo(() => {
        let currentUrl = `${separator}\n####  *TERRITORIO ${territoryNumber}*   ###\n${separator}\n\n*Fecha: ${generalHelpers.GetCurrentLocalDate()}*\n\n`;
        if (territoryHTH.mapUrl) {
            currentUrl += `\nMapa:\n${territoryHTH.mapUrl}\n\n`;
        }
        const doNotCalls = territoryHTH.map.polygons.map(p =>
                p.doNotCalls.map(d => ({ ...d, street: p.street, block: p.block }))
            )
            .filter(x => x.length).flat()
            .sort((a, b) => parseInt(a.block) - parseInt(b.block));
        const facesWithBuildingsOrDnt = territoryHTH.map.polygons.map(p => p).filter(p => p.buildings?.length || p.doNotCalls?.length);
        const blocksNumbers = [...new Set(facesWithBuildingsOrDnt.map(f => f.block))].sort((a, b) => parseInt(a) - parseInt(b));
        blocksNumbers.forEach(b => {
            currentUrl += `\n${separator1}\n *MANZANA ${hthHelper.MaskTheBlock(b, config.usingLettersForBlocks)}*\n${separator1}\n\n`;
            let streets = 'Calles: ';
            territoryHTH.map.polygons.filter(p => p.block === b).forEach((p, i, t) =>
                i === t.length - 1
                ? streets += `${p.street}`
                : streets += `${p.street} | `
            );
            const hyphens = '---------------------------------';
            currentUrl += "```" + hyphens + "\n" + streets + "\n" + hyphens + "```\n\n";
            if (doNotCalls.some(d => d.block === b)) {
                currentUrl += "*NO TOCAR:*\n";
                doNotCalls.filter(d => d.block === b).forEach(d => {
                    currentUrl += `> ${d.street} ${d.streetNumber} ${d.doorBell ?? ''} (${d.date})\n`;
                });
                currentUrl += "\n";
            } else {
                currentUrl += "~No hay No Tocar en esta manzana~\n\n";
            }
            if (territoryHTH.map.polygons.some(f => f.block === b && f.buildings?.length)) {
                currentUrl += "\n*EDIFICIOS:*\n";
                const faces = territoryHTH.map.polygons.filter(p => p.block === b);
                faces.forEach(f => {
                    f.buildings?.sort((a, b) => a.streetNumber - b.streetNumber).forEach(b => {
                        currentUrl += `- ${f.street} ${b.streetNumber}${b.streetNumber2 ? `/${b.streetNumber2}` : ''}${b.streetNumber3 ? `/${b.streetNumber3}` : ''}`;
                        currentUrl += `\n`;
                        currentUrl += `> ${domain}/edificio/${config.congregation}/${territoryNumber}/${faces[0].block}/${f.face}/${b.streetNumber}`;
                        currentUrl += `\n\n`;
                    });
                });
            } else {
                currentUrl += "\n~No hay Edificios en esta manzana~\n\n";
            }
        });
        return currentUrl;
    }, [config, territoryHTH.map.polygons, territoryHTH.mapUrl, territoryNumber]);

    const shareUniqueLinkText: string = useMemo(() => {
        let text = `*Congregación ${config.name}*`;
        text += `\n\n`;
        text += `Acceso al *Territorio ${territoryNumber}* para el día ${generalHelpers.GetCurrentLocalDate()}:`;
        text += `\n\n`;
        text += '`';
        text += shareUrl;
        text += '`';
        return text;
    }, [config, territoryNumber, shareUrl]);

    const shareHandler = async (isUniqueLink: boolean) => {
        if (!territoryHTH.map.polygons.some(f => f.buildings?.length)) return;
        if (!generalHelpers.IsToday(territoryHTH.dateOfLastSharing ?? 1)) {
            const success = await houseToHouseServices.SetIsSharedBuildings(territoryNumber);
            if (!success) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Error",
                    message: "No se pudieron habilitar los permisos de los edificios",
                    animation: 2
                }));
                return;
            }
        }
        if (isUniqueLink) setCopiedUniqueLinkToClipboard(true);
        else setCopiedAllLinksToClipboard(true);
    }

    return (<>
        <style>
            {`
                .share-btn {
                    border-radius: 10px;
                    border: 1px solid lightgrey;
                    color: inherit;
                    height: 72px;
                    margin-inline: 10px;
                    text-align: left;
                    text-decoration: none;
                    transition: color 0.15s ease, border-color 0.15s ease;
                    width: 300px;
                }
                .share-btn:hover, .share-btn:focus, .share-btn:active {
                    border-color: #0070f3;
                    color: #0070f3;
                }
            `}
        </style>
        {showQrCode &&
            <QrCode
                closeModal={() => setShowQrCode(false)}
                title={`COMPARTIR TERRITORIO ${territoryNumber}`}
                url={shareUrl}
            />
        }
        <div className={'row mt-3'}>
            <div className={'text-center'}>
                <CopyToClipboard text={shareAllLinksText}>
                    <button className={`btn ${copiedAllLinksToClipboard ? 'btn-general-red' : 'btn-general-blue'} py-2 my-2 ${isMobile ? '' : 'me-2'}`}
                        style={{ width: '300px', maxWidth: '95%', height: '72px' }}
                        onClick={() => shareHandler(false)}
                        disabled={!shareAllLinksText}
                    >
                        {copiedAllLinksToClipboard ? 
                            "Copiados!"
                            :
                            <div className={'row'}>
                                <div className={'col-2'}>
                                    <FaCopy size={'30px'} className='mt-1 ms-2' />
                                </div>
                                <div className={'col-10 px-1'}>
                                    Copiar Edificios de todo el Territorio para compartir
                                </div>
                            </div>
                        }
                    </button>
                </CopyToClipboard>
                <CopyToClipboard text={shareUniqueLinkText}>
                    <button className={`btn ${copiedUniqueLinkToClipboard ? 'btn-general-red' : 'btn-general-blue'} py-2 my-2 ${isMobile ? '' : 'ms-2'}`}
                        style={{ width: '300px', maxWidth: '95%', height: '72px' }}
                        onClick={() => {
                            copiedUniqueLinkToClipboard ? setShowQrCode(true) : shareHandler(true);
                        }}
                        disabled={!shareUniqueLinkText}
                    >
                        {copiedUniqueLinkToClipboard ? 
                            "Copiado! Otro click para compartir por QR"
                            :
                            <div className={'row'}>
                                <div className={'col-2'}>
                                    <FaCopy size={'30px'} className='mt-1 ms-2' />
                                </div>
                                <div className={'col-10 px-1'}>
                                    Copiar link único de todo el Territorio para compartir
                                </div>
                            </div>
                        }
                    </button>
                </CopyToClipboard>
            </div>
        </div>
        <div className={`${isMobile ? 'mb-5' : 'mb-1'}`}>
            {hthConfigOptions.blocks.map(b =>
                <Fragment key={b}>
                    {!!territoryHTH.map.polygons.filter(p => p.block === b && p.buildings?.length).length &&
                        <ShareBlock
                            faces={territoryHTH.map.polygons.filter(p => p.block === b)}
                            territoryNumber={territoryNumber}
                        />
                    }
                </Fragment>
            )}
        </div>
    </>)
}


type propsType1 = {
    faces: typePolygon[];
    territoryNumber: typeTerritoryNumber;
}

const ShareBlock: FC<propsType1> = ({ faces, territoryNumber }) => {
    const [copiedToClipboard1, setCopiedToClipboard1] = useState(false);
    const [copiedToClipboard2, setCopiedToClipboard2] = useState(false);
    const config = useGetConfig();
    const dispatch = useDispatch();
    const isMobile = useGetIsMobile();

    const shareLinks = useMemo(() => {
        let currentUrl = `Territorio ${territoryNumber} - Manzana ${hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}`;
        currentUrl += `\n\n`;
        faces.forEach(f =>
            f.buildings?.sort((a, b) => a.streetNumber - b.streetNumber).forEach(b => {
                currentUrl += `${f.street} ${b.streetNumber}`;
                currentUrl += `\n`;
                currentUrl += `${domain}/edificio/${config.congregation}/${territoryNumber}/${faces[0].block}/${f.face}/${b.streetNumber}`;
                currentUrl += `\n\n`;
            })
        );
        return currentUrl;
    }, [config, faces, territoryNumber]);

    const shareLink = useMemo(() => {
        let text = `*Congregación ${config.name}*`;
        text += `\n\n`;
        text += `Acceso al *Territorio ${territoryNumber} Manzana ${hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}* para el día ${generalHelpers.GetCurrentLocalDate()}:`;
        text += `\n\n`;
        text += '`';
        text += `${domain}/buscador?grupo=${config.congregation}&lugar=${territoryNumber}&manzana=${faces[0].block}`;
        text += '`';
        text += `\n\n`;
        text += `Calles: ${faces.map(f => f.street).join(' | ')}`;
        return text;
    }, [config, faces, territoryNumber]);

    const shareHandler = async (isUniqueLink: boolean) => {
        if (!generalHelpers.IsToday(faces[0].dateOfLastSharing ?? 1)) {
            const success = await houseToHouseServices.SetIsSharedBuildings(territoryNumber, faces[0].block);
            if (!success) {
                dispatch(setValuesAndOpenAlertModalReducer({
                    mode: 'alert',
                    title: "Error",
                    message: "No se pudieron habilitar los permisos de los edificios",
                    animation: 2 
                }));
                return;
            }
        }
        if (isUniqueLink) setCopiedToClipboard2(true);
        else setCopiedToClipboard1(true);
    }

    return (
        <div className={'row'}>
            <div className={'col-md-6'}>
                <div className={isMobile ? 'mt-2' : 'd-flex justify-content-end'}>
                    <div style={{ marginBlock: isMobile ? '0' : '10px' }}>
                        <div className={'row d-flex align-items-center justify-content-center'}>
                            <div className={`card share-btn ${copiedToClipboard1 ? 'btn-general-red' : 'btn-light'} text-center pointer pt-1`} onClick={() => {}}>
                                <CopyToClipboard text={shareLinks}>
                                    <WhatsappShareButton
                                        onClick={() => {}}
                                        url={shareLinks}
                                        style={{ marginTop: '7px' }}
                                        windowWidth={660}
                                        windowHeight={460}
                                    >
                                        {copiedToClipboard1 ?
                                            <div className={'mt-2'}>
                                                Copiados! Manzana {hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}
                                            </div>
                                            :
                                            <div className={'row'} onClick={() => shareHandler(false)}>
                                                <div className={'col-2'}>
                                                    <WhatsAppIcon1 styles={{ width: '45px' }} />
                                                </div>
                                                <div className={'col-10 px-1'}>
                                                    &nbsp; Manzana {hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}: Enviar los Edificios por WhatsApp
                                                    (hay {faces.map(f => f.buildings).filter(b => b && b.length > 0).flat().length})
                                                </div>
                                            </div>
                                        }
                                    </WhatsappShareButton>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'col-md-6'}>
                <div className={isMobile ? 'mt-2' : 'd-flex justify-content-start'}>
                    <div style={{ marginBlock: isMobile ? '0' : '10px' }}>
                        <div className={'row d-flex align-items-center justify-content-center'}>
                            <CopyToClipboard text={shareLink}>
                                <div className={`card share-btn ${copiedToClipboard2 ? 'btn-general-red' : 'btn-light'} text-center pointer pt-1`}
                                    onClick={() => shareHandler(true)}
                                >
                                    {copiedToClipboard2 ?
                                        <div className={'mt-3'}>
                                            Copiado! Manzana {hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}
                                        </div>
                                        :
                                        <div className={'row'} style={{ marginTop: '7px' }}>
                                            <div className={'col-2'}>
                                                <FaCopy size={'30px'} className='mt-1 ms-2' color={generalBlue} />
                                            </div>
                                            <div className={'col-10 px-1'}>
                                                &nbsp; Manzana {hthHelper.MaskTheBlock(faces[0].block, config.usingLettersForBlocks)}: Copiar link único para compartir
                                                (hay {faces.map(f => f.buildings).filter(b => b && b.length > 0).flat().length})
                                            </div>
                                        </div>
                                    }
                                </div>
                            </CopyToClipboard>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
