import { CartParticipantForm } from '../admins-page/carts/participant/CartParticipantForm';
import { cartServices } from 'src/services';
import { H2, Loading } from 'src/components/_commons';
import { typeCartsDoc, typeCartParticipant } from '@monorepo/models';
import { useGetCurrentUser, useGetIsDarkMode } from 'src/custom-hooks';
import { useNavigate } from 'react-router';
import { useState, useEffect } from 'react';

export const CartsSelfRegistrationPage = () => {
    const [cartDoc, setCartDoc] = useState<typeCartsDoc | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditInit, setIsEditInit] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState<typeCartParticipant | null>(null);
    const isDarkMode = useGetIsDarkMode();
    const navigate = useNavigate();
    const user = useGetCurrentUser();

    useEffect(() => {
        cartServices.GetDoc().then(co => {
            setIsLoading(false);
            if (!co) return;
            const participant = co.participants?.find(p => p.userId === user.id);
            if (participant) {
                setSelectedParticipant(participant);
                setIsEditInit(true);
                setCartDoc({ ...co, users: co.users?.filter(u => u.userId === user.id) });
            } else {
                const p: typeCartParticipant = {
                    availabilities: [],
                    comments: '',
                    email: user.email,
                    hasParticipatedBefore: false,
                    isEnabled: false,
                    lastName: user.lastName,
                    name: user.name,
                    phoneNumber: user.phoneNumber,
                    priority: 3,
                    userId: user.id
                };
                setSelectedParticipant(p);
                setIsEditInit(false);
                setCartDoc({ ...co, users: [p]});
            }
        });
    }, []);

    if (isLoading) return <Loading mt={'80px'} />;

    if (!cartDoc) return <div className={'mt-5'}>No se pudieron recuperar los datos</div>;

    if (!user.name || !user.lastName) return (
        <div className={'mt-5'}>
            Para participar en esta actividad se necesita que previamente un administrador de la aplicación cargue nombre, apellido y teléfono
        </div>
    );

    return (
        <div>
            <H2 title={'AUTORREGISTRO CARRITOS'} />
            <div className={`container card ${isDarkMode ? 'bg-dark text-white' : ''} py-4 mt-4`}>
                <div className={'text-center mb-4 mt-3'}>
                    <h2>Solicitud para participar en los Carritos</h2>
                    <p className={isDarkMode ? '' : 'text-muted'}>
                        INVITAMOS A INSCRIBIRSE (2 Timoteo 4:5 )
                    </p>
                </div>
                <CartParticipantForm
                    cartDoc={cartDoc}
                    isEditInit={isEditInit}
                    isSelfRegistration={true}
                    goBack={() => navigate('/')}
                    selectedParticipant={selectedParticipant}
                    setRefreshCounter={() => {}}
                    setSelectedParticipant={() => {}}
                />
            </div>
        </div>
    )
}
